import React, { useEffect, useState } from "react";
import Footer from "../component/Footer";
import Header from "../component/Header";
import { useFormik, Form, Field, ErrorMessage, FormikProvider } from "formik";
import * as Yup from "yup";
import "react-toastify/dist/ReactToastify.css";
import { Avatar } from "@material-ui/core";
import axios from "axios";
import { ChefDetailsURL, ExtensionChangeURL } from "../component/config/config";
import { Dialog } from "@mui/material";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import ChefStep from "../component/ChefStep";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import CreatableSelect from "react-select/creatable";
import { CuisinesURL } from "../component/config/config";
import { TagURL } from "../component/config/config";
import { useDispatch } from "react-redux";
import {
  ChefDetailAction,
  loginSuccess,
  setUser,
} from "../Actions/ActionCreators";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { ChefURL } from "../component/config/config";
import { ImageURL } from "../component/config/config";
import { CountryURL } from "../component/config/config";
import { LocalConvenienceStoreOutlined } from "@mui/icons-material";
import base64 from "base-64";
import { tostS, tostE } from "../toast";
import Swal from "sweetalert2";
import axiosInstance from "../component/config/axios";
// import heic2jpeg from 'heic2jpeg';
const ChefDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const LoginData = useSelector((state) =>
    state.login.data && state.login.data.chefDetails ? state.login.data : {}
  );

  const SignupData = useSelector((state) =>
    state.Signup.data ? state.Signup.data : {}
  );
  console.log(SignupData, "signup");
  //id is use for fetch data on press back button
  const id = localStorage.getItem("cID");
  // const myId = SignupData.chefDetails._id ? SignupData.chefDetails._id : LoginData.chefDetails._id
  const myId =
    LoginData.chefDetails && LoginData.chefDetails._id
      ? LoginData.chefDetails._id
      : SignupData.chefDetails && SignupData.chefDetails._id
      ? SignupData.chefDetails._id
      : 0;
  const sig =
    LoginData.chefDetails && LoginData.chefDetails.signupComplete
      ? LoginData.chefDetails.signupComplete
      : SignupData.chefDetails && SignupData.chefDetails.signupComplete
      ? SignupData.chefDetails.signupComplete
      : false;

  const [isDisabled, setIsDisabled] = useState(false);
  const [country, setCountry] = useState();
  const [image, setImage] = useState("");
  const [cuisineType, setCuisineType] = useState();
  const [tagType, setTagType] = useState();
  const [kitchenImage, setKitchenImage] = useState([]);
  const [deleteImage, setDeleteImage] = useState([]);
  const [address, setAddress] = React.useState("");
  const [coordinates, setCoordinates] = React.useState({
    lat: null,
    lng: null,
  });
  const { state } = useLocation();
  const [editInfo, setEditInfo] = useState();
  const [selectedFile, setSelectedFile] = useState([]);
  const [documentImage, setDocument] = useState([]);
  const [error, setError] = useState([]);
  const [images, setImages] = useState([]);
  const [cusError, setCusError] = useState("");
  const [tagError, setTagError] = useState("");
  const [ssnError, setSsnError] = useState("");
  const [countryType, setCountryType] = useState([]);

  const fetchData = async () => {
    const countryResponse = await axios.get(CountryURL);
    let countryData = countryResponse?.data?.data;
    setCountryType(countryData);
    // console.log(countryData)
    const cuisineresponse = await axios.get(CuisinesURL);
    const tagresponse = await axios.get(TagURL);
    // console.log(countryType)
    // setCuisineType(cuisineresponse?.data?.data);
    let cuisineData = cuisineresponse?.data?.data;
    // setTagType(tagresponse?.data?.data);
    let tagData = tagresponse?.data?.data;

    if (myId !== null && myId !== undefined && myId !== "") {
      const body = {
        _id: myId,
      };
      const response = await axiosInstance().get(ChefURL, body);
      const b = response?.data?.data;
      // console.log("b", b);
      // console.log(b.kitchenName);
      setEditInfo(b.profileImage);
      let setData = {
        kitchenName: "",
        country: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipCode: "",
        description: "",
        deliveryPreference: "",
        instructions: "",
        ssn: "",
        profileImage: "",
        agreeTerms: false,
      };
      for (let i in setData) {
        if (
          b[i] !== null &&
          b[i] !== undefined &&
          b[i] !== "undefined" &&
          i !== "checkbox"
        ) {
          setData[i] = b[i];
        }
      }
      if (b.profileImage) {
        // setData['profileImage'] = ImageURL + b.profileImage;
        setFieldValue("profileImage", b.profileImage);
      }
      setData["cuisineOffered"] = b?.cuisines.map((da) => ({
        value: da._id,
        label: da.name,
      }));
      setData["tags"] = b?.tagsResult.map((d) => ({
        value: d._id,
        label: d.name,
      }));
      if (b.chefID) {
        setData["chefID"] = b.chefID;
        console.log(b.chefID);
      }
      if (b.ssn) {
        setData["ssn"] = base64.decode(b.ssn);
      }
      setValues({
        ...values,
        ...setData,
      });
      const photoArray = b.kitchenPhotos.map((dataaaa) => ImageURL + dataaaa);
      const kitcArray = b.kitchenPhotos.map((demo) => demo);
      console.log(kitcArray);
      setImages([...photoArray]);
      // setSelectedFile([...photoArray]);
      // setDeleteImage([...kitcArray])
      setKitchenImage(b.kitchenPhotos);
      setCoordinates({
        ...coordinates,
        lat: b.location.coordinates[1],
        lng: b.location.coordinates[0],
      });
      console.log(b.location.coordinates[0]);
    }
    setCuisineType(cuisineData);
    setTagType(tagData);
  };
  useEffect(() => {
    if (!sig) {
      navigate("/");
    }
    fetchData();
  }, []);

  const handleCuisine = (item) => {
    let result = item.map((data) => {
      if (typeof data.value === "string") {
        return textCapitalize(data.value);
      } else {
        return data.value._id;
      }
    });
    return result;
  };
  const handleTag = (item) => {
    // console.log(item);

    let result = item.map((data) => {
      if (typeof data.value === "string") {
        return textCapitalize(data.value);
      } else {
        return data.value._id;
      }
    });
    return result;
  };

  const setFun = (data) => {
    if (LoginData !== null) {
      const fData = {
        ...LoginData,
        chefDetails: {
          ...data,
        },
      };
      dispatch(setUser(fData));
    }
  };

  const addFormField = (event) => {
    // debugger
    if (selectedFile.length <= 10) {
      setDocument([...documentImage, {}]);
    } else {
      setError("Only 10 images add allowed");
    }
  };
  const handleChange = async (e) => {
    console.log(e.target.files[0]);
    if (selectedFile.length < 10) {
      if (
        e.target.files[0].type == "image/png" ||
        e.target.files[0].type == "image/jpg" ||
        e.target.files[0].type == "image/jpeg"
      ) {
        if (e.target.files[0].size <= 3145728) {
          let newFile = Object.values(e.target.files);
          console.log("newFileeeee", newFile);
          setSelectedFile((files) => [...files, ...newFile]);
          console.log(newFile);
          let newImage = [...images];
          newImage.push(URL.createObjectURL(e.target.files[0]));
          // newImage.push(e.target.files[0]);

          setImages(newImage);
          setError("");
        } else {
          setError("Size must be less than 3 MB");
        }
      } else if (
        e.target.files[0].type === "image/heif" ||
        e.target.files[0].type === "image/heic" ||
        e.target.files[0].type === ""
      ) {
        let formData = new FormData();
        formData.append("imagePath", "chef/" + values.chefID);
        formData.append("extension", e.target.files[0]);

        const resp = await axios.post(ExtensionChangeURL, formData);
        let newFile = e.target.files;
        // console.log(newFile[0])
        let newImages = [...images];
        newImages.push(ImageURL + resp.data.data[0]);
        setSelectedFile((files) => [...files, resp.data.data[0]]);
        setFieldValue("kitchenPhotos", resp.data.data[0]);
        setImages(newImages);
      } else {
        setError("Invalid File Format ");
      }
    } else {
      setError("Only 10 images handle allowed");
    }
  };
  const deleteSelectedFile = (index) => {
    const arr = [...selectedFile];
    const arr1 = [...images];
    const arr2 = [...kitchenImage];
    const arr3 = [...kitchenImage];
    console.log(arr, "arr");
    console.log(arr1, "arr1");
    arr.splice(index, 1);
    arr1.splice(index, 1);
    let deleteData = arr2.splice(index, 1);
    console.log(deleteData);
    arr3.splice(index, 1);
    console.log(arr3);
    if (document.getElementById("kitchen-input")) {
      document.getElementById("kitchen-input").value = "";
    }
    // console.log(arr);
    setSelectedFile(arr);
    setImages(arr1);
    setDeleteImage(deleteData);
    setKitchenImage(arr3);
  };
  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/heif",
    "image/heic",
  ];
  // Apply a validation on field
  const myValidationSchema = Yup.object().shape({
    cuisineOffered: Yup.array()
      .min(1, "Cuisines Offered field must have at least 1 item(s).")
      .required("Cuisine is  required"),
    kitchenName: Yup.string()
      // .strict(true)
      // .trim("Extra spaces should be truncated")
      .min(5, "Kitchen Name must be at least 5 characters")
      .max(55, "Kitchen name should not be more than 55 characters")
      .required("Kitchen Name is required "),
    country: Yup.string().trim().required("Country of Origin is required "),
    city: Yup.string()
      .min(3, "City must be at least 3 characters")
      .required("City is required "),
    state: Yup.string()
      .min(3, "State must be at least 3 characters")
      .required("State is required "),
    address1: Yup.string()
      .min(5, "Select Address from dropdown")
      .required("Address is required"),
    address2: Yup.string()
      // .strict(true).trim("Extra spaces should be truncated")
      .min(5, "Address 2 must be at least 5 characters ")
      .max(250, " Address 2 cannot be more than 120 characters"),
    description: Yup.string()
      .min(5, "Description must be at least 5 characters ")
      .max(750, "Description cannot be more than 750 characters")
      .required("Description is required "),
    zipCode: Yup.string()
      .min(5, "Zip Code must be at least 5 digits")
      .max(5, "Zip Code must not be greater than 5 digits")
      .required("Zip Code is required ")
      // .test(
      // 	"is-decimal",
      // 	"Zip Code should be number only",
      // 	(val) => val !== undefined && val.match(/^[0-9]+$/)
      // )
      .matches(/^\d{5}$/, "Enter valid Zip Code"),
    tags: Yup.array()
      .min(1, "Tags field must have at least 1 item(s)")
      .required("Tags is required "),
    profileImage: Yup.mixed()
      // .test(
      // 	"fileSize",
      // 	"File size too long",
      // 	(value) => {
      // 		console.log("image value", value)
      // 		return !value || (value && value.size <= 10000000)
      // 	})
      // .test(
      // 	"fileFormat",
      // 	"Unsupported Format",
      // 	(value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))
      // )
      .required("Chef Image is required"),
    instructions: Yup.string()
      .min(5, " Food Pick-up Instructions must be at least 5 characters ")
      .max(
        250,
        " Food Pick-up Instructions must not be more than 250 characters "
      ),
    ssn: Yup.string()
      .required("SSN is required")
      .matches(
        /^(?!(000|666|9))\d{3}-(?!00)\d{2}-(?!0000)\d{4}$/,
        "Enter a valid SSN"
      ),
    agreeTerms: Yup.boolean().oneOf(
      [true],
      "Accepting the Chef Terms & Conditions is required"
    ),
  });
  const numFormat = (num) => {
    let nums = num.split("");
    // console.log("::::::::::::", nums);
    let a = nums.fill("*", 0, 7);
    return a.join().replaceAll(",", "");
  };
  let baseReg = /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/;
  // console.log("yeh le", Math.floor(100000 + Math.random() * 900000));
  // For show initial values
  const formik = useFormik({
    initialValues: {
      kitchenName: "",
      country: "",
      chefID: Math.floor(100000 + Math.random() * 900000),
      address1: "",
      address2: "",
      city: "",
      state: "",
      zipCode: "",
      description: "",
      cuisineOffered: "",
      deliveryPreference: "",
      instructions: "",
      tags: "",
      imagePath: "",
      id: myId,
      ssn: "",
      kitchenPhotos: [],
      profileImage: "",
      agreeTerms: false,
    },

    validationSchema: myValidationSchema,
    onSubmit: async (values, errors) => {
      // console.log(values);
      let location = { lat: coordinates?.lat, lng: coordinates?.lng };
      // console.log(location);
      if (coordinates.lat !== undefined && coordinates.lng !== undefined) {
        try {
          let formData = new FormData();
          formData.append("kitchenName", values?.kitchenName);
          formData.append("country", values?.country);
          formData.append("chefID", values?.chefID);
          formData.append("address1", values?.address1);
          formData.append("address2", values?.address2);
          formData.append("city", values?.city);
          formData.append("state", values?.state);
          formData.append("zipCode", values?.zipCode);
          formData.append(
            "description",
            values?.description.replace(/ +/g, " ")
          );
          formData.append("ssn", values?.ssn);

          formData.append("deliveryPreference", values?.deliveryPreference);
          formData.set(
            "cuisineOffered",
            JSON.stringify(handleCuisine(values?.cuisineOffered))
          );

          formData.append(
            "instructions",
            values?.instructions.replace(/ +/g, " ")
          );
          formData.set("tags", JSON.stringify(handleTag(values?.tags)));

          formData.append("imagePath", "chef/" + values.chefID);
          formData.append("id", myId);
          for (let key in selectedFile) {
            console.log("keeeeeyyyyyy", key);
            // console.log(selectedFile[key]);
            formData.append("kitchenPhotos", selectedFile[key]);
          }
          // formData.append("kitchenPhotos", JSON.stringify(images));
          formData.append(
            "oldKitchenPhotos",
            kitchenImage ? JSON.stringify(kitchenImage) : ""
          );
          formData.append(
            "deletedKitchenPhotos",
            deleteImage ? JSON.stringify(deleteImage) : ""
          );
          formData.append("profileImage", values?.profileImage);
          formData.append("location", JSON.stringify(location));
          formData.append("agreeTerms", values?.agreeTerms);
          formData.append("oldProfileImage", image == "" ? "" : editInfo);

          document.body.classList.add("loading-indicator");
          const response = await axios.post(ChefDetailsURL, formData);
          // console.log(response);
          if (response.status === 200) {
            formik.resetForm();
            document.body.classList.remove("loading-indicator");
            localStorage.setItem("cID", response.data.data._id);
            localStorage.setItem("chefID", response.data.data.chefID);
            if (LoginData.chefDetails && LoginData.token) {
              dispatch(loginSuccess(response.data.data));
            } else {
              dispatch(ChefDetailAction(response.data.data));
            }
            handleClickOpen();
          }
        } catch (error) {
          setIsDisabled(false);
          document.body.classList.remove("loading-indicator");

          // console.log(error?.response?.data?.message)
          if (error?.response?.data?.data?.ssn === true) {
            // setSsnError(error?.response?.data?.message)
            setFieldError("ssn", error?.response?.data?.message);
          } else {
            if (
              (error.response.data.message =
                "We are not in your region.We will come soon.")
            ) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                html:
                  "We are coming to your city soon. Want us to launch in your city sooner? Sign-up as a chef " +
                  '<a href="https://forms.gle/ajAqE57vwDy5zic89" target="_blank" style="color: #0d6efd" ><b>here</b></a> ' +
                  "and spread the word to your friends who love to cook too!",
                confirmButtonColor: "#8d126e",
              });
            } else {
              tostE(error.response.data.message);
            }
          }
          setTagError(error?.response?.data?.message);
          // console.log(error);
        }
      } else {
        tostE("Select Address from dropdown");
      }
    },
  });

  console.log("selectedfile", selectedFile);

  let {
    submitCount,
    errors,
    setErrors,
    touched,
    setFieldValue,
    setFieldError,
    handleSubmit,
    values,
    setValues,
  } = formik;

  const textCapitalize = (re) => {
    // console.log(re)
    return re
      .toLowerCase()
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
  };

  const handleChangeCuisine = (e) => {
    // console.log("xxxxxxxxxxxxxxxxx00000", e)
    let existingLabel = [];
    let cuisineArray = [];

    e.map((dddd) => {
      let x = textCapitalize(dddd.label);
      dddd.label = x;
      // console.log(x)
      if (existingLabel.includes(x) === false) {
        existingLabel.push(dddd.label);
        cuisineArray.push(dddd);
      } else {
        tostE("Cuisine Already selected");
      }
    });
    // console.log(cuisineArray);
    setFieldValue("cuisineOffered", cuisineArray);
  };
  const handleTagCuisine = (e) => {
    let existingsLabel = [];
    let tagArray = [];

    e.map((ddddd) => {
      let x = textCapitalize(ddddd.label);
      ddddd.label = x;

      if (existingsLabel.includes(x) === false) {
        existingsLabel.push(ddddd.label);
        tagArray.push(ddddd);
      } else {
        tostE("Tag Already selected");
      }
    });
    // console.log(tagArray);
    setFieldValue("tags", tagArray);
  };

  // let x = SignupData.chefDetails.fullName ? SignupData.chefDetails.fullName : LoginData.chefDetails.fullName;
  let x =
    LoginData.chefDetails && LoginData.chefDetails.fullName
      ? LoginData.chefDetails.fullName
      : SignupData.chefDetails && SignupData.chefDetails.fullName
      ? SignupData.chefDetails.fullName
      : "null";

  //For upload a kitchen/food photos

  //For Dialog box to click on next button
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectPlace = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    console.log(address);
    setFieldValue("address1", value);
    setCoordinates({
      lat: latLng?.lat,
      lng: latLng?.lng,
    });
    // console.log("latLng", results[0]);
    // console.log(
    // 	"results",
    // 	results.map((x) => x)
    // );
    let fData = {
      state: "",
      city: "",
      address1: "",
      zipCode: "",
    };
    const availableAddComponents = [];
    results[0].address_components.map((x) => {
      if (
        x.types.includes("neighborhood") ||
        x.types.includes("locality") ||
        x.types.includes("sublocality_level_1") ||
        (x.types.includes("administrative_area_level_2") && fData["city"] == "")
      ) {
        fData["city"] = fData.city != "" ? fData.city : x.long_name;
        setFieldValue("city", fData.city != "" ? fData.city : x.long_name);
      }
      if (x.types.includes("street_number")) {
        fData["address1"] = x.long_name;
        setFieldValue("address1", x.long_name);
      }
      if (x.types.includes("route")) {
        const add = fData["address1"]
          ? fData["address1"] + " " + x.long_name
          : x.long_name;
        console.log(add);
        fData["address1"] = add;
        setFieldValue("address1", add);
      }
      // else {
      // 	setFieldValue('address1', "")
      // }
      if (x.types.includes("administrative_area_level_1")) {
        fData["state"] = x.long_name;
        setFieldValue("state", x.long_name);
      }
      if (x.types.includes("postal_code")) {
        fData["zipCode"] = x.long_name;
        setFieldValue("zipCode", x.long_name);
      }

      availableAddComponents.push(...x.types);
    });
    // console.log(availableAddComponents)
    if (!availableAddComponents.includes("street_number")) {
      setFieldValue("address1", "");
    }
    setCoordinates({
      lat: latLng?.lat,
      lng: latLng?.lng,
    });
    setErrors({
      ...errors,
      city: null,
      state: null,
      zipCode: null,
    });
  };
  const handler = (e) => {
    let x = e.target.value;
    let regSsn = /^[0-9-]*$/;
    if (regSsn.test(x)) {
      if (x.length < 12) {
        if (x.charAt(x.length - 1) !== "-") {
          if (x.length === 4) {
            x = x.slice(0, 3) + "-" + x.charAt(x.length - 1);
          } else if (x.length === 7) {
            x = x.slice(0, 6) + "-" + x.charAt(x.length - 1);
          }
        }
        setFieldValue("ssn", x);
      }
    }
  };
  const handleZip = (e) => {
    let y = e.target.value;
    let zipReg = /^\d+$/;
    // console.log(zipReg.test(y))
    if (zipReg.test(y) || y === "") {
      if (y.length <= 5) {
        setFieldValue("zipCode", y);
      }
    }
  };
  let { pathname } = useLocation();
  const handleBack = () => {
    if (pathname === "/dashboard/chefdetails") {
      navigate("/dashboard/signup");
    } else {
      navigate("/signup");
    }
  };
  const profile = async (e) => {
    document.body.classList.add("loading-indicator");

    // console.log(e)
    if (
      e.target.files[0].type === "image/png" ||
      e.target.files[0].type === "image/jpg" ||
      e.target.files[0].type === "image/jpeg"
    ) {
      if (parseInt(e.target.files[0].size) <= 5145728) {
        document.body.classList.remove("loading-indicator");
        setFieldValue("profileImage", e.target.files[0]);
        setImage(URL.createObjectURL(e.target.files[0]));
      } else {
        tostE("Please upload a file smaller than 5 MB");
      }
    } else if (
      e.target.files[0].type === "image/heif" ||
      e.target.files[0].type === "image/heic" ||
      e.target.files[0].type === ""
    ) {
      let formData = new FormData();
      formData.append("imagePath", "chef/" + values.chefID);
      formData.append("extension", e.target.files[0]);

      const resp = await axios.post(ExtensionChangeURL, formData);
      document.body.classList.remove("loading-indicator");
      // console.log(resp.data.data)
      setFieldValue("profileImage", resp.data.data[0]);
      setImage(ImageURL + resp.data.data[0]);
    } else {
      document.body.classList.remove("loading-indicator");
      tostE("Please upload a file with png,jpg or jpeg format.");
    }
  };
  console.log("IMAGESSSSSS", images);

  return (
    <>
      <FormikProvider value={formik}>
        <Form
          autoComplete="off"
          noValidate
          // onSubmit={handleSubmit}
          onSubmit={(e) => {
            e.preventDefault();
            console.log(Object.values(errors));
            if (Object.values(errors).length >= 1) {
              tostE("There is an error on the form. Please fix and try again");
            }
            handleSubmit();
          }}
        >
          <ChefStep />
          <section className="main-wrapper-login mar-t mb-5">
            <div className="container">
              <div className="form-box row">
                <div className="form-head text-center">
                  <h2>Chef details</h2>
                </div>
                <div className="col-md-9 m-auto">
                  <div className="mb-3">
                    <div className="upload-photo">
                      <div className="avatar-upload">
                        <span className="info-tag tool-tip-box con-tooltip top">
                          <b>*</b>

                          <i className="ri-information-line"></i>

                          <div className="tooltip ">
                            <p>
                              Please upload a photo of yourself. This will be
                              what customers see when they order your food, so
                              make sure you’re looking your best! Refer to the
                              Chef FAQs for tips on how to take a great picture.
                            </p>
                          </div>
                        </span>
                        <div className="avatar-edit">
                          <input
                            type="file"
                            name="profileImage"
                            id="imageUpload"
                            onChange={(e) => profile(e)}
                            accept="image/png, image/jpeg,image/jpg,image/heif,image/heic"
                          />
                          <label for="imageUpload" className="img-upload-icon">
                            <i className="ri-pencil-line"></i>
                          </label>
                        </div>
                        <Avatar
                          className="avatar-preview"
                          src={
                            image != "" ? image : ImageURL + values.profileImage
                          }
                        />
                      </div>
                      <span
                        className="text-danger"
                        style={{ textAlign: "center" }}
                      >
                        <ErrorMessage name="profileImage" />
                      </span>
                    </div>
                  </div>
                  <div className="combine-group d-flex ">
                    <div className="mb-3">
                      <label className="form-label">
                        Kitchen Name *
                        <span className="tool-tip-box con-tooltip top">
                          <i className="ri-information-line"></i>
                          <div className="tooltip ">
                            <p>
                              What would you like to name your kitchen? Keep it
                              simple, or make it catchy!
                            </p>
                          </div>
                        </span>
                      </label>
                      <Field
                        type="text"
                        name="kitchenName"
                        placeholder="Kitchen Name"
                        value={formik.values?.kitchenName.replace(/ +/g, " ")}
                        required
                        className={`form-control ${
                          submitCount > 0
                            ? errors?.kitchenName && touched?.kitchenName
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        // value={values?.kitchenName}
                      />
                      <span className="text-danger">
                        <ErrorMessage name="kitchenName" />
                      </span>
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Chef Name</label>
                      <Field
                        type="text"
                        value={x}
                        name="chefName"
                        placeholder="Michael Bruno"
                        className="form-control"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="combine-group d-flex ">
                    <div className="mb-3">
                      <label className="form-label">
                        Country of Origin *
                        <span className="tool-tip-box con-tooltip top">
                          <i className="ri-information-line"></i>
                          <div className="tooltip ">
                            <p>Which country are you natively from?</p>
                          </div>
                        </span>
                      </label>
                      <Field
                        as="select"
                        name="country"
                        required
                        className={`form-control ${
                          submitCount > 0
                            ? errors?.country && touched?.country
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        value={values.country}
                        style={{ color: "black" }}
                      >
                        <option value="">Select</option>
                        {countryType !== undefined &&
                          countryType?.map((countr, idx) => {
                            return (
                              <option key={idx} value={countr?.name}>
                                {countr?.name}
                              </option>
                            );
                          })}
                      </Field>
                      <span className="text-danger">
                        <ErrorMessage name="country" />
                      </span>
                    </div>
                  </div>

                  <div className="combine-group d-flex ">
                    <div className="mb-3">
                      <label className="form-label">Address line 1 *</label>
                      <PlacesAutocomplete
                        value={values.address1}
                        onChange={(data) => {
                          setFieldValue("address1", data);
                        }}
                        searchOptions={{
                          componentRestrictions: { country: ["us"] },
                        }}
                        onSelect={handleSelectPlace}
                        // searchOptions={{ types: ['locality', 'country', "street_number", "city"] }}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div>
                            <Field
                              name="address1"
                              autoComplete="off"
                              value={formik.values?.address1.replace(
                                / +/g,
                                " "
                              )}
                              className={`form-control ${
                                submitCount > 0
                                  ? errors?.address1 && touched?.address1
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                              {...getInputProps({
                                placeholder: "Type address",
                              })}
                              onBlur={formik.handleBlur}
                            ></Field>

                            <div>
                              {loading ? <div>...loading</div> : null}
                              {suggestions.map((suggestion) => {
                                const style = {
                                  backgroundColor: suggestion.active
                                    ? "#41b6e6"
                                    : "#fff",
                                };

                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      style,
                                    })}
                                  >
                                    {suggestion.description}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                      <span className="text-danger">
                        <ErrorMessage name="address1" />
                      </span>
                    </div>

                    <div className="mb-3">
                      <label className="form-label">Address line 2 </label>
                      <Field
                        type="text"
                        name="address2"
                        placeholder="Address Line 2 (Optional)"
                        className="form-control"
                        value={formik.values?.address2.replace(/ +/g, " ")}
                        // autoComplete="off"
                      />
                      <span className="text-danger">
                        <ErrorMessage name="address2" />
                      </span>
                    </div>
                  </div>
                  <div className="combine-group d-flex ">
                    <div className="mb-3">
                      <label className="form-label">City *</label>
                      <Field
                        type="text"
                        name="city"
                        placeholder="City"
                        value={formik.values?.city.replace(/ +/g, " ")}
                        className={`form-control ${
                          submitCount > 0
                            ? errors?.city && touched?.city
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                      />

                      <span className="text-danger">
                        <ErrorMessage name="city" />
                      </span>
                    </div>

                    <div className="mb-3">
                      <label className="form-label">State *</label>
                      <Field
                        type="text"
                        name="state"
                        placeholder="State"
                        value={formik.values?.state.replace(/ +/g, " ")}
                        className={`form-control ${
                          submitCount > 0
                            ? errors?.state && touched?.state
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                      />

                      <span className="text-danger">
                        <ErrorMessage name="state" />
                      </span>
                    </div>

                    <div className="mb-3">
                      <label className="form-label"> Zip Code *</label>
                      <Field
                        type="text"
                        name="zipCode"
                        placeholder="Zip Code"
                        required
                        className={`form-control ${
                          submitCount > 0
                            ? errors?.zipCode && touched?.zipCode
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        onChange={(e) => handleZip(e)}
                        onKeyDown={(evt) =>
                          evt.key === "e" && evt.preventDefault()
                        }
                        onWheel={(e) => e.target.blur()}
                      />
                      <span className="text-danger">
                        <ErrorMessage name="zipCode" />
                      </span>
                    </div>

                    <div className="mb-3">
                      <label className="form-label"> SSN *</label>
                      <Field
                        type="text"
                        name="ssn"
                        placeholder="SSN"
                        required
                        className={`form-control ${
                          submitCount > 0
                            ? errors?.ssn && touched?.ssn
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        onChange={(e) => handler(e)}
                        // value={base64.decode(values.ssn)}
                      />
                      <span className="text-danger">
                        <ErrorMessage name="ssn" />
                      </span>
                      <span className="text-danger">{ssnError}</span>
                    </div>
                  </div>
                  <div className="mb-3 textarea-box">
                    <label className="form-label">
                      Chef Description *{" "}
                      <span className="tool-tip-box con-tooltip top">
                        <i className="ri-information-line"></i>
                        <div className="tooltip ">
                          <p>
                            Please tell us about yourself. What inspires you to
                            cook and what is special about your cooking? Your
                            bio will be displayed on your profile so that
                            customers can learn about you.
                          </p>
                        </div>
                      </span>
                    </label>
                    <Field
                      name="description"
                      component="textarea"
                      rows="4"
                      value={formik.values.description}
                      required
                      style={{ overflow: "hidden" }}
                      className={`form-control ${
                        submitCount > 0
                          ? errors?.description && touched?.description
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                      }`}
                      placeholder="Ex. Hi, I’m John Smith! I specialize in authentic Italian cuisine. Growing up, I learned to cook alongside my father and uncles. Their cannoli was the envy of the neighborhood. While the family recipes are a secret, the flavor and passion are meant to be shared. I love to make pesto and pizzelle for my children, our friends, and neighbors, and I hope you’ll give my cooking a try too!
"
                    ></Field>
                    <span className="text-danger">
                      <ErrorMessage name="description" />
                    </span>
                  </div>

                  <div className="mb-3">
                    <label className="form-label">
                      Kitchen/Food Photos{" "}
                      <span className="tool-tip-box con-tooltip top">
                        <i className="ri-information-line"></i>
                        <div className="tooltip ">
                          <p>
                            Feel free to add additional photos of yourself
                            cooking in your kitchen. This step is optional, but
                            more photos can help your profile really stand out!
                          </p>
                        </div>
                      </span>
                    </label>
                    <div className="uploading-group upload-document">
                      <ul className="uploding-list"></ul>

                      {images?.length > 0 &&
                        images.map((file, index) => {
                          // { console.log("file", file) }
                          return (
                            <div className="uploading-group upload-document">
                              <ul className="uploding-list">
                                <li key={index}>
                                  <img src={file} />
                                  <small
                                    className="close-btn"
                                    onClick={() => deleteSelectedFile(index)}
                                  >
                                    <i className="ri-close-line"></i>
                                  </small>
                                </li>
                              </ul>
                            </div>
                          );
                        })}
                      {selectedFile.length != 10 ? (
                        <div className="file file--upload">
                          <button type="button" onClick={() => addFormField()}>
                            <label className="file-label">
                              <i className="ri-add-circle-line"></i>{" "}
                              <input
                                type="file"
                                id="kitchen-input"
                                title="&nbsp;"
                                className="form-control  form-control-aatch-file"
                                name="kitchenPhotos"
                                onChange={(e) => {
                                  console.log("skjdhfkh");
                                  handleChange(e);
                                }}
                              />
                              <span>Add more</span>
                            </label>
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {error !== "" ? (
                      <span className="errorMessage">{error}</span>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Cuisines Offered *</label>
                    <div className=" common-key-word">
                      <CreatableSelect
                        isMulti
                        // value={cuisineType.filter((a) => values.cuisineOffered.includes(a._id)).map((daa) => (daa._id))}
                        options={cuisineType?.map((x) => ({
                          value: x,
                          label: x.name,
                        }))}
                        value={values.cuisineOffered}
                        onChange={(e) => {
                          handleChangeCuisine(e);
                        }}
                        className={`css-1s2u09g-control ${
                          submitCount > 0
                            ? errors?.cuisineOffered && touched?.cuisineOffered
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                      />

                      <span className="text-danger">
                        <ErrorMessage name="cuisineOffered" />
                      </span>
                      <span className="text-danger">{/* {cusError} */}</span>
                    </div>
                  </div>
                  <div className="mb-3 textarea-box">
                    <label className="form-label">
                      Food Pick-up Instructions
                    </label>
                    <Field
                      className="form-control"
                      component="textarea"
                      rows="4"
                      name="instructions"
                      value={formik.values.instructions}
                      placeholder="Instructions for customers/delivery driver pick-up	"
                    ></Field>
                    <span className="text-danger">
                      <ErrorMessage name="instructions" />
                    </span>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">
                      Tags *{" "}
                      <span className="tool-tip-box con-tooltip top">
                        <i className="ri-information-line"></i>
                        <div className="tooltip ">
                          <p>
                            Tags are like keywords or hashtags. Use tags field
                            to elaborate your style of cooking, its
                            characteristics, or pinpoint the sub cuisine or
                            specific local geographical region it belongs to.
                            Thus, making it easier for customers to search for
                            your dishes. Ex. Street Food, Less oil, Punjabi,
                            Southern style, Tex Mex, etc.{" "}
                          </p>
                        </div>
                      </span>
                    </label>
                    <div className="common-key-word ">
                      <CreatableSelect
                        isMulti
                        onChange={(e) => {
                          handleTagCuisine(e);
                        }}
                        value={values.tags}
                        options={tagType?.map((x) => ({
                          value: x,
                          label: x.name,
                        }))}
                        className={`css-1s2u09g-control ${
                          submitCount > 0
                            ? errors?.tags && touched?.tags
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                      />
                      <span className="text-danger">
                        <ErrorMessage name="tags" />
                      </span>
                      <span className="text-danger">{/* {tagError} */}</span>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="coustom-checkbox">
                      <input
                        type="checkbox"
                        name="agreeTerms"
                        checked={values.agreeTerms ? true : false}
                        value={values?.agreeTerms}
                        onChange={formik.handleChange}
                        onBlur={formik.onBlur}
                        className={`form-control ${
                          submitCount > 0
                            ? errors?.agreeTerms && touched?.agreeTerms
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                      />
                      <span className="checkmark"></span>
                      <p>
                        Accept{" "}
                        <a
                          href={
                            pathname === "/chefdetails"
                              ? "/cheft&c"
                              : "/dashboard/cheft&c"
                          }
                          target="blank"
                        >
                          Chef Terms & Conditions
                        </a>
                        {"\u00a0"}*
                      </p>
                    </label>
                    <span className="text-danger">
                      <ErrorMessage name="agreeTerms" />
                    </span>
                  </div>
                  <div className="mb-3 text-center mt-5 d-flex align-items-center justify-content-center">
                    <a
                      type="button"
                      className="btn min-height black-btn  hvr-sweep-to-right me-3"
                      onClick={() => handleBack()}
                    >
                      Back
                    </a>
                    <button
                      type="submit"
                      className="btn purple-btn min-height hvr-sweep-to-right "
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* </div> */}
          {/* <ToastContainer /> */}
          {/* </div> */}
        </Form>
      </FormikProvider>

      <Footer />
      <Dialog open={open}>
        <div className="common-modal space-2">
          <div className="modal-dialog ">
            <div className="modal-content">
              <div className="modal-head text-center mb-4">
                <h4 className="modal-title">
                  YAY!!! You have completed the second step
                </h4>
                <p>Please click "next" to proceed further.</p>
              </div>
              <div className="modal-body text-center">
                <div className="mt-3">
                  <NavLink
                    className="btn purple-btn min-height hvr-sweep-to-right"
                    to={
                      LoginData.chefDetails && LoginData.token
                        ? "/dashboard/adddish"
                        : "/adddish"
                    }
                  >
                    Next
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default ChefDetails;
