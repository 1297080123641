import { LOGIN_LOADING, LOGIN_SUCCESS, LOGIN_FAILURE, CLEAN_ALL_DATA, TIMEZONE } from "./Types"
import { LOGOUT_LOADING, LOGOUT_SUCCESS, LOGOUT_FAILURE, CLEAN_LOGIN_DATA } from "./Types"
import { REFRESH_TOKEN_LOADING, REFRESH_TOKEN_SUCCESS, REFRESH_TOKEN_FAILURE } from './Types'
import { SET_USER, REMEMBER_USER } from './Types';
import { SignUp, ChefDetail, AddDish, UploadDocument, AddPayment, CleanSignUpData } from "./Types";

import {COMMISSION} from "./Types"
export const SignUpAction = (data) => ({
   type: SignUp,
   data
})


export const ChefDetailAction = (data) => ({
   type: ChefDetail,
   data
})


export const AddDishAction = (data) => ({
   type: AddDish,
   data
})


export const UploadDocumentAction = (data) => ({
   type: UploadDocument,
   data
})


export const AddPaymentAction = (data) => ({
   type: AddPayment,
   data
})

export const CleanSignUpDataAction = (data) => ({
   type: CleanSignUpData,
   data
})


export const loginBegin = () => ({
   type: LOGIN_LOADING
})

export const loginSuccess = (logindata) => ({
   type: LOGIN_SUCCESS,
   logindata,
})

export const loginFailure = (loginerror) => ({
   type: LOGIN_FAILURE,
   loginerror

})

export const setUser = (data) => ({
   type: SET_USER,
   data,
})

export const rememberUser = (data) => ({
   type: REMEMBER_USER,
   data,
})


export const logoutBegin = () => ({
   type: LOGOUT_LOADING
})

export const logoutSuccess = (data) => ({
   type: LOGOUT_SUCCESS,
   data,
})

export const logoutFailure = (error) => ({
   type: LOGOUT_FAILURE,
   error
})


export const refreshTokenBegin = () => ({
   type: REFRESH_TOKEN_LOADING
})

export const refreshTokenSuccess = (data) => ({
   type: REFRESH_TOKEN_SUCCESS,
   data,
})

export const refreshTokenFailure = (error) => ({
   type: REFRESH_TOKEN_FAILURE,
   error

})


export const cleanLoginData = () => ({
   type: CLEAN_LOGIN_DATA,
})


export const cleanAllData = () => ({
   type: CLEAN_ALL_DATA,
})

export const commissionData = (data) => ({
  type: COMMISSION,
  payload: data, 
});
export const timeZoneData = (data) => ({
  type: TIMEZONE,
  payload: data,
});