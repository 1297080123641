import { LOGIN_LOADING, LOGIN_SUCCESS, LOGIN_FAILURE, SET_USER, REMEMBER_USER } from '../Actions/Types';
import { REFRESH_TOKEN_LOADING, REFRESH_TOKEN_SUCCESS, REFRESH_TOKEN_FAILURE } from '../Actions/Types';
import { LOGOUT_LOADING, LOGOUT_SUCCESS, LOGOUT_FAILURE } from '../Actions/Types';
import { CLEAN_LOGIN_DATA } from '../Actions/Types'
import { CLEAN_ALL_DATA } from '../Actions/Types';





const login = (state, action) => {
    if (typeof state === 'undefined') {
        return {
            remember: {},
            data: {},
            loading: false,
            error: {},
            logoutdata: {},
            logouterror: {}

        }
    }

    switch (action.type) {
        case CLEAN_ALL_DATA:
            return {
                ...state,
                data: {},
                loading: false,
                error: {},
                logoutdata: {},
                logouterror: {}

            }
        case CLEAN_LOGIN_DATA:
            return {
                ...state,
                data: {},
                loading: false,
                error: {},
                logoutdata: {},
                logouterror: {}

            }

        case LOGIN_LOADING:

            return {
                ...state,
                loading: true,
                error: {},

            }

        case LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                error: {},
                data: action.logindata
            }

        case LOGIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.loginerror,
                data: {},
            }

        case LOGOUT_LOADING:
            return {
                ...state,
                loading: true,
                logouterror: action.logouterror
            }
        case LOGOUT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: {},
                data: {},
                logoutdata: action.logoutdata
            }
        case LOGOUT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.loginerror
            }

        case SET_USER:
            return {
                ...state,
                loading: false,
                error: {},
                data: { ...action.data }
            }
        case REMEMBER_USER:
            return {
                ...state,
                remember: action.data
            }

        case REFRESH_TOKEN_LOADING:
            return {
                ...state,
                loading: true,
                error: {}
            }
        case REFRESH_TOKEN_SUCCESS:
            return {
                ...state,
                loading: false,
                error: {},
                data: { ...state.data, token: action.data }
            }
        case REFRESH_TOKEN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        default:
            return state






    }
}



export default login;