import React from "react";
import { ImageURL } from "./config/config";
import { version_code } from "./config/config";
const app = ImageURL + "assets/images/App-Store-small.png";
const google = ImageURL + "assets/images/Google-Play-small.png";
const footerLogo = ImageURL + "assets/images/logo5.png";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="footer-menu">
          <div className="container">
            <div className="row">
              <div className="col-md-5 footer-content">
                <div className="footer-top-left">
                  <figure>
                    <img src={footerLogo} />
                  </figure>
                  <div className="google-btn mt-4">
                    <a
                      className="app-strore-btn me-4"
                      target="_blank"
                      href="https://apps.apple.com/us/app/wannaeat/id1661904255"
                    >
                      <img src={app} />
                    </a>
                    <a
                      className="google-play-btn"
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.wannaeat"
                    >
                      <img src={google} />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 footer-content ps-4">
                <h3>ABOUT WANNA EAT</h3>
                <ul>
                  <li>
                    <a href="/about-us">About Us</a>
                  </li>
                  <li>
                    <a href="/faqs">FAQs</a>
                  </li>
                  <li>
                    <a href="/terms">Terms & Conditions </a>
                  </li>
                  <li>
                    <a href="/privacy-policy">Privacy Policy </a>
                  </li>
                  {/* <li><a href="/refund">Refund Policy </a></li> */}
                  <li>
                    <a href="/contact-us">Contact Us</a>
                  </li>
                </ul>
              </div>
              <div className="col-md-3 footer-content">
                <h3>social media</h3>
                <ul className="footer-social" style={{ marginLeft: "20px" }}>
                  <li>
                    <a
                      href="https://www.instagram.com/orderwannaeat/"
                      target="_blank"
                    >
                      <i className="ri-instagram-line"></i>
                    </a>
                  </li>
                  {/* <li>
                                        <a href="javascript:;"><i className="ri-twitter-line"></i></a>
                                    </li> */}
                  <li>
                    <a
                      href="https://www.facebook.com/orderwannaeat/"
                      target="_blank"
                    >
                      <i className="ri-facebook-line"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-footer">
          <div className="container">
            <p>WannaEat © 2023, v{version_code}</p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
