import React from 'react'
import { Outlet } from 'react-router-dom'
import ChefStep from '../component/ChefStep'
import InnerHeader from '../component/dashboard/InnerHeader'
import SideBar from '../component/dashboard/SideBar'
import Footer from '../component/Footer'
import Header from '../component/Header'
export default function Layout() {
    return (
        <div>
            <Header />
            <Outlet />
        </div>
    )
}
