import React, { useEffect, useState } from "react";
import axios from "axios";
import Footer from "./Footer";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { CateringlURL, ImageURL } from "./config/config";
import { Typography } from "@material-ui/core";

const CateringService = () => {
  const [catering, setCatering] = useState();
  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState([]);
  const cateringFetch = async () => {
    setLoading(true);
    const response = await axios.post(CateringlURL, { type: "Catering" });
    // console.log(response)
    setCatering(response.data.data);
    setImages(response?.data?.data?.path);
    setLoading(false);
  };
  useEffect(() => {
    cateringFetch();
  }, []);

  const options = {
    responsiveClass: true,
    autoplay: false,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    },
  };


  return (
    <>
      {catering && catering.testimonials?.length ? (
        <div className="wraper-inner padding-head">
          <div className="inner-pages">
            <div className="inner-banner inner-space">
              <figure>
                <img src="/static/media/contact-banner.a055178b0b3ad437a5d5.png"></img>
              </figure>
              <div className="container">
                <div className="banner-inner-content ">
                  <h4>Catering Services</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="intro-content space-similer">
            <div className="container">
              <div className="intro-content-head text-center ps-5 pe-5">
                <h2 className="mb-3">INTRODUCTION</h2>
              </div>
              <div class="catering-content-box">
                <p>
                  <Typography
                    dangerouslySetInnerHTML={{ __html: catering?.headerH1 }}
                    variant="h6"
                  ></Typography>
                </p>
                <p>
                  <Typography
                    dangerouslySetInnerHTML={{ __html: catering?.headerH2 }}
                    variant="h6"
                  ></Typography>
                </p>
              </div>

              <div className="intro-slider pt-5">
                <OwlCarousel
                  className="owl-theme"
                  // items={3}
                  // loop
                  // nav
                  margin={8}
                  {...options}
                >
                  {images.length &&
                    images?.map((item, index) => (
                      <div className="item">
                        <figure>
                          <img src={`${item}`} alt={`Image ${index}`}></img>
                        </figure>
                      </div>
                    ))}
                </OwlCarousel>
              </div>
            </div>
          </div>

          <div className="contact-info-box">
            <div className="container">
              <div className="contact-info-content">
                <h2>
                  Contact Us to host your next <br></br> Party / Festival
                </h2>
                <ul className="banner-contact-list">
                  <li>
                    <figure>
                      <i class="ri-mail-line"></i>
                    </figure>
                    <figcaption>
                      <h5>email</h5>
                      <a href={`mailto:${catering?.emailText}`}>
                        {catering?.emailText}
                      </a>
                      {/* <a>{catering?.emailText}</a> */}
                    </figcaption>
                  </li>
                  <li>
                    <figure>
                      <i class="ri-phone-line"></i>
                    </figure>
                    <figcaption>
                      <h5>CONTACT</h5>
                      <a href={`tel:+1 ${catering?.phoneNumberText}`}>
                        +1{" "}
                        {catering?.phoneNumberText?.replace(
                          /(\d{3})(\d{3})(\d{4})/,
                          "$1-$2-$3"
                        )}
                      </a>
                      {/* <a>{catering?.phoneNumberText}</a> */}
                    </figcaption>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="testimonaials-slider pt-5  space-similer">
            <div className="container">
              <div className="intro-content-head text-center mb-5">
                <h2 className="">testimonials</h2>
              </div>
              <OwlCarousel
                className="owl-theme"
                // items={3}
                // loop
                // nav
                margin={8}
                {...options}
              >
                <div className="item">
                  <figure>
                    <i class="ri-double-quotes-l"></i>
                  </figure>
                  <figcaption>
                    <h4>{catering?.testimonials[0]?.mainHeading}</h4>
                    <p>{catering?.testimonials[0]?.description}</p>
                  </figcaption>
                </div>
                <div className="item">
                  <figure>
                    <i class="ri-double-quotes-l"></i>
                  </figure>
                  <figcaption>
                    <h4>{catering?.testimonials[1]?.mainHeading}</h4>
                    <p>{catering?.testimonials[1]?.description} </p>
                  </figcaption>
                </div>
                <div className="item">
                  <figure>
                    <i class="ri-double-quotes-l"></i>
                  </figure>
                  <figcaption>
                    <h4>{catering?.testimonials[2]?.mainHeading}</h4>
                    <p>{catering?.testimonials[2]?.description} </p>
                  </figcaption>
                </div>
                <div className="item">
                  <figure>
                    <i class="ri-double-quotes-l"></i>
                  </figure>
                  <figcaption>
                    <h4>{catering?.testimonials[3]?.mainHeading}</h4>
                    <p>{catering?.testimonials[3]?.description} </p>
                  </figcaption>
                </div>
                <div className="item">
                  <figure>
                    <i class="ri-double-quotes-l"></i>
                  </figure>
                  <figcaption>
                    <h4>{catering?.testimonials[4]?.mainHeading}</h4>
                    <p>{catering?.testimonials[4]?.description} </p>
                  </figcaption>
                </div>
                <div className="item">
                  <figure>
                    <i class="ri-double-quotes-l"></i>
                  </figure>
                  <figcaption>
                    <h4>{catering?.testimonials[5]?.mainHeading}</h4>
                    <p>{catering?.testimonials[5]?.description} </p>
                  </figcaption>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      <Footer />
    </>
  );
};
export default CateringService;
