import React, { useEffect, useState } from "react";
import Footer from "../component/Footer";
import Header from "../component/Header";

import videoImage from "../assets/images/video-img.png";
import Testimonial from "../component/Testimonial";
import { NavLink } from "react-router-dom";
import ContactForm from "../component/ContactForm";
import { cleanAllData, CleanSignUpDataAction } from "../Actions/ActionCreators";
import { useDispatch } from "react-redux";
import { ImageURL, LandingURL } from "../component/config/config";
import axios from "axios";
import { Typography } from "@material-ui/core";
import { Helmet } from "react-helmet";
const app = ImageURL + "assets/images/App-Store-small.png";
const google = ImageURL + "assets/images/Google-Play-small.png";
const shape1 = ImageURL + "assets/images/shape-1.png";
const shef = ImageURL + "assets/images/woman.png";
const joinIcon = ImageURL + "assets/images/join-icon.png";
const chefIcon = ImageURL + "assets/images/chef-icon.png";
const customer1 = ImageURL + "assets/images/Customer Image 1.png";
const customer2 = ImageURL + "assets/images/Customer Image 2.png";
const customer3 = ImageURL + "assets/images/Customer Image 3.png";
const customer4 = ImageURL + "assets/images/Customer Image 4.png";
const shape2 = ImageURL + "assets/images/shape-2.png";
const chef1 = ImageURL + "assets/images/Chef Image 1.png";
const chef2 = ImageURL + "assets/images/create.jpg";
const chef3 = ImageURL + "assets/images/Chef Image 3.png";
const chef4 = ImageURL + "assets/images/Chef Image 4.png";

const Home = () => {
  const dispatch = useDispatch();
  const [landing, setLanding] = useState();
  const landFetch = async () => {
    const response = await axios.post(LandingURL);
    setLanding(response.data.data);
  };
  useEffect(() => {
    localStorage.clear();
    dispatch(cleanAllData());
    dispatch(CleanSignUpDataAction());
    landFetch();
  }, []);
  return (
    <>
      {/* {console.log(landing)} */}

      {/* <Header /> */}
      <div className="wraper-inner padding-head">
        <Helmet>
          <title> Wanna Eat </title>
          <meta
            name="keywords"
            content="home cooked meals, home cooked food, home cooked breakfast, home cooked Indian food, home cooked family meals, home cooked lunch, home cooked dinner, home cooked evening snacks"
          />
          <meta
            name="description"
            content=" Join WannaEat and pamper yourself with traditional family recipes that have been passed down through generations to modern twists on classics or innovations. Download app, join and order tasty home cooked food now."
          />
        </Helmet>
        <section className="main-banner">
          <div className="banner-main-box ">
            <div className="banner-top">
              <div className="container">
                <div className="banner-top-inner">
                  <div className="banner-top-L">
                    <small>
                      <Typography
                        dangerouslySetInnerHTML={{ __html: landing?.headerH1 }}
                      ></Typography>
                    </small>
                    <h1>
                      <Typography
                        dangerouslySetInnerHTML={{ __html: landing?.headerH2 }}
                      ></Typography>
                    </h1>
                    <p className="banner-top-L-content">
                      <Typography
                        dangerouslySetInnerHTML={{ __html: landing?.headerH3 }}
                      ></Typography>
                    </p>
                    <div className="google-btn mt-4">
                      <a
                        className="app-strore-btn me-4"
                        target="_blank"
                        href="https://apps.apple.com/us/app/wannaeat/id1661904255"
                      >
                        <img src={app} />
                      </a>
                      <a
                        className="google-play-btn"
                        target="_blank"
                        href="https://play.google.com/store/apps/details?id=com.wannaeat"
                      >
                        <img src={google} />
                      </a>
                    </div>
                  </div>
                  <div className="banner-top-R">
                    <figure>
                      <img src={shef} />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="how-its-works-customer space-similer"
          id="join-customer"
        >
          <span className="shape-1">
            <img src={shape1} />
          </span>
          <div className="container">
            <div className="similer-title-head text-center mb-4">
              <span className="sub-title">Our magic</span>
              <h2>how it works</h2>
            </div>
            <ul className="inner-tabs-link text-center pt-5 d-flex justify-content-center">
              <li className="active">
                <a href="#join-customer">
                  <span>
                    <img src={joinIcon} />
                  </span>{" "}
                  Join as Customer
                </a>
              </li>
              <li>
                <a href="#join-chef" className="join-chef">
                  <span>
                    <img src={chefIcon} />
                  </span>{" "}
                  Join as Chef
                </a>
              </li>
            </ul>
            <div className="row join-customer">
              <div className="col-md-3">
                <div className="food-box-similer">
                  <figure>
                    <img src={customer1} />
                  </figure>
                  <figcaption className="text-center">
                    <h3 className="mb-2">
                      download <br />
                      app
                    </h3>
                    <p>
                      {/* Lorem ipsum dolor sit amet,
											consectetur adipiscing elit, sed do
											eiusmod tempor incididunt ut labore
											et dolore magna aliqua */}
                      <Typography
                        dangerouslySetInnerHTML={{
                          __html: landing?.customerContent[0].description,
                        }}
                        variant={"h6"}
                      ></Typography>
                    </p>
                  </figcaption>
                </div>
              </div>
              <div className="col-md-3">
                <div className="food-box-similer">
                  <figure>
                    <img src={customer2} />
                  </figure>
                  <figcaption className="text-center">
                    <h3 className="mb-2">Select Choice of Home Cooked Food</h3>
                    <p>
                      <Typography
                        dangerouslySetInnerHTML={{
                          __html: landing?.customerContent[1].description,
                        }}
                        variant={"h6"}
                      ></Typography>
                    </p>
                  </figcaption>
                </div>
              </div>
              <div className="col-md-3">
                <div className="food-box-similer">
                  <figure>
                    <img src={customer3} />
                  </figure>
                  <figcaption className="text-center">
                    <h3 className="mb-2">Chef prepares your order</h3>
                    <p>
                      <Typography
                        dangerouslySetInnerHTML={{
                          __html: landing?.customerContent[2].description,
                        }}
                        variant={"h6"}
                      ></Typography>
                    </p>
                  </figcaption>
                </div>
              </div>
              <div className="col-md-3">
                <div className="food-box-similer">
                  <figure>
                    <img src={customer4} />
                  </figure>
                  <figcaption className="text-center">
                    <h3 className="mb-2">Get your order delivered</h3>
                    <p>
                      <Typography
                        dangerouslySetInnerHTML={{
                          __html: landing?.customerContent[3].description,
                        }}
                        variant={"h6"}
                      ></Typography>
                    </p>
                  </figcaption>
                </div>
              </div>
              <div className="google-btn mt-4 text-center mt-5">
                <a
                  className="app-strore-btn me-4"
                  target="_blank"
                  href="https://apps.apple.com/us/app/wannaeat/id1661904255"
                >
                  <img src={app} />
                </a>
                <a
                  className="google-play-btn"
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.wannaeat"
                >
                  <img src={google} />
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="how-its-works-chef space-similer" id="join-chef">
          <span className="shape-2">
            <img src={shape2} />
          </span>
          <div className="container">
            <div className="similer-title-head text-center mb-4">
              <span className="sub-title">Join Us</span>
              <h2>JOIN US AS CHEF</h2>
            </div>
            <ul className="inner-tabs-link join-chef-tabs text-center pt-5 d-flex justify-content-center">
              <li>
                <a className="join-customer" href="#join-customer">
                  <span>
                    <img src={joinIcon} />
                  </span>{" "}
                  Join as Customer
                </a>
              </li>
              <li className="active join-chef">
                <a href="#join-chef">
                  <span>
                    <img src={chefIcon} />
                  </span>{" "}
                  Join as Chef
                </a>
              </li>
            </ul>
            <div className="row join-customer">
              <div className="col-md-3">
                <div className="food-box-similer">
                  <figure>
                    <img src={chef1} />
                  </figure>
                  <figcaption className="text-center">
                    <h3 className="mb-2">Set-up your Chef profile</h3>
                    <p>
                      <Typography
                        dangerouslySetInnerHTML={{
                          __html: landing?.chefContent[0].description,
                        }}
                        variant={"h6"}
                      ></Typography>
                    </p>
                  </figcaption>
                </div>
              </div>
              <div className="col-md-3">
                <div className="food-box-similer">
                  <figure>
                    <img src={chef2} />
                  </figure>
                  <figcaption className="text-center">
                    <h3 className="mb-2">
                      add your dish on <br />
                      menu
                    </h3>
                    <p>
                      <Typography
                        dangerouslySetInnerHTML={{
                          __html: landing?.chefContent[1].description,
                        }}
                        variant={"h6"}
                      ></Typography>
                    </p>
                  </figcaption>
                </div>
              </div>
              <div className="col-md-3">
                <div className="food-box-similer">
                  <figure>
                    <img src={chef3} />
                  </figure>
                  <figcaption className="text-center">
                    <h3 className="mb-2">
                      receive your
                      <br /> order
                    </h3>
                    <p>
                      <Typography
                        dangerouslySetInnerHTML={{
                          __html: landing?.chefContent[2].description,
                        }}
                        variant={"h6"}
                      ></Typography>
                    </p>
                  </figcaption>
                </div>
              </div>
              <div className="col-md-3">
                <div className="food-box-similer">
                  <figure>
                    <img src={chef4} />
                  </figure>
                  <figcaption className="text-center">
                    <h3 className="mb-2">
                      dispatch <br />
                      order
                    </h3>
                    <p>
                      <Typography
                        dangerouslySetInnerHTML={{
                          __html: landing?.chefContent[3].description,
                        }}
                        variant={"h6"}
                      ></Typography>
                    </p>
                  </figcaption>
                </div>
              </div>
              <div className="text-center mt-5">
                <a href="/signup">
                  <button className="btn purple-btn banner-btn">
                    Join as Chef
                  </button>
                </a>
              </div>
            </div>
          </div>
        </section>

        <Testimonial />
        <section className="contact-us-home space-similer">
          <span className="shape-3">
            <img src="images/shape-3.png" />
          </span>
          <div className="container">
            <div className="similer-title-head text-center mb-4">
              <span className="sub-title">Contact us</span>
              <h2>send us a message</h2>
            </div>
            <ContactForm />
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Home;
