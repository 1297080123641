import React, { useEffect, useState } from "react";
import ChefStep from "../component/ChefStep";
import Footer from "../component/Footer";
import Header from "../component/Header";

import { UploadURL } from "../component/config/config";
import axios from "axios";
import { Dialog } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { UploadDocumentAction } from "../Actions/ActionCreators";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ChefURL } from "../component/config/config";
import { ImageURL } from "../component/config/config";
import { tostS, tostE } from "../toast";
import axiosInstance from "../component/config/axios";
const FileIcon = ImageURL + "assets/images/file-icon.jpg";

const Upload = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const LoginData = useSelector((state) =>
    state.login.data && state.login.data.chefDetails ? state.login.data : {}
  );
  const SignupData = useSelector((state) =>
    state.Signup.data ? state.Signup.data : {}
  );
  //For upload a documents
  const [documentImage, setDocument] = useState([]);
  const [oldDocumentImage, setOldDocumentImage] = useState([]);
  const [deleteImage, setDeleteImage] = useState([]);
  const [error, setError] = useState([]);
  const userToken = useSelector((state) =>
    state.login && state.login.data & state.login.data.token
      ? state.login.data.token
      : null
  );
  const [selectedFile, setSelectedFile] = useState([]);
  const [image, setImage] = useState([]);
  const id =
    LoginData.chefDetails && LoginData.chefDetails._id
      ? LoginData.chefDetails._id
      : SignupData.chefDetails && SignupData.chefDetails._id
      ? SignupData.chefDetails._id
      : 0;
  const chefId =
    LoginData.chefDetails && LoginData.chefDetails.chefID
      ? LoginData.chefDetails.chefID
      : SignupData.chefDetails && SignupData.chefDetails.chefID
      ? SignupData.chefDetails.chefID
      : 0;
  // const addDish = SignupData.chefDetails.addDishComplete === true
  const che =
    LoginData.chefDetails && LoginData.chefDetails.addDishComplete
      ? LoginData.chefDetails.addDishComplete
      : SignupData.chefDetails && SignupData.chefDetails.addDishComplete
      ? SignupData.chefDetails.addDishComplete
      : false;
  const fetchData = async () => {
    // const formData = new FormData();
    // formData.append("imagePath", "chef/" + localStorage.getItem("chefID"));

    const body = {
      _id: id,
    };
    const response = await axiosInstance().get(ChefURL, body);
    const u = response?.data?.data;
    // console.log("u", u)
    const uploadArray = u.documentUrls.map((dat) => {
      const dataType = dat.includes(".pdf");
      if (dataType) {
        return FileIcon;
      } else {
        return dat;
      }
    });
    setImage([...uploadArray]);
    setSelectedFile([...uploadArray]);
    setOldDocumentImage(u.documents);
    // setDeleteImage(u.documents)
  };
  useEffect(() => {
    if (!che) {
      navigate("/");
    }
    fetchData();
    // if (LoginData.chefDetails.chefID === null || SignupData.chefDetails.chefID === null) {
    // 	navigate("/home")
    // }
  }, []);

  function handleChange(e) {
    if (selectedFile.length < 10) {
      if (
        e.target.files[0].type == "application/pdf" ||
        e.target.files[0].type == "image/jpg" ||
        e.target.files[0].type == "image/jpeg" ||
        e.target.files[0].type == "image/png" ||
        e.target.files[0].type == "application/msword"
      ) {
        if (e.target.files[0].size <= 3145728) {
          let newFile = Object.values(e.target.files);
          setSelectedFile((files) => [...files, ...newFile]);
          let newImage = [...image];
          if (
            e.target.files[0].type == "application/pdf" ||
            e.target.files[0].type == "application/msword"
          ) {
            newImage.push(FileIcon);
          } else {
            newImage.push(URL.createObjectURL(e.target.files[0]));
          }
          setImage(newImage);
          console.log(image);
          setError("");
        } else {
          setError("Size must be less than 3 MB");
        }
      } else {
        setError("Invalid File Format ");
      }
    } else {
      setError("Only 10 images handle allowed");
    }
  }
  //For add more documents
  const addFormFields = (event) => {
    // console.log(documentImage.length);
    if (selectedFile.length <= 10) {
      setDocument([...documentImage, {}]);
    } else {
      setError("Only 10 images add allowed");
    }
  };
  //For Skip box on click skip button
  const [skip, setSkip] = useState(false);
  const handleOpen = () => {
    setSkip(true);
  };
  const handleClickClose = () => {
    setSkip(false);
  };

  //For Next box on click next button
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const getSelectedFile = (e) => {
    let newFile = Object.values(e.target.files);
    setSelectedFile((files) => [...files, ...newFile]);
    // console.log(newFile);
  };
  const deleteSelectedFile = (index) => {
    const arr = [...selectedFile];
    const arr1 = [...image];
    const arr2 = [...oldDocumentImage];
    const arr3 = [...oldDocumentImage];
    arr.splice(index, 1);
    arr1.splice(index, 1);
    arr2.splice(index, 1);
    let deleteData = arr3.splice(index, 1);
    setSelectedFile(arr);
    setImage(arr1);
    setOldDocumentImage(arr2);
    setDeleteImage(deleteData);
  };
  const handleSubmission = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("id", id);
    formData.append("imagePath", "chef/" + chefId);
    formData.append("deleteDocument", JSON.stringify(deleteImage));
    formData.append("oldDocuments", JSON.stringify(oldDocumentImage));
    for (let key in selectedFile) {
      formData.append("documents", selectedFile[key]);
    }
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    document.body.classList.add("loading-indicator");
    if (selectedFile.length <= 10) {
      axios
        .post(UploadURL, formData, config)
        .then((response) => {
          // console.log(response.data);

          if (response.status === 200) {
            document.body.classList.remove("loading-indicator");
            handleClickOpen();
            dispatch(UploadDocumentAction(response.data.data));
          }
        })
        .catch((error) => {
          // console.log("message" + "" + error.response.data.message);
          document.body.classList.remove("loading-indicator");
          tostE(error.response.data.message);
        });
    } else {
      setError("Only 10 images allowed");
    }
  };

  return (
    <>
      <ChefStep />
      <section className="main-wrapper-login mar-t mb-5">
        <div className="container">
          <div className="form-box row mb-5">
            <div className="form-head text-center">
              <h2>Upload documents </h2>
            </div>

            <div className="col-md-9 m-auto mb-5 pb-5">
              <div className="mb-3">
                <small className="sub-title-inner">
                  At Wanna Eat, we strive to give our customers the best food
                  experience ever. An essential part of that is to make sure all
                  our chefs are trained in food safety. Please take the
                  following training and upload your certificate of completion
                  here for our records.
                  <br />
                  <a
                    style={{ textDecoration: "underLine" }}
                    href="https://www.efoodhandlers.com/shop/Program?t=BFS&StateCode=NJ&County=Essex&CID=&lg=en"
                    target="_blank"
                  >
                    New Jersey Food Safety Certificate
                  </a>
                  <br />
                  If you already have a food handler or food manager
                  certificate, you can submit a picture or a scanned copy of
                  your document. Please make sure the photo or scan clearly
                  displays your name and the expiration date.
                  <br />
                  If you have further questions, you can go to our Chef FAQs
                  page for more details.
                </small>
                <label className="form-label">
                  Upload Documents{" "}
                  <span className="tool-tip-box con-tooltip top">
                    <i className="ri-information-line"></i>
                    <div className="tooltip ">
                      <p>Document size should not be more than 5 MB</p>
                    </div>
                  </span>
                </label>
                <div className="uploading-group upload-document">
                  <ul className="uploding-list"></ul>

                  {image?.length > 0 &&
                    image.map((file, index) => {
                      return (
                        <div className="uploading-group upload-document">
                          <ul className="uploding-list">
                            <li key={index}>
                              {/* <i className="ri-file-2-line"></i> */}
                              {/* {file.name} */}
                              <img src={file} />
                              <small
                                className="close-btn"
                                onClick={() => deleteSelectedFile(index)}
                              >
                                <i className="ri-close-line"></i>
                              </small>
                            </li>
                          </ul>
                        </div>
                      );
                    })}

                  {selectedFile.length != 10 ? (
                    <div className="file file--upload">
                      <button onClick={() => addFormFields()}>
                        <label className="file-label">
                          <i className="ri-add-circle-line"></i>{" "}
                          <input
                            type="file"
                            id="input"
                            multiple=""
                            className="form-control  form-control-aatch-file"
                            name="file[]"
                            onChange={(e) => handleChange(e)}
                          />
                          <span>Add more</span>
                        </label>
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {error !== "" ? (
                  <span className="errorMessage">{error}</span>
                ) : null}
              </div>
              <div className="mb-3 skip-next-back-bg text-center mt-5 d-flex align-items-center justify-content-center">
                <a
                  type="button"
                  onClick={handleOpen}
                  className="btn purple-btn min-height hvr-sweep-to-right trans-btn me-3 a"
                  data-bs-toggle="modal"
                  data-bs-target="#next-step"
                >
                  Skip for Now
                </a>
                <button
                  type="submit"
                  onClick={handleSubmission}
                  className="btn purple-btn min-height hvr-sweep-to-right me-3"
                  data-bs-toggle="modal"
                  data-bs-target="#next-step"
                >
                  Next
                </button>
                <a
                  className="btn min-height black-btn  hvr-sweep-to-right"
                  onClick={() => navigate(-1)}
                >
                  Back
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* </div> */}
      {/* <ToastContainer /> */}
      {/* // </div> */}

      <Footer />
      <Dialog open={open}>
        <div className="common-modal space-2">
          <div className="modal-dialog ">
            <div className="modal-content">
              {/* <button
								type="button"
								onClick={handleClose}
								className="close-btn"
								data-bs-dismiss="modal"
								aria-label="Close"
							>
								<i className="ri-close-circle-line"></i>
							</button> */}
              <div className="modal-head text-center mb-4">
                <h4 className="modal-title">
                  YAY!!! You have completed the fourth step
                </h4>
                <p>Please click "next" to proceed further.</p>
              </div>
              <div className="modal-body text-center">
                <div className="mt-3">
                  <NavLink
                    className="btn purple-btn min-height hvr-sweep-to-right"
                    to={"/welcome"}
                  >
                    Next
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog open={skip}>
        <div className="common-modal space-2">
          <div className="modal-dialog">
            <div className="modal-content">
              {/* <button
								type="button"
								className="close-btn"
								onClick={handleClickClose}
								data-bs-dismiss="modal"
								aria-label="Close"
							>
								<i className="ri-close-circle-line"></i>
							</button> */}
              <div className="modal-head text-center mb-4">
                <h4 className="modal-title">SKIPPED THIS STEP</h4>
                <p>No problem. You can complete this step later.</p>
              </div>
              <div className="modal-body text-center">
                <div className="mt-3">
                  <NavLink
                    className="btn purple-btn min-height hvr-sweep-to-right"
                    to={"/welcome"}
                  >
                    Next
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default Upload;
