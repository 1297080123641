import React, { useEffect, useState, useRef } from "react";
import { useFormik, Form, Field, ErrorMessage, FormikProvider } from "formik";
import * as Yup from "yup";
import { AddDishURL, LoginChefURL } from "../component/config/config";
import { DishURL } from "../component/config/config";
import {
  FoodCategoryURL,
  ExtensionChangeURL,
} from "../component/config/config";
import { CuisinesURL } from "../component/config/config";
import { Avatar, Dialog } from "@material-ui/core";
import axios from "axios";
import Footer from "../component/Footer";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import ChefStep from "../component/ChefStep";
import { useDispatch } from "react-redux";
import { AddDishAction, commissionData, setUser } from "../Actions/ActionCreators";
import { useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { ImageURL } from "../component/config/config";
import { tostS, tostE } from "../toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripLines } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../component/config/axios";
import { convertStringToPascalCase } from "../utility/convertStringToPascalCase";

const UploadDish = () => {
  const dispatch = useDispatch();
  const grip = <FontAwesomeIcon icon={faGripLines} />;
  const commission = useSelector((state) => state.commissionReducer);
  const chefCommission = commission?.commission?.commission
    ? commission?.commission?.commission
    : commission?.commission?.globalCommission;

  const LoginData = useSelector((state) =>
    state.login.data && state.login.data.chefDetails ? state.login.data : {}
  );
  const SignupData = useSelector((state) =>
    state.Signup.data ? state.Signup.data : {}
  );
  const che =
    LoginData.chefDetails && LoginData.chefDetails.chefDetailsComplete
      ? LoginData.chefDetails.chefDetailsComplete
      : SignupData.chefDetails && SignupData.chefDetails.chefDetailsComplete
      ? SignupData.chefDetails.chefDetailsComplete
      : false;
  const chef_id = localStorage.getItem("Chefid");
  const dish_id = localStorage.getItem("Dishid");
  const userToken = useSelector((state) =>
    state.login && state.login.data & state.login.data.token
      ? state.login.data.token
      : null
  );
  const dragItem = useRef();
  const dragOverItem = useRef();
  const dragStart = (e, position) => {
    dragItem.current = position;
  };
  // const LoginData = useSelector(state => state.login.data && state.login.data.chefDetails ? state.login.data : null);

  const setFun = (data) => {
    if (LoginData !== null) {
      const fData = {
        ...LoginData,
        chefDetails: {
          ...data,
        },
      };
      dispatch(setUser(fData));
    }
  };
  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };
  const drag = (e) => {
    const copyItems = [...formValues];
    const dragItemContent = copyItems[dragItem.current];
    copyItems.splice(dragItem.current, 1);
    copyItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setFormValues(copyItems);
  };

  const [tagsData, setTagsData] = useState([
    {
      title: "Dairy Free",
      status: false,
    },
    {
      title: "Gluten Free",
      status: false,
    },
    {
      title: "Nut Free",
      status: false,
    },
    {
      title: "Keto-Friendly",
      status: false,
    },
    {
      title: "Low Carb",
      status: false,
    },
    {
      title: "Organic",
      status: false,
    },
    {
      title: "Vegan",
      status: false,
    },
    {
      title: "Vegetarian",
      status: false,
    },
    {
      title: "Halal",
      status: false,
    },
    {
      title: "Kosher",
      status: false,
    },
  ]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [foodType, setFoodType] = useState();
  const [cuisineType, setCuisineType] = useState();
  const [image, setImage] = useState("");
  const [formValues, setFormValues] = useState([]);
  const [error, setError] = useState(false);
  // const textCapitalize = (re) => {
  //     return (

  //         // re.charAt(0).toUpperCase() + re.slice(1)
  //         re.toLowerCase().split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ')
  //     )
  // }

  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i] = e.target.value;
    setFormValues(newFormValues);
    setFieldValue("ingredients", newFormValues);
  };
  const inputRef = useRef(null);
  //For add a ingredients field
  let addFormFields = () => {
    setFormValues([...formValues, ""]);
    setFieldValue("ingredients", [...formValues, ""]);
    // inputRef.current.focus()
  };
  //For remove a ingredients field
  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
    setFieldValue("ingredients", newFormValues);
  };
  const [editInfo, setEditInfo] = useState();

  //For take a food type and cuisine details from admin
  const fetchData = async () => {
    const foodresponse = await axios.get(FoodCategoryURL);
    const cuisineresponse = await axios.get(CuisinesURL);
    const responses = await axiosInstance().get(LoginChefURL, {});
    dispatch(commissionData(responses.data.data));
    setFoodType(foodresponse?.data?.data);
    setCuisineType(cuisineresponse?.data?.data);

    const body = {
      dishId: dish_id,
      chefId: chef_id,
    };
    const response = await axios.post(DishURL, body);
    const d = response?.data?.data;
    setEditInfo(d.dishImage);
    if (d._id) {
      setValues({
        dishId: d._id,
        name: d.name,
        // cuisine: d?.cuisineName.map((data) => ({
        //     value: data.cuisine,
        //     label: data.cuisineName,

        // })),
        cuisine: d.cuisine,
        price: d.price.toString(),
        foodType: d.category,
        portionSize: d.portionSize,
        preprationTime: d.preprationTime.toString(),
        description: d.description,
        // cuisineOffered: b?.cuisines.map((da) => ({
        //     value: da._id,
        //     label: da.name,
        // })),
        ingredients: d.ingredientResult.map((Daa) => Daa.name),
        restrictedDiets: d.restrictedDiets,
        dishImage: d.dishImage,
      });
    }
    let tagArray = tagsData.map((dataaa) => {
      if (d?.restrictedDiets?.includes(dataaa.title)) {
        return {
          title: dataaa.title,
          status: true,
        };
      } else {
        return dataaa;
      }
    });
    setTagsData([...tagArray]);
    const fvArray = d.ingredientResult.map((Daa) => Daa.name);
    setFormValues([...fvArray]);
  };

  useEffect(() => {
    if (!che) {
      navigate("/");
    }
    fetchData();
  }, []);
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
  const SPACES = /^[^-\s][a-zA-Z0-9_\s-,."/;:_`~@#%&'!]+$/;
  const PRICE = /^[0-9]+(\.[0-9]{1,2})?$/;

  const [start, setStart] = useState(false);
  const myValidationSchema = Yup.object().shape({
    dishImage: Yup.mixed()
      // .test(
      //     "fileSize",
      //     "Dish Image is required",
      //     (value) => !value || (value && value.size <= 10000000)
      // )
      // .test(
      //     "fileFormat",
      //     "Unsupported Format",
      //     (value) =>
      //         !value || (value && SUPPORTED_FORMATS.includes(value.type))
      // )
      .required("Dish Image is required"),
    name: Yup.string()
      // .strict(true).trim("Extra spaces should be truncated")
      .min(3, " Dish Name must be at least 3 characters ")
      .max(55, " Dish Name must not be more than 55 characters ")
      .required("Dish Name is  required")
      .test(
        "trim",
        "Dish Name is not valid",
        (val) =>
          val !== undefined && !val.match(/^[0-9]*$/, "Dish name is not valid")
      ),

    cuisine: Yup.string().required("Cuisine is required "),
    price: Yup.string()
      // .strict(true).trim("Extra spaces should be truncated")
      .min(1, " Price must be at least 1 digits ")
      .max(8, " Price must not be more than 8 digits ")
      .required("Price is required ")
      .test(
        "is-decimal",
        "Price should be valid",
        (val) => val !== undefined && val.match(/^[0-9]+(\.[0-9]{1,2})?$/)
      )
      .test(
        "maxFourDigits",
        "Price should be more than 0 ",
        (number) => Number(number) > 0
      ),
    foodType: Yup.string().required("Food Type is required"),
    portionSize: Yup.string().required("Portion Size is required "),
    preprationTime: Yup.string()
      // .strict(true).trim("Extra spaces should be truncated")
      .required("Preparation Time is required ")
      .matches(/^\d+$/, "Enter valid Preparation Time")
      .test(
        "maxTwoDigits",
        "Preparation Time should not be more than 120 minutes",
        (number) => Number(number) <= 120
      ),
    description: Yup.string()
      .min(5, " Dish Description must be at least 5 characters ")
      .max(750, " Dish Description cannot be more than 750 characters ")
      .required("Dish Description is required "),
    ingredients: Yup.array()
      .min(1, "Ingredient(s) is/are required")
      .of(
        Yup.string()
          .trim()
          .max(30, " Ingredient(s) name must not be more than 30 characters")
          .required("Ingredient is required ")
      ),
  });
  //Get a id from a local
  let Id =
    SignupData.chefDetails && SignupData.chefDetails._id
      ? SignupData.chefDetails._id
      : SignupData._id
      ? SignupData._id
      : LoginData.chefDetails._id;

  //Get a chef id from a local
  let chefID =
    SignupData.chefDetails && SignupData.chefDetails.chefID
      ? SignupData.chefDetails.chefID
      : SignupData.chefID
      ? SignupData.chefID
      : LoginData.chefDetails.chefID;

  //For initial value
  const formik = useFormik({
    initialValues: {
      id: "",
      imagePath: "",
      price: "",
      cuisine: "",
      name: "",
      foodType: "",
      portionSize: "",
      description: "",
      ingredients: formValues,
      restrictedDiets: [],
      preprationTime: "",
      dishImage: "",
    },
    validationSchema: myValidationSchema,
    onSubmit: async (values, resetForm) => {
      let tags = [];
      tagsData.map((element) => {
        if (element.status) {
          tags.push(element.title);
        }
      });
      let unique = [];
      for (let i = 0; i < formValues.length; i++) {
        if (unique.indexOf(formValues[i]) === -1) {
          unique.push(formValues[i]);
        }
      }
      try {
        let formData = new FormData();
        if (
          values.dishId &&
          values.dishId !== null &&
          values.dishId !== undefined &&
          values.dishId !== ""
        ) {
          formData.append("dishId", values.dishId);
        }
        formData.append("id", Id);
        formData.append("price", values?.price);
        formData.append("cuisine", values?.cuisine);
        formData.append("name", convertStringToPascalCase(values?.name));
        formData.append("foodType", values?.foodType);
        formData.append("portionSize", values?.portionSize);
        formData.append("imagePath", "chef/" + chefID);
        formData.append("description", values?.description.replace(/ +/g, " "));
        formData.append("ingredients", JSON.stringify(unique));
        formData.append("restrictedDiets", JSON.stringify(tags));
        formData.append("preprationTime", values?.preprationTime);
        formData.append("dishImage", values?.dishImage);
        formData.append("oldDishImage", image == "" ? "" : editInfo);

        document.body.classList.add("loading-indicator");

        const response = await axios.post(AddDishURL, formData);
        const data = response.data.data;
        if (response.status === 200) {
          //Save a token for use in a login
          document.body.classList.remove("loading-indicator");

          localStorage.setItem("Chefid", response.data.data.chefDetails._id);
          localStorage.setItem("Dishid", response.data.data.dishDetails._id);
          dispatch(AddDishAction(data));
          setFun(data);
          formik.resetForm();

          handleClickOpen();
        }
      } catch (error) {
        setIsDisabled(false);
        document.body.classList.remove("loading-indicator");
        tostE(error.response.data.message);
      }
    },
  });
  let {
    submitCount,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    values,
    setValues,
  } = formik;

  const handleChangeTags = (i, e) => {
    const a = [...tagsData];
    a[i].status = e.target.checked;
    setTagsData([...a]);
  };

  //For Dialog box
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const priceChange = () => {
    let Z = chefCommission / 100;
    let Y = formik.values.price * Z;
    let X = formik.values.price - Y;
    return X ? X.toFixed(2) : 0;
  };

  const navigate = useNavigate();
  function handleEnter(event) {
    if (event.keyCode === 13) {
      // const form = event.target.form;
      // const index = Array.prototype.indexOf.call(form, event.target);
      // form.elements[index + 1].focus();
      event.preventDefault();
      addFormFields();
    }
  }
  let { pathname } = useLocation();
  const handleBack = () => {
    if (pathname === "/dashboard/adddish") {
      navigate("/dashboard/chefdetails");
    } else {
      navigate("/chefdetails");
    }
  };
  const handleTime = (e) => {
    let z = e.target.value;
    let zipReg = /^\d+$/;
    if (zipReg.test(z) || z === "") {
      if (z.length <= 3) {
        setFieldValue("preprationTime", z);
      }
    }
  };
  const exceptThisSymbols = ["e", "E", "+", "-", "."];

  const profil = async (e) => {
    document.body.classList.add("loading-indicator");

    if (
      e.target.files[0].type === "image/png" ||
      e.target.files[0].type === "image/jpg" ||
      e.target.files[0].type === "image/jpeg"
    ) {
      if (parseInt(e.target.files[0].size) <= 5145728) {
        document.body.classList.remove("loading-indicator");
        setFieldValue("dishImage", e.target.files[0]);
        setImage(URL.createObjectURL(e.target.files[0]));
      } else {
        tostE("Please upload a file smaller than 5 MB");
      }
    } else if (
      e.target.files[0].type === "image/heif" ||
      e.target.files[0].type === "image/heic" ||
      e.target.files[0].type === ""
    ) {
      let formData = new FormData();
      formData.append("imagePath", "chef/" + chefID);
      formData.append("extension", e.target.files[0]);

      const resp = await axios.post(ExtensionChangeURL, formData);
      document.body.classList.remove("loading-indicator");
      setFieldValue("dishImage", resp.data.data[0]);
      setImage(ImageURL + resp.data.data[0]);
    } else {
      document.body.classList.remove("loading-indicator");
      tostE("Please upload a file with png,jpg,heic or jpeg format.");
    }
  };
  return (
    <>
      <FormikProvider value={formik}>
        <Form
          autoComplete="off"
          noValidate
          // onSubmit={handleSubmit}
          onSubmit={(e) => {
            e.preventDefault();
            if (Object.values(errors).length >= 1) {
              tostE("There is an error on the form. Please fix and try again");
            }
            handleSubmit();
          }}
        >
          <ChefStep />
          <section className="main-wrapper-login mar-t mb-5">
            <div className="container">
              <div className="form-box row">
                <div className="form-head text-center">
                  <h2>Add A Dish</h2>
                </div>
                <div className="col-md-9 m-auto">
                  <div className="mb-3">
                    <div className="upload-photo">
                      <div className="avatar-upload">
                        <span className="info-tag tool-tip-box con-tooltip top">
                          <b>*</b>
                          <i className="ri-information-line"></i>
                          <div className="tooltip ">
                            <p>
                              Upload a mouth-watering picture of your dish for
                              the customers to see when they search for it.
                              Please refer to the instructions on how to take
                              good food pictures in the FAQ section.{" "}
                            </p>
                          </div>
                        </span>
                        <div className="avatar-edit">
                          <input
                            type="file"
                            name="dishImage"
                            id="imageUpload"
                            onChange={(e) => profil(e)}
                            accept="image/png, image/jpeg,image/jpg,,image/heic"
                          />
                          <label for="imageUpload" className="img-upload-icon">
                            <i className="ri-pencil-line"></i>
                          </label>
                        </div>
                        <Avatar
                          className="avatar-preview"
                          src={
                            image != "" ? image : ImageURL + values.dishImage
                          }
                        />
                      </div>
                      <span
                        className="text-danger"
                        style={{ textAlign: "center" }}
                      >
                        <ErrorMessage name="dishImage" />
                      </span>
                    </div>
                  </div>
                  <div className="combine-group d-flex ">
                    <div className="mb-3">
                      <label className="form-label">Dish Name *</label>
                      <Field
                        type="text"
                        name="name"
                        placeholder="Dish Name"
                        required
                        value={convertStringToPascalCase(formik.values.name.replace(/ +/g, " "))}
                        className={`form-control ${
                          submitCount > 0
                            ? errors?.name && touched?.name
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                      />
                      <span className="text-danger">
                        <ErrorMessage name="name" />
                      </span>
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Cuisine *</label>
                      <Field
                        as="select"
                        name="cuisine"
                        required
                        className={`form-control ${
                          submitCount > 0
                            ? errors?.cuisine && touched?.cuisine
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        value={values.cuisine}
                        style={{ color: "black" }}
                      >
                        <option value="">Select</option>
                        {cuisineType !== undefined &&
                          cuisineType?.map((cuisine) => {
                            return (
                              <option value={cuisine?._id}>
                                {cuisine?.name}
                              </option>
                            );
                          })}
                      </Field>
                      <span className="text-danger">
                        <ErrorMessage name="cuisine" />
                      </span>
                    </div>
                  </div>
                  <div className="combine-group d-flex ">
                    <div className="mb-3">
                      <label className="form-label">Price *</label>
                      <Field
                        type="text"
                        name="price"
                        placeholder="$0.00"
                        value={`$${values?.price.replace(/ +/g, " ")}`}
                        required
                        // value={formik.values.price.replace(/ +/g, " ")}
                        className={`form-control ${
                          submitCount > 0
                            ? errors?.price && touched?.price
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        onChange={(e) => {
                          let t = e.target.value;

                          t = t.slice(1);
                          let cond =
                            (t.charAt(t.length - 1) === "." &&
                              t.charAt(t.length - 2) === ".") ||
                            t.split(".").length > 2;
                          let zipReg = /[0-9.]+$/;
                          if ((zipReg.test(t) || t === "") && !cond) {
                            setFieldValue(
                              "price",
                              t.indexOf(".") >= 0
                                ? t.substr(0, t.indexOf(".")) +
                                    t.substr(t.indexOf("."), 3)
                                : t
                            );
                          }
                        }}
                      />
                      <span className="text-danger">
                        <ErrorMessage name="price" />
                      </span>
                      <small className="Price-suggestion">
                        <b>
                          You earn <i>${priceChange()}</i> from each dish sold!
                        </b>
                        Wanna Eat collects $
                        {(formik.values.price * chefCommission) / 100
                          ? (
                              (formik.values.price * chefCommission) /
                              100
                            ).toFixed(2)
                          : 0}{" "}
                        ({chefCommission}%) to cover marketing, operations,
                        customer support and software development costs.
                      </small>
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Food Type * </label>
                      <Field
                        as="select"
                        name="foodType"
                        required
                        className={`form-control ${
                          submitCount > 0
                            ? errors?.foodType && touched?.foodType
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        value={values.foodType}
                        style={{ color: "black" }}
                      >
                        <option value="">Select</option>
                        {foodType !== undefined &&
                          foodType?.map((food) => {
                            return (
                              <option value={food?._id}>{food?.name}</option>
                            );
                          })}
                      </Field>
                      <span className="text-danger">
                        <ErrorMessage name="foodType" />
                      </span>
                    </div>
                  </div>
                  <div className="combine-group d-flex ">
                    <div className="mb-3">
                      <label className="form-label">
                        Portion Size *
                        <span className="tool-tip-box con-tooltip top">
                          <i className="ri-information-line"></i>
                          <div className="tooltip ">
                            <p>
                              Select the size of your dish/container for one
                              serving, so that the customers know what to
                              expect. Additionally, you can elaborate the count
                              of items in your dish or number of people the dish
                              serves, etc using the ‘Dish Description’ field.
                            </p>
                          </div>
                        </span>
                      </label>
                      <Field
                        as="select"
                        name="portionSize"
                        required
                        className={`form-control ${
                          submitCount > 0
                            ? errors?.portionSize && touched?.portionSize
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        value={values.portionSize}
                        style={{ color: "black" }}
                      >
                        <option value="">Select</option>
                        <option value="4oz">4 oz</option>
                        <option value="8oz">8 oz</option>
                        <option value="12oz">12 oz</option>
                        <option value="16oz">16 oz</option>
                        <option value="24oz">24 oz</option>
                        <option value="32oz">32 oz</option>
                        <option value="Catering Half Tray">
                          Catering Half Tray
                        </option>
                        <option value="Catering Full Tray">
                          Catering Full Tray
                        </option>
                        <option value="Count">
                          Count (see description for Count)
                        </option>
                      </Field>
                      <span className="text-danger">
                        <ErrorMessage name="portionSize" />
                      </span>
                    </div>
                    <div className="mb-3">
                      <label className="form-label">
                        Preparation Time (in mins) *{" "}
                        <span className="tool-tip-box con-tooltip top">
                          <i className="ri-information-line"></i>
                          <div className="tooltip ">
                            <p>
                              How much time will it take for you to prepare this
                              dish?
                            </p>
                          </div>
                        </span>
                      </label>
                      <Field
                        type="text"
                        name="preprationTime"
                        placeholder="10"
                        required
                        value={formik.values.preprationTime.replace(/ +/g, " ")}
                        className={`form-control ${
                          submitCount > 0
                            ? errors?.preprationTime && touched?.preprationTime
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        onChange={(e) => handleTime(e)}
                        // onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                      />
                      <span className="text-danger">
                        <ErrorMessage name="preprationTime" />
                      </span>
                    </div>
                  </div>
                  <div className="mb-3 textarea-box">
                    <label className="form-label">
                      Dish Description *{" "}
                      <span className="tool-tip-box con-tooltip top">
                        <i className="ri-information-line"></i>
                        <div className="tooltip ">
                          <p>
                            This is your opportunity to describe your dish and
                            tell us what makes it special. So, make it count!
                          </p>
                        </div>
                      </span>
                    </label>
                    <Field
                      component="textarea"
                      rows="4"
                      name="description"
                      value={formik.values.description}
                      required
                      className={`form-control ${
                        submitCount > 0
                          ? errors?.description && touched?.description
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                      }`}
                      placeholder=" Please describe this dish. What flavors or spices does it include? What makes it special? ex. A traditional pasta of Genoa; this classic Italian dish consists of penne topped with a sauce made up of olive oil, fresh basil, and buttery Pecorino cheese."
                    />
                    <span className="text-danger">
                      <ErrorMessage name="description" />
                    </span>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Ingredients *</label>
                    <small className="inner-text-content">
                      Please arrange the ingredients in descending order i.e.
                      the most used ingredient should be at the top of the list
                      and least used ingredient at the bottom
                    </small>
                  </div>

                  {formValues.map((element, index) => (
                    <div
                      className={
                        start
                          ? "ingredients-box mb-3 active"
                          : "ingredients-box mb-3"
                      }
                    >
                      <div
                        key={index}
                        className="d-flex ingredients-detail "
                        onDragStart={(e) => {
                          setStart(true);
                          dragStart(e, index);
                        }}
                        onDragEnter={(e) => {
                          setStart(false);
                          dragEnter(e, index);
                        }}
                        onDragEnd={drag}
                        draggable
                      >
                        <input
                          type="text"
                          name="ingredients"
                          value={element.replace(/ +/g, " ") || ""}
                          placeholder="Name"
                          onKeyDown={handleEnter}
                          onChange={(e) => handleChange(index, e)}
                          className={`form-control ${
                            submitCount > 0
                              ? errors?.ingredients && touched?.ingredients
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}
                          ref={inputRef}
                        />

                        <div className="action-btn d-flex">
                          <a>{grip}</a>
                          {index >= 0 ? (
                            <a>
                              {" "}
                              <i
                                className="ri-subtract-line"
                                onClick={() => removeFormFields(index)}
                              ></i>
                            </a>
                          ) : null}
                        </div>
                      </div>
                      <span className="text-danger">
                        <ErrorMessage name={`ingredients[${index}]`} />
                      </span>
                    </div>
                  ))}
                  {formValues.length === 0 && (
                    <span className="text-danger">
                      <ErrorMessage name="ingredients" />
                    </span>
                  )}
                  <a
                    className="btn purple-btn trans-btn min-height mb-3 s-btn"
                    onClick={() => addFormFields()}
                    // onKeyDown={handleEnter}
                  >
                    {" "}
                    Add ingredient
                  </a>
                  <div
                    className="mb-3 textarea-box"
                    name="restrictedDiets"
                    onChange={(e) => {
                      setFieldValue("restrictedDiets", e.target.value);
                    }}
                  >
                    <label className="form-label">Dietary Tags </label>
                    <ul key=" ul" className="dietary-list">
                      {tagsData.map((data, index) => {
                        return (
                          <li>
                            <label className="coustom-checkbox">
                              <input
                                checked={data.status}
                                value={data.status}
                                type="checkbox"
                                onChange={(e) => handleChangeTags(index, e)}
                              />
                              <span className="checkmark"></span>
                              <p>{data.title}</p>
                            </label>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className="mb-3 text-center mt-5 d-flex align-items-center justify-content-center">
                  <a
                    type="button"
                    className="btn min-height black-btn  hvr-sweep-to-right me-3"
                    onClick={() => handleBack()}
                  >
                    Back
                  </a>
                  <button
                    type="submit"
                    className="btn purple-btn min-height hvr-sweep-to-right"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </section>
          {/* </div> */}
          {/* <ToastContainer /> */}
          {/* </div> */}
        </Form>
      </FormikProvider>
      <Footer />
      <Dialog open={open}>
        <div className="common-modal space-2">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-head text-center mb-4">
                <h4 className="modal-title">
                  YAY!!! You have completed the third step
                </h4>
                <p>Please click "next" to proceed further.</p>
              </div>
              <div className="modal-body text-center">
                <div className="mt-3">
                  <NavLink
                    className="btn purple-btn min-height hvr-sweep-to-right"
                    to={userToken !== null ? "/dashboard/upload" : "/upload"}
                  >
                    Next
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default UploadDish;
