import React, { useState, useEffect } from 'react'
import { AllDishReviews } from '../../component/config/config';
import { NavLink, useNavigate } from "react-router-dom";
import { ImageURL } from "../../component/config/config";
import ReactStars from "react-rating-stars-component";
import axiosInstance from "../config/axios";

const DishReviews = () => {
   const [dishReviews, setDishReviews] = React.useState();
   let navigate = useNavigate();
   const fetchAllDishReviews = async () => {
      const response = await axiosInstance().post(AllDishReviews, {})
      setDishReviews(response?.data)
   }
   useEffect(() => {
      fetchAllDishReviews();
   }, []);
   const viewDetailReview = (e, dishId) => {
      e.preventDefault();
      navigate(`/dashboard/reviewdetails/${dishId}`)
   }
   return (
      <>
         <div>
            <div class="dash-R">
               <div class="dash-head">
                  <h3>my reviews</h3>
               </div>
               <div class="dash-bottom mt-2 my-orders">
                  <div class="my-orders-head d-flex align-items-center justify-content-between">
                     <ul class="nav nav-tabs my-orders-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                           <NavLink
                              to="/dashboard/myreviews"
                              className="nav-link"
                           >
                              Chef Reviews
                           </NavLink>
                        </li>
                        <li class="nav-item" role="presentation">
                           <button class="nav-link active" id="tab-2" data-bs-toggle="tab" data-bs-target="#open-order" type="button" role="tab" aria-controls="open-order" aria-selected="false">Dish Reviews</button>
                        </li>
                     </ul>
                  </div>
                  <h3 class="my-orders-title">my dishes </h3>
                  {dishReviews?.data !== undefined &&
                     dishReviews?.data.map((data, index) => {
                        return (
                           <div class="row dish-review" key={index}>
                              <div class="col-md-4 ">
                                 <a
                                    className="nav-link"
                                    onClick={(e) => viewDetailReview(e, data?._id)}
                                    style={{ cursor: 'pointer' }}
                                 >
                                    <div class="dish-review-box d-flex justify-content-between align-items-center">
                                       <div class="dish-review-box-L d-flex align-items-center">
                                          <figure><img style={{ height: "100%" }} src={ImageURL + data?.dishImage} /></figure>
                                          <h4>{data?.name}</h4>
                                       </div>
                                       <div class="dish-review-box-R">
                                          <ul class="rating-star">
                                             <li><ReactStars size={24} count={5} activeColor="#8d126e" value={data?.avg} isHalf={true} edit={false} /></li>
                                          </ul>
                                       </div>
                                    </div>
                                 </a>
                              </div>
                           </div>
                        )
                     })}
               </div>
            </div>
         </div>
      </>
   )
}

export default DishReviews