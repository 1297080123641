import React, { useEffect, useRef, useState } from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import ChefStep from "../component/ChefStep";
import axios from "axios";
import { WannaEatBase, SignupURL } from "../component/config/config";
import { ChefURL } from "../component/config/config";
import validator from "validator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Navigate, NavLink, useLocation } from "react-router-dom";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Dialog } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SignUpAction } from "../Actions/ActionCreators";
import { string } from "yup";
import { useSelector } from "react-redux";
import { tostS, tostE } from "../toast";
import axiosInstance from "../component/config/axios";
import { Helmet } from "react-helmet";

const SignUp = () => {
  const dispatch = useDispatch();
  const LoginData = useSelector((state) =>
    state.login.data && state.login.data.chefDetails ? state.login.data : {}
  );
  const SignupData = useSelector((state) =>
    state.Signup.data ? state.Signup.data : {}
  );
  const x1 =
    LoginData.chefDetails && LoginData.chefDetails._id
      ? LoginData.chefDetails._id
      : SignupData.chefDetails && SignupData.chefDetails._id
      ? SignupData.chefDetails._id
      : 0;

  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    // checkbox: false,
  });

  //Regex validation for name field
  const nameReg = /[aA-zZ]+/;
  const mobReg = /^(1\s?)?(\d{3}|\(\d{3}\))[\s\-]?\d{3}[\s\-]?\d{4}$/;
  //For change a input field
  const handleSubmit = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if ((name === "firstName" || name === "lastName") && value.length > 0) {
      if (nameReg.test(value)) {
        setUser({ ...user, [name]: value });
        setError({ ...error, [name]: false });
      }
    } else {
      setUser({ ...user, [name]: value });
      setError({ ...error, [name]: false });
      if (name === "phoneNumber") {
        if (user.phoneNumber != "" || value.length >= 1) {
          if (
            string()
              .matches(mobReg)
              .required("number is required")
              .isValidSync(value.slice(0, 14))
          ) {
            setErrorMobile("");
          } else {
            setErrorMobile("Mobile Number is not valid");
          }
          //validateMobile(user.phoneNumber);
        }
        setUser({ ...user, [name]: formatPhoneNumber(e.target.value) });
      }

      if (name === "email") {
        if (user.email != "" || value.length >= 1) {
          validateEmail(user.email);
        }
      }
      if (name === "password") {
        if (user.password != "" || user.password.length < 8) {
          validate(value);
        }
      }

      if (name === "confirmPassword") {
        if (user.password !== "") {
          cnfrmPassValidate(value);
        }
        // user.password === value
        // 	? setPassmatch(true)
        // 	: setPassmatch(false);
      }
    }
  };

  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  };
  // for check password and confirm password same
  const [passmatch, setPassmatch] = useState(true);
  const validatePassword = () => {
    user.password === user.confirmPassword
      ? setPassmatch(true)
      : setPassmatch(false);
    return user.password === user.confirmPassword;
  };

  //for check validation
  const [errorMobile, setErrorMobile] = useState("");
  const [error, setError] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phoneNumber: false,
    password: false,
    confirmPassword: false,
    // checkbox: false,
  });
  const refEmail = useRef();
  // For Check email is valid or not
  const [errorEmail, setErrorEmail] = useState("");
  const validateEmail = (value) => {
    let y = value;
    let regEma =
      /^[a-z0-9][a-z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/;
    // if (y) {
    console.log(refEmail.current.value);
    console.log(regEma.test(refEmail.current.value));
    if (regEma.test(refEmail.current.value)) {
      setErrorEmail("");
      return true;
    } else {
      setErrorEmail("Email Address is not valid");
      return false;
    }
    // }
  };
  // .replace("(", "").replace(")", "").replace(" ", "").replace("-", "")
  // For submitting a Form
  const submit = (e) => {
    e.preventDefault();
    console.log(user);
    //Validate on a  field
    let isValid = true;

    let er = {
      firstName: false,
      lastName: false,
      email: false,
      phoneNumber: false,
      password: false,
      confirmPassword: false,
      // checkbox: false,
    };
    if (user.firstName == "") {
      isValid = false;
      er.firstName = true;
    }
    if (user.lastName == "") {
      isValid = false;
      er.lastName = true;
    }
    if (user.email == "") {
      isValid = false;
      setErrorEmail("Email is required");
    }
    if (user.phoneNumber == "") {
      isValid = false;
      setErrorMobile("Mobile number is required");
    } else {
      if (
        string()
          .matches(mobReg)
          .required("number is required")
          .isValidSync(user.phoneNumber)
      ) {
        setErrorMobile("");
      } else {
        isValid = false;
        setErrorMobile("Mobile Number is not valid");
      }
      // console.log("phone", user.phoneNumber);
    }
    if (isDisabled === false) {
      if (user.password == "") {
        isValid = false;
        setErrorMessage("Password is required");
      }
      if (user.confirmPassword == "") {
        isValid = false;
        setConfrmPassword("Confirm password is required");
        // er.confirmPassword = true;
      }
    }

    setError({ ...er });
    document.body.classList.add("loading-indicator");
    if (isValid === true && validatePassword() && validateEmail(user.email)) {
      axios
        .post(SignupURL, {
          id: user?.id,
          firstName: user?.firstName,
          lastName: user?.lastName,
          email: user?.email,
          phoneNumber: user?.phoneNumber
            .replace("(", "")
            .replace(")", "")
            .replace(" ", "")
            .replace("-", ""),
          password: user?.password,
          confirmPassword: user?.confirmPassword,
        })
        .then((response) => {
          // console.log(response);
          // console.log("message" + "" + response.data.message);
          if (response.status === 200) {
            document.body.classList.remove("loading-indicator");
            setUser({
              firstName: "",
              lastName: "",
              email: "",
              phoneNumber: "",
              password: "",
              confirmPassword: "",
              id: x1,
            });
            dispatch(SignUpAction(response.data.data));
            //For save a id in local to use in a chef id

            localStorage.setItem("id", response.data.data._id);

            //For save a full name in local to use a chef name
            localStorage.setItem("fullName", response.data.data.fullName);
            setError({
              firstName: false,
              lastName: false,
              email: false,
              phoneNumber: false,
              password: false,
              confirmPassword: false,
              // checkbox: false,
            });
            handleClickOpen();
          }
        })
        .catch((error) => {
          // console.log("message" + "" + error.response.data.message);
          document.body.classList.remove("loading-indicator");

          tostE(error?.response?.data?.message);
          // tostE(error?.response?.data?.message?.email);
          // tostE(error?.response?.data?.message?.phoneNumber);
          // tostE(error?.response?.data?.message?.password);
        });
      console.log("ksjdf");
    } else {
      document.body.classList.remove("loading-indicator");
    }
  };

  //Eye icon for see password and confirm password
  const eye = <FontAwesomeIcon icon={faEye} />;
  const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;

  //For Show Password
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  //For Show Confirm Password
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordShown(confirmPasswordShown ? false : true);
  };

  //For Dialog box on click next button

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setUser({
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",
      checkbox: false,
    });
  };

  // Check Password is strong or not

  const [errorMessage, setErrorMessage] = useState("");
  const validate = (value) => {
    if (
      validator.isStrongPassword(value, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumber: 1,
        minSymbols: 1,
      })
    ) {
      //setErrorMessage("isStrongPassword");
      setErrorMessage("");
    } else {
      setErrorMessage(
        <p>
          Password must contain at least 8 characters including minimum 1
          lowercase letter, 1 uppercase letter, 1 number and 1 special character{" "}
        </p>
      );
    }
  };

  // check confirm password is valid
  const [confrmPasswrd, setConfrmPassword] = useState("");
  const cnfrmPassValidate = (value) => {
    if (value === user.password) {
      setPassmatch(true);
      setConfrmPassword("");
    } else {
      setPassmatch(false);
      setConfrmPassword("Password and Confirm Password need to match");
    }
  };

  let navigate = useNavigate();
  let { pathname } = useLocation();
  // console.log(pathname)
  const handleView = (data) => {
    if (pathname === "/dashboard/signup") {
      navigate(`/dashboard/chefdetails`, { state: data });
    } else {
      navigate(`/chefdetails`, { state: data });
    }
  };
  // setIsDisabled(!isDisabled)
  const [isDisabled, setIsDisabled] = useState(false);
  // console.log(isDisabled);

  //Fetch data on click user back button

  // const x = localStorage.getItem("id");
  // console.log(x);
  const fetchData = async () => {
    // const body = {
    // 	_id: x1,
    // };
    const response = await axiosInstance().get(ChefURL);
    // console.log("response", response);
    const a = response?.data?.data;
    setUser({
      firstName: a.firstName,
      lastName: a.lastName,
      email: a.email,
      phoneNumber: `(${a?.phoneNumber?.slice(0, 3)}) ${a?.phoneNumber?.slice(
        3,
        6
      )}-${a?.phoneNumber?.slice(6)}`,
      password: "********",
      confirmPassword: "********",
      checkbox: false,
      id: x1,
    });
    // console.log("first");
    setIsDisabled(true);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {/* <Header /> */}
      {/* {console.log(user.email)} */}
      <ChefStep />
      <div className="mar-t mb-5 main-form ">
        <Helmet>
          <title> Wanna Eat </title>
          <meta
            name="keywords"
            content=" chefs, chef, home cooks, home cook jobs, home cook jobs near me, chef jobs near me, chef jobs, chef jobs new jersey, chef jobs hiring near me, chef jobs remote, chef jobs part time, chef jobs hiring"
          />
          <meta
            name="description"
            content=" Are your cooking skills exceptional? Want to use your free time to cook some delicious dishes. Join WannaEat and showcase your signature dishes, cherished family recipes, and inventive culinary masterpieces, captivating taste buds and earning the acclaim you deserve. WannaEat is committed to supporting your success."
          />
        </Helmet>
        <div className="container">
          <div className="form-box row">
            <div className="form-head text-center">
              <h2>Sign-up and share your love for cooking!</h2>
              <p>
                Create an account and start serving your amazing culinary
                creations to your local community
              </p>
            </div>
            <form className="col-md-9 m-auto" autoComplete="off">
              <div className="combine-group d-flex ">
                <div className="mb-3">
                  <label className="form-label">Chef First Name *</label>
                  <input
                    type="text"
                    name="firstName"
                    placeholder="Chef First Name"
                    className="form-control"
                    value={user.firstName.replace(/ +/g, " ")}
                    minLength={1}
                    maxLength={20}
                    onChange={handleSubmit}
                  />
                  <span
                    className="errorMessage"
                    style={{
                      display: error.firstName ? "block" : "none",
                    }}
                  >
                    First Name is required{" "}
                  </span>
                </div>

                <div className="mb-3">
                  <label className="form-label">Chef Last Name *</label>
                  <input
                    type="text"
                    name="lastName"
                    maxLength={20}
                    placeholder="Chef Last Name"
                    className="form-control"
                    value={user.lastName.replace(/ +/g, " ")}
                    onChange={handleSubmit}
                  />
                  <span
                    className="errorMessage"
                    style={{
                      display: error.lastName ? "block" : "none",
                    }}
                  >
                    Last Name is required
                  </span>
                </div>
              </div>
              <div className="combine-group d-flex ">
                <div className="mb-3">
                  <label className="form-label">Email Address *</label>
                  <input
                    type="email"
                    name="email"
                    placeholder="Email Address"
                    className="form-control"
                    ref={refEmail}
                    value={user.email}
                    onChange={handleSubmit}
                    // onBlur={(e) =>
                    // 	validateEmail(e.target.value)
                    // }
                    disabled={isDisabled}
                  />
                  <span
                    className="errorMessage"
                    style={{
                      display: error.email ? "block" : "none",
                    }}
                  >
                    Email is required
                  </span>
                  {errorEmail === "" ? null : (
                    <span className="errorMessage">{errorEmail}</span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">Mobile Number *</label>
                  <input
                    type="text"
                    name="phoneNumber"
                    placeholder="Mobile Number"
                    className="form-control"
                    value={user.phoneNumber}
                    onChange={handleSubmit}
                    disabled={isDisabled}
                    // onBlur={(e) =>
                    // 	validateMobile(e.target.value)
                    // }
                  />
                  <span
                    className="errorMessage"
                    style={{
                      display: error.phoneNumber ? "block" : "none",
                    }}
                  >
                    Mobile Number is required
                  </span>
                  {errorMobile === "" ? null : (
                    <span className="errorMessage">{errorMobile}</span>
                  )}
                </div>
              </div>
              <div className="combine-group d-flex ">
                <div className="mb-3 password-box">
                  <label className="form-label">Password *</label>
                  <div className="icon-with-input">
                    {" "}
                    <input
                      type={passwordShown ? "text" : "password"}
                      name="password"
                      placeholder="Password"
                      className="form-control"
                      value={user.password}
                      onChange={handleSubmit}
                      aria-required="true"
                      // onBlur={(e) =>
                      // 	validate(e.target.value)
                      // }
                      disabled={isDisabled}
                      autoComplete="off"
                    />
                    <div className="eyeBtn">
                      {passwordShown != "" ? (
                        <i
                          onClick={togglePasswordVisiblity}
                          disabled={isDisabled}
                        >
                          {eye}
                        </i>
                      ) : (
                        <i
                          onClick={togglePasswordVisiblity}
                          disabled={isDisabled}
                        >
                          {eyeSlash}
                        </i>
                      )}
                    </div>
                  </div>
                  <span
                    className="errorMessage"
                    style={{
                      display: error.password ? "block" : "none",
                    }}
                  >
                    Password is required
                  </span>
                  {errorMessage === "" || errorMessage.length < 8 ? null : (
                    <span className="errorMessage">{errorMessage}</span>
                  )}
                </div>

                <div className="mb-3 password-box">
                  <label className="form-label">Confirm Password *</label>
                  <div className="icon-with-input">
                    <input
                      type={confirmPasswordShown ? "text" : "password"}
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      className="form-control"
                      value={user.confirmPassword}
                      onChange={handleSubmit}
                      aria-required="true"
                      aria-invalid={passmatch ? true : false}
                      disabled={isDisabled}
                    />
                    <div className="eyeBtn">
                      {confirmPasswordShown != "" ? (
                        <i
                          onClick={toggleConfirmPasswordVisibility}
                          disabled={isDisabled}
                        >
                          {eye}
                        </i>
                      ) : (
                        <i
                          onClick={toggleConfirmPasswordVisibility}
                          disabled={isDisabled}
                        >
                          {eyeSlash}
                        </i>
                      )}
                    </div>
                  </div>
                  <span
                    className="errorMessage"
                    style={{
                      display: error.confirmPassword ? "block" : "none",
                    }}
                  >
                    Confirm Password is required
                  </span>
                  <div className="errorMessage">
                    {user.password !== user.confirmPassword ||
                    user.confirmPassword === ""
                      ? confrmPasswrd
                      : ""}
                  </div>
                  {/* <div className="errorMessage">
										{passmatch
											? ""
											: "Password and Confirm Password should need to match"}
									</div> */}
                </div>
              </div>
              <p>
                By selecting the Next button and signing up, you agree to our{" "}
                <a
                  style={{ cursor: "pointer", color: "#2196f3 " }}
                  target="_blank"
                  href={`${WannaEatBase}/privacy-policy`}
                >
                  {" "}
                  Privacy Policy.{" "}
                </a>
              </p>
              <div className="mb-3 text-center mt-5">
                <button
                  type="button"
                  className="btn purple-btn min-height hvr-sweep-to-right"
                  data-bs-toggle="modal"
                  data-bs-target="#next-step"
                  onClick={submit}
                >
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
        {/* </div>
				</div> */}
        {/* <ToastContainer /> */}
      </div>

      <Footer />
      <Dialog open={open}>
        <div className="common-modal space-2">
          <div className="modal-dialog ">
            <div className="modal-content">
              <div className="modal-head text-center mb-4">
                <h4 className="modal-title">
                  YAY!!! You have completed the first step
                </h4>
                <p>Please click "next" to proceed further.</p>
              </div>
              <div className="modal-body text-center">
                <div className="mt-3">
                  <a
                    className="btn purple-btn min-height hvr-sweep-to-right"
                    onClick={() => handleView()}
                  >
                    Next
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default SignUp;
