import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { TestimonialURL, ImageURL } from "./config/config";
import axios from "axios";

const Testimonial = () => {
  const [testi, setTesti] = useState();
  const [loading, setLoading] = useState(true);
  const testiFetch = async () => {
    setLoading(true);
    const response = await axios.post(TestimonialURL);
    // console.log(response)
    setTesti(response.data.data);
    setLoading(false);
  };
  useEffect(() => {
    testiFetch();
  }, []);
  const options = {
    // margin: 30,
    responsiveClass: true,
    nav: true,
    // dots: false,
    autoplay: false,
    // navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    },
  };
  return (
    <>
      {loading ? (
        "loading"
      ) : (
        <section className="our-clients comm-space space-similer">
          <div className="container">
            <div className="similer-title-head text-center mb-4">
              <span className="sub-title">Testimonials</span>
              <h2>what clients say</h2>
            </div>
            <div className="slider-box pt-5">
              <OwlCarousel
                className="owl-theme"
                // items={3}
                // loop
                // nav
                margin={8}
                {...options}
              >
                <div className="item">
                  <div className="item-content">
                    <p>{testi?.userTestimonials[0]?.comment}</p>
                    <br />
                    <h4> {testi?.userTestimonials[0]?.name}</h4>
                  </div>
                </div>
                <div className="item">
                  <div className="item-content">
                    <p>{testi?.userTestimonials[1]?.comment} </p>
                    <br />
                    <h4> {testi?.userTestimonials[1]?.name}</h4>
                  </div>
                </div>
                <div className="item">
                  <div className="item-content">
                    <p>{testi?.userTestimonials[2]?.comment}</p>
                    <br />
                    <h4>{testi?.userTestimonials[2]?.name}</h4>
                  </div>
                </div>
                <div className="item">
                  <div className="item-content">
                    <p>{testi?.userTestimonials[3]?.comment}</p>
                    <br />
                    <h4>{testi?.userTestimonials[3]?.name}</h4>
                  </div>
                </div>
                <div className="item">
                  <div className="item-content">
                    <p>{testi?.userTestimonials[4]?.comment}</p>
                    <br />
                    <h4>{testi?.userTestimonials[4]?.name}</h4>
                  </div>
                </div>
                <div className="item">
                  <div className="item-content">
                    <p>{testi?.userTestimonials[5]?.comment}</p>
                    <br />
                    <h4>{testi?.userTestimonials[5]?.name}</h4>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Testimonial;
