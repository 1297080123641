import React, { useState } from 'react'
import validator from "validator";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ContactURL } from "../component/config/config";
import axios from 'axios';
import { FormikProvider, Form, Field, ErrorMessage, useFormik } from "formik"
import * as Yup from "yup";
import { tostS, tostE } from "../toast"


const ContactForm = () => {
    const [isDisabled, setIsDisabled] = useState(false);

    const EMAIL = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/

    const myValidationSchema = Yup.object().shape({
        name: Yup.string()
            // .strict(true).trim("Extra spaces should be truncated")
            .min(5, "Name must be at least 5 characters ")
            .max(55, "Name must not be more than 55 characters ")
            .required("Name is  required"),
        phoneNumber: Yup.string()
            // .matches(PHONE, "Mobile number should be valid")
            .min(14, "Mobile Number is not valid")
            .max(14, "Mobile Number is not valid")
            // .matches(PHONE, "Mobile number should be  digits ")
            .required("Mobile Number is required"),
        email: Yup
            .string()
            .matches(EMAIL, "Email must be valid ")
            .email("Email must be valid").required("Email is required"),
        subject: Yup.string()
            // .strict(true).trim("Extra spaces should be truncated")
            .min(5, " Subject must be at least 5 characters ")
            .max(750, " Subject cannot be more than 750 characters ")
            .required("Subject is required "),
        description: Yup.string()
            // .strict(true).trim("Extra spaces should be truncated")
            .min(5, " Description must be at least 5 characters ")
            .max(750, "Description cannot be more than 750 characters ")
            .required("Description is required "),


    });
    //For initial value
    const formik = useFormik({
        initialValues: {
            name: "",
            phoneNumber: "",
            email: "",
            subject: "",
            description: "",

        },
        validationSchema: myValidationSchema,
        onSubmit: async (values, resetForm) => {
            console.log(values);

            try {
                const response = await axios.post(ContactURL, values);
                console.log(response);
                if (response.status === 200) {
                    const message = response.data.message;
                    tostS(message);
                    formik.resetForm()
                }
            } catch (error) {
                setIsDisabled(false);
                console.log("message" + "" + error.response.data.message);
                tostE(error.response.data.message);
            }
        },
    });

    let { submitCount, errors, touched, setFieldValue, handleSubmit, values, setValues } =
        formik;
    const formatPhoneNumber = (values) => {
        if (!values) return values;
        const phoneNumber = values.target.value.replace(/[^\d]/g, "");
        const phoneNumberLength = phoneNumber.length;
        if (phoneNumberLength <= 3) return phoneNumber;
        if (phoneNumberLength >= 4 && phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`

    }
    return (
        <>
            <FormikProvider value={formik}>
                <Form className="pt-5" autoComplete="off" noValidate onSubmit={handleSubmit}>
                    {/* <form className="pt-5" onSubmit={submit}> */}
                    <div className="combine-group d-flex">
                        <div className="mb-3">
                            <Field type="text" name="name" placeholder="Name" value={values.name.replace(/ +/g, " ")} className={`form-control ${submitCount > 0
                                ? errors?.name &&
                                    touched?.name
                                    ? "is-invalid"
                                    : "is-valid"
                                : ""
                                }`} />

                            <span className="text-danger">
                                <ErrorMessage name="name" />
                            </span>
                        </div>
                        <div className="mb-3">
                            <Field
                                name="phoneNumber"
                                value={values.phoneNumber}
                                onChange={(e) => {
                                    const fp = formatPhoneNumber(e);
                                    setFieldValue('phoneNumber', fp);
                                }}
                                placeholder="Mobile"
                                className={`form-control ${submitCount > 0 ? (errors?.phoneNumber && touched?.phoneNumber ? "is-invalid" : "is-valid") : ""}`} />
                            <span className="text-danger">
                                <ErrorMessage name="phoneNumber" />
                            </span>
                        </div>
                    </div>
                    <div className="combine-group d-flex">
                        <div className="mb-3">
                            <Field name="email" placeholder="Email" type="text" value={formik.values.email.replace(" ", "")} className={`form-control ${submitCount > 0 ? (errors?.email && touched?.email ? "is-invalid" : "is-valid") : ""}`}></Field>
                            <span className="text-danger">
                                <ErrorMessage name="email" />
                            </span>

                        </div>
                        <div className="mb-3">
                            <Field type="text" name="subject" value={values.subject.replace(/ +/g, " ")} placeholder="Subject" className={`form-control ${submitCount > 0 ? (errors?.subject && touched?.subject ? "is-invalid" : "is-valid") : ""}`} />
                            <span className="text-danger">
                                <ErrorMessage name="subject" />
                            </span>
                        </div>
                    </div>
                    <div className="mb-3 textarea-box">
                        <Field type="text" component="textarea" rows="4" name="description" value={values.description.replace(/ +/g, " ")} placeholder="Comment" className={`form-control ${submitCount > 0
                            ? errors?.description &&
                                touched?.description
                                ? "is-invalid"
                                : "is-valid"
                            : ""
                            }`}></Field>
                        <span className="text-danger">
                            <ErrorMessage name="description" />
                        </span>
                    </div>
                    <div className="text-center mt-4 pt-2"><button type="submit" className="btn btn-submit pink-btn hvr-sweep-to-right" >submit</button></div>
                    {/* </form> */}
                </Form>
            </FormikProvider >
        </>
    )
}

export default ContactForm
