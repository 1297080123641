import React from 'react'

const Pagination = ({ postsPerPage, currentPage, totalPosts, paginate, onNext, onPrevious }) => {
    const pageNumbers = [];
    window.scrollTo(0,0)
    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i)

    }
    return (
        <>
            <div className="pagination-box d-flex justify-content-center mt-5 mb-5">
                {totalPosts > postsPerPage &&
                    <div class="pagination-main" style={{ overflow: "auto", width: "45%", margin: "auto", paddingBottom: "8px", textAlign: "center" }}>
                        <ul className="pagination" style={{ display: "inline-flex " }}>
                            <li className="page-item " onClick={() => { if (pageNumbers.indexOf(currentPage) !== 0) { onPrevious() } }}>
                                <a className="page-link " href="#" aria-label="Previous">
                                    <span aria-hidden="true"><i className="ri-arrow-left-line"></i></span>
                                </a>
                            </li>
                            {pageNumbers.map((number, idx) => (
                                < li style={{ cursor: "pointer" }} className={currentPage === number ? "page-item active" : "page-item"} key={number}>
                                    <a className="page-link" onClick={() => paginate(number)}>{number}</a>
                                </li>
                            ))}
                            <li className="page-item" onClick={() => { if (pageNumbers.indexOf(currentPage) !== pageNumbers.length - 1) { onNext() } }}>
                                <a className="page-link" href="#" aria-label="Next">
                                    <span aria-hidden="true"><i className="ri-arrow-right-line"></i></span>
                                </a>
                            </li>
                        </ul>
                    </div>
                }

            </div>
        </>
    )
}

export default Pagination
