import React, { useEffect } from 'react'
import { NavLink, useParams } from "react-router-dom";
import axios from 'axios';
import { ViewDetailedReview } from '../../component/config/config';
import authHeader from '../services/auth_header';
import moment from 'moment';
import ReactStars from "react-rating-stars-component";
import axiosInstance from "../config/axios";


const ReviewDetails = () => {

    const [detailReview, setdetailReview] = React.useState();
    const { id } = useParams();

    const fetchOneDetailReview = async () => {
        const response = await axiosInstance().post(ViewDetailedReview, { dishId: id })
        setdetailReview(response?.data)
    }

    useEffect(() => {
        fetchOneDetailReview();
    }, []);


    return (
        <>
            {console.log(detailReview)}
            <div class="dash-R">
                <div class="dash-head">
                    <h3>my reviews</h3>
                    <nav class="breadcrumb-box">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><NavLink to="/dashboard/myreviews">My Reviews</NavLink></li>
                            <li class="breadcrumb-item"><NavLink to="/dashboard/dishreviews">Dish Reviews</NavLink></li>
                            <li class="breadcrumb-item active" aria-current="page">{detailReview?.data[0]?.dishData?.map((da) => (da?.dishId?.name))}</li>
                        </ol>
                    </nav>
                </div>
                <div class="dash-bottom mt-2 my-order">
                    <div class="row french-fries-reviews">
                        <div class="col-md-12">
                            <h3 class="my-orders-title">reviews for {detailReview?.data[0]?.dishData?.map((da) => (da?.dishId?.name))}</h3>
                        </div>
                        {detailReview !== undefined && detailReview.data.map((data, index) => {
                            return (
                                <div class="col-md-6 chef-reviews-box" key={index}>
                                    <small>{moment(data?.createdAt).format("MM/DD/YYYY")}</small>
                                    <h3>{data?.reviewBy?.fullName}</h3>
                                    {data?.dishData.map((daa) => {
                                        return (
                                            <>

                                                <ul class="rating-star pb-1">
                                                    <li><ReactStars size={24} count={5} activeColor="#8d126e" value={daa?.dishRating} isHalf={true} edit={false} /></li>
                                                </ul>
                                                <p>{daa?.dishReview}</p>
                                            </>
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            {/* <div class="screen-div-info">
                <ul class="info-list">
                    <li><a href="tel:9513539491"><i class="ri-phone-line"></i>951-353-9491</a></li>
                    <li><a href="mailto:info@email.com"><i class="ri-mail-line"></i>info@email.com</a></li>
                </ul>
            </div> */}
        </>
    )
}

export default ReviewDetails