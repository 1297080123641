import React from "react";
import { useRoutes, Navigate, useLocation } from "react-router-dom";
import Dashboard from "../component/dashboard/Dashboard";
import EditDish from "../component/dashboard/EditDish";
import MyMenu from "../component/dashboard/MyMenu";
import Layout from "../Layouts";
import Upload from "../pages/Upload";
import Payment from "../pages/Payment";
import Welcome from "../pages/Welcome";
import ChefDetails from "../pages/ChefDetails";
import UploadDish from "../pages/UploadDish";
import AddDish from "../component/dashboard/AddDish";
import DishDetails from "../component/dashboard/DishDetails";
import DashBoardLayout from "../Layouts/dashBoardLayout";
import AddMenuDetails from "../component/dashboard/AddMenuDetails";
import Cheffaq from "../component/helpCenter/cheffaq";
import ContactUsHelp from "../component/helpCenter/contactUs"
import NewOrder from "../component/myOrders/NewOrder";
import ViewDetails from "../component/myOrders/ViewDetails";
import SignUp from "../pages/SignUp";
import Home from '../pages/Home'
import ChefTerms from "../component/ChefTerms";
import MyEarnings from "../component/myEarnings/MyEarnings";
import MyReviews from "../component/myReviews/MyReviews";
import ReviewDetails from "../component/myReviews/ReviewDetails";
import Settings from "../component/settings/Settings";
import DishReviews from "../component/myReviews/DishReviews";
import UpdateMenu from "../component/dashboard/UpdateMenu";
import AboutUs from "../component/AboutUs";
import CateringService from "../component/CateringService";
import ContactUs from "../component/ContactUs";
// import AboutUs from "../component/AboutUs";
// import ContactUs from "../component/ContactUs";

const DashboardRoutes = () => {
	return useRoutes([
    {
      path: "cheft&c",
      element: <Layout />,
      children: [{ index: true, element: <ChefTerms /> }],
    },
    {
      path: "dashboard",
      element: <DashBoardLayout />,
      children: [
        { path: "", element: <Dashboard /> },
        // { path: "chefdetails", element: < ChefDetails /> },
        // { path: "adddish", element: < UploadDish /> },
        { path: "mymenu/:type", element: <MyMenu /> },
        { path: "editdish/:id", element: <EditDish /> },
        { path: "addish", element: <AddDish /> },
        { path: "dishdetails/:id", element: <DishDetails /> },
        { path: "addmenu/:type", element: <AddMenuDetails /> },
        { path: "cheffaq", element: <Cheffaq /> },
        { path: "chefcontactus", element: <ContactUsHelp /> },
        { path: "myorder/:type", element: <NewOrder /> },
        { path: "viewdetails/:id", element: <ViewDetails /> },
        { path: "myearnings", element: <MyEarnings /> },
        { path: "myreviews", element: <MyReviews /> },
        { path: "reviewdetails/:id", element: <ReviewDetails /> },
        { path: "dishreviews", element: <DishReviews /> },
        { path: "settings", element: <Settings /> },
        { path: "updatemenu/:type", element: <UpdateMenu /> },

        // { path: "upload", element: < Upload /> },
        // { path: "payment", element: < Payment /> },
        // { path: "welcome", element: < Welcome /> },
      ],
    },
    {
      path: "dashboard",
      element: <Layout />,
      children: [
        //addonnn//
        { path: "signup", element: <SignUp /> },
        { path: "about-us", element: <AboutUs /> },
        { path: "catering-services", element: <CateringService /> },
        { path: "contact-us", element: <ContactUs /> },
        //addonn//
        { path: "chefdetails", element: <ChefDetails /> },
        { path: "adddish", element: <UploadDish /> },
        { path: "upload", element: <Upload /> },
        { path: "payment", element: <Payment /> },
        { path: "welcome", element: <Welcome /> },
        { path: "cheft&c", element: <ChefTerms /> },
      ],
    },
    {
      path: "home",
      element: <Layout />,
      children: [{ path: "", element: <Home /> }],
    },
    // {
    // 	path: '/',
    // 	element: <Layout />,
    // 	children: [
    // 		{ path: "", element: < Home /> },
    // 		{ path: "about-us", element: < AboutUs /> },
    // 		{ path: "contact-us", element: < ContactUs /> },
    // 	]
    // },
    { path: "*", element: <Navigate to="/dashboard" replace /> },
  ]);
};

export default DashboardRoutes;
