import React, { useEffect, useState } from "react";
import { TotalURL, LoginChefURL } from "../../component/config/config";
import { tostE } from "../../toast";
import moment from "moment";
import Pagination from "../myOrders/Pagination";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import Accordion from "react-bootstrap/Accordion";
import axiosInstance from "../config/axios";

const MyEarnings = () => {
  function getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff - 1));
  }

  const LoginData = useSelector((state) =>
    state.login.data && state.login.data.chefDetails ? state.login.data : {}
  );

  const create = LoginData.chefDetails.createdAt;

  var curr = new Date();
  var first = curr.getDate() - curr.getDay() + 1;
  var firstday = getMonday(new Date());

  const [sale, setSale] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState();
  const [value, setValue] = React.useState(getMonday(new Date()));
  const [val, setVal] = React.useState(new Date());
  const [totalData, setTotalData] = useState(0);
  const commission = useSelector((state) => state.commissionReducer);
  const chefDetail = commission?.commission;
  const getData = async () => {
    // document.body.classList.add("loading-indicator");
    // try {
    //   // const response = await axiosInstance().get(LoginChefURL, {});
    setPostsPerPage(chefDetail?.pageSize);
    localStorage.setItem("pageSize", chefDetail?.pageSize);
    fetchData(1, chefDetail?.pageSize);
    // } catch (error) {
    //   document.body.classList.remove("loading-indicator");
    // }
  };
  const PostPerPage = +localStorage.getItem("pageSize");
  const options = Intl.DateTimeFormat().resolvedOptions();
  const timezone = options.timeZone;
  const fetchData = async (cp, postsPerPage) => {
    document.body.classList.add("loading-indicator");
    try {
      const response = await axiosInstance().post(TotalURL, {
        pageNumber: cp,
        pageSize: PostPerPage ? PostPerPage : postsPerPage,
        startDate: value,
        endDate: val,
        timezone: timezone,
      });
      document.body.classList.remove("loading-indicator");
      setSale(response.data.data);
      setTotalData(response.data.data.totalRecords);
      setCurrentPage(cp);
    } catch (error) {
      document.body.classList.remove("loading-indicator");
      tostE(error.response.data.message);
    }
  };

  useEffect(() => {
    getData();
  }, [val, value]);
  const paginate = (pageNumber) => {
    fetchData(pageNumber);
    setCurrentPage(pageNumber);
  };
  const onNext = () => {
    setCurrentPage(currentPage + 1);
    fetchData(currentPage + 1);
  };
  const onPrevious = () => {
    setCurrentPage(currentPage - 1);
    fetchData(currentPage - 1);
  };
  return (
    <>
      <div className="dash-R">
        <div className="dash-head">
          <h3>my earnings</h3>
        </div>
        <div className="row">
          <div className="col-md-6 mb-3">
            <div className="dashboard-top-box purple-bg">
              <div className="dashboard-top-box-L">
                <b>${sale?.totalEarnings?.toFixed(2)}</b>
                <span>Total Earnings</span>
              </div>
              <div className="dashboard-top-box-R">
                <span>
                  <i className="ri-money-dollar-box-line"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="dash-bottom mt-3">
          <div className="select-filter d-flex col-md-5 mb-4">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className="form-control"
                label="Start Date"
                value={value}
                minDate={
                  create > new Date(firstday).toISOString() ? firstday : create
                }
                showToolbar={false}
                inputProps={{ readOnly: true }}
                onChange={(newValue) => {
                  setValue(newValue.$d);
                }}
                renderInput={(params) => (
                  <>
                    {" "}
                    <TextField {...params} />{" "}
                    <Box
                      sx={{ mx: 2 }}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      to{" "}
                    </Box>
                  </>
                )}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className="form-control"
                label="End Date"
                value={val}
                showToolbar={false}
                minDate={value}
                inputProps={{ readOnly: true }}
                onChange={(newVal) => {
                  setVal(newVal.$d);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
          <div className="earnings-box">
            <Accordion className="accordion dash-accordion ">
              {sale?.chefEarningData.map((data, id) => (
                <Accordion.Item eventKey={id}>
                  <Accordion.Header>
                    <div className="my-earnings-head">
                      <h4>
                        <i className="ri-calendar-2-line"></i> Week of{" "}
                        {moment(data?._id.startDate).format("MM/DD") +
                          " " +
                          "-" +
                          " " +
                          moment(data?._id.endDate).format("MM/DD")}
                      </h4>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="doller-total-earning">
                      {" "}
                      <i class="ri-money-dollar-circle-line"></i>
                      {data?.totalWeekEarnings.toFixed(2)}
                    </div>

                    {data.orderData.map((dat, index) => (
                      <>
                        <div className="accordion-collapse ">
                          <div className="accordion-body">
                            <ul className="Delivery-content">
                              <li style={{ listStyleType: "none" }}>
                                <b>Order ID:</b>#{dat?.orderId}
                                {dat?.orderPortion
                                  ? `,(${dat?.orderPortion})`
                                  : ""}{" "}
                              </li>
                              <li style={{ listStyleType: "none" }}>
                                <b>Delivery Date:</b>
                                {moment(dat?.orderDeliveryDate).format(
                                  "Do MMM YYYY"
                                )}
                              </li>
                              <li style={{ listStyleType: "none" }}>
                                <b>My Earnings:</b>${dat?.myEarnings.toFixed(2)}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </div>
        <Pagination
          currentPage={currentPage}
          postsPerPage={PostPerPage}
          totalPosts={totalData}
          paginate={(pageNumber) => {
            paginate(pageNumber);
          }}
          onNext={onNext}
          onPrevious={onPrevious}
        />
      </div>
    </>
  );
};

export default MyEarnings;
