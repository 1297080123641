import React, { useEffect, useState } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import { ImageURL } from "./config/config";
const background = ImageURL + "assets/images/patterns-img.png";

const ChefStep = () => {
  const loginData = useSelector((state) =>
    state.login && state.login.data && state.login.data.chefDetails
      ? state.login.data.chefDetails
      : {}
  );
  const chefData = useSelector((state) =>
    state.Signup && state.Signup.data && state.Signup.data.chefDetails
      ? state.Signup.data.chefDetails
      : state.Signup.data._id
      ? state.Signup.data
      : {}
  );

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <div
        className={
          open
            ? "wraper-inner padding-head filter-shift"
            : "wraper-inner padding-head"
        }
      >
        <div className="inner-pages">
          <div className="patterns-banner inner-space">
            <figure>
              <img src={background} />
            </figure>
          </div>
          <div className={open ? "sidebar open" : "sidebar "}>
            <h3>Chef Onboarding Steps</h3>
            <a className="menu-fold" style={{ display: "none" }}>
              <i className="ri-menu-fold-line " onClick={handleClickOpen}></i>
              <i
                className="ri-menu-unfold-line"
                style={{ display: "none" }}
                onClick={() => {
                  handleClose();
                }}
              ></i>
            </a>
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <label className="coustom-checkbox">
                    <input
                      type="checkbox"
                      checked={
                        chefData.signupComplete
                          ? chefData.signupComplete
                          : loginData.signupComplete
                          ? loginData.signupComplete
                          : false
                      }
                    />
                    <span className="checkmark"></span>
                    <p>1: Sign up</p>
                  </label>
                </h2>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <label className="coustom-checkbox">
                    <input
                      type="checkbox"
                      checked={
                        chefData.chefDetailsComplete
                          ? chefData.chefDetailsComplete
                          : loginData.chefDetailsComplete
                          ? loginData.chefDetailsComplete
                          : false
                      }
                    />
                    <span className="checkmark"></span>
                    <p>2: Chef Details</p>
                  </label>
                </h2>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <label className="coustom-checkbox">
                    <input
                      type="checkbox"
                      checked={
                        chefData.addDishComplete
                          ? chefData.addDishComplete
                          : loginData.addDishComplete
                          ? loginData.addDishComplete
                          : false
                      }
                    />
                    <span className="checkmark"></span>
                    <p>3: Add A Dish</p>
                  </label>
                </h2>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingfour">
                  <label className="coustom-checkbox">
                    <input
                      type="checkbox"
                      checked={
                        chefData.documentsComplete
                          ? chefData.documentsComplete
                          : loginData.documentsComplete
                          ? loginData.documentsComplete
                          : false
                      }
                    />
                    <span className="checkmark"></span>
                    <p>4: Upload Documents (Optional)</p>
                  </label>
                </h2>
              </div>
              {/* <div className="accordion-item">
                                <h2 className="accordion-header" id="headingfour">
                                    <label className="coustom-checkbox">
                                        <input type="checkbox" checked={chefData.addPaymentInfoComplete ? chefData.addPaymentInfoComplete : loginData.addPaymentInfoComplete ? loginData.addPaymentInfoComplete : false} />
                                        <span className="checkmark"></span>
                                        <p>5: Add Payment Information (Optional)</p>
                                    </label>
                                </h2>
                            </div> */}
            </div>
            <h2 className="sidebar-bottom-title">WELCOME AS A CHEF</h2>
          </div>
          {/* </section> */}
        </div>
      </div>
    </>
  );
};

export default ChefStep;
