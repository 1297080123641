import moment from "moment-timezone";

export const orderedDate = (changeDate, changeTime) => {
  let selectedTime = new Date(changeTime).toTimeString();
  let selectedDay = new Date(changeDate).toDateString();
  let finalDate = selectedDay + " " + selectedTime;
  return finalDate;
};
export const datefiltered = (date) => new Date(date).toLocaleString();
export const selectedDateFormat = (date) => moment(date).format("YYYY-MM-DD");
export const filterPassedTime = (time, currentDate = new Date()) => {
  const selectedDate = new Date(time);
  const date1 = new Date(time).setHours(15, 30, 1, 1);
  console.log("date1", date1);
  const date2 = currentDate.getTime();
  console.log("date2", date2);
  return date1 >= date2;
  // if (date1 == "-126001146206999") {
  //     console.log(date1 > date2)
  //     return date1 < date2;
  // } else {
  //     console.log(date1 < date2)
  //     return date1 > date2
  // }
};
export const timezone = "America/New_York";
export function getDateForMenu(dateStr = null) {
  let date;
  if (dateStr) {
    date = moment.tz(dateStr, timezone);
  } else {
    date = moment.tz(timezone);
  }
  const isDST = date.isDST();
  const isDSTNow = moment(new Date()).isDST();

  if (isDST && !isDSTNow) {
    date.subtract(1, "hour");
  } else if (!isDST && isDSTNow) {
    date.add(1, "hour");
  }

  return date.toDate();
}
