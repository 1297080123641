import { COMMISSION } from "../Actions/Types";


const initialState = {
  commission: null,  // Initial value can be null or an empty array/object, depending on your data structure
  loading: false,
  error: null,
};

const commissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case COMMISSION:
      return {
        ...state,
        commission: action.payload,  // Use action.payload instead of action.commision
        loading: false,
        error: null,
      };

    default:
      return state;
  }
}

export default commissionReducer;
