import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DishImage from "../../assets/images/dish-img.png";
import { useSelector } from "react-redux";
import axios from "axios";
import { NewOrderURL } from "../../component/config/config";
import { toast } from "react-toastify";
import authHeader from "../services/auth_header";
import { tostS, tostE } from "../../toast";
import { ImageURL } from "../../component/config/config";
import moment from "moment";
import { AcceptOrderURL } from "../../component/config/config";
import { RejectOrderURL } from "../../component/config/config";
import { DashboardURL } from "../../component/config/config";
import axiosInstance from "../config/axios";

import { FormikProvider, Form, Field, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import { Dialog } from "@mui/material";
import Pagination from "../myOrders/Pagination";

const Dashboard = () => {
  const [newOrder, setNewOrder] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isDisabled, setIsDisabled] = useState(false);
  const [postsPerPage, setPostsPerPage] = useState(2);
  const [totalData, setTotalData] = useState(0);
  const [loading, setLoading] = useState(false);
  const [sal, setSale] = useState([]);

  const navigate = useNavigate();

  const { chefDetails } = useSelector((state) =>
    state.login.data && state.login.data.chefDetails ? state.login.data : {}
  );
  const todaySale = async () => {
    try {
      const response = await axiosInstance().get(DashboardURL, {});
      setSale(response.data.data);
      // console.log(response.data.data)
    } catch (err) {
      console.log(err.response.data.message);
    }
  };
  const newOrderToday = async (cp, searchString) => {
    document.body.classList.add("loading-indicator");
    console.log(searchString);
    setLoading(true);
    try {
      const res = await axiosInstance().post(NewOrderURL, {
        pageSize: postsPerPage,
        pageNumber: cp,
        searchItem: searchString,
      });
      if (res.status === 200) {
        document.body.classList.remove("loading-indicator");
        setNewOrder(res.data.data);
        setTotalData(res.data.total_records);
        tostS(res.data.message);
      } else {
        document.body.classList.remove("loading-indicator");
      }
    } catch (error) {
      document.body.classList.remove("loading-indicator");
      const message1 = error.res.data.message;
      tostE(error.res.data.message);
    }
  };
  useEffect(() => {
    let p = new Promise((resolve, reject) => {
      if (
        chefDetails.addDishComplete === false &&
        chefDetails.chefDetailsComplete === true
      ) {
        navigate("/dashboard/adddish");
      } else if (chefDetails.chefDetailsComplete === false) {
        navigate("/dashboard/chefdetails");
      }
      resolve();
    });
    p.then(() => {});
    p.catch((err) => {});
  }, [chefDetails]);
  useEffect(() => {
    newOrderToday(currentPage);
    todaySale();
  }, []);

  const accept = (id) => {
    axiosInstance()
      .post(AcceptOrderURL, { id: id })
      .then((re) => {
        if (re.status === 200) {
          tostS(re.data.message);
          newOrderToday(currentPage);
        }
      })
      .catch((error) => {
        tostE(error.re.data.message);
      });
  };

  const [open, setOpen] = useState(false);
  const handleClickOpen = (ids) => {
    setOpen(true);
    setFieldValue("id", ids);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const validationSchema = Yup.object().shape({
    reason: Yup.string()
      // .strict(true).trim("Extra spaces should be truncated")
      .min(5, " Reason must be at least 5 characters ")
      .max(750, "Reason cannot be more than 750 characters ")
      .required("Reason is required"),
  });
  const formik = useFormik({
    initialValues: {
      reason: "",
      id: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, ids) => {
      try {
        const respon = await axiosInstance().post(RejectOrderURL, values, {
          id: ids,
        });
        console.log(respon);
        if (respon.status === 200) {
          const messa = respon.data.message;
          setOpen(false);
          newOrderToday(currentPage);
          tostS(messa);
        }
      } catch (error) {
        setIsDisabled(false);
        tostE(error.respon.data.messsage);
      }
    },
  });

  const paginate = (pageNumber) => {
    newOrderToday(pageNumber);
    setCurrentPage(pageNumber);
  };

  const onNext = () => {
    setCurrentPage(currentPage + 1);
    newOrderToday(currentPage + 1);
  };
  const onPrevious = () => {
    setCurrentPage(currentPage - 1);
    newOrderToday(currentPage - 1);
  };

  let {
    submitCount,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    values,
    setValues,
  } = formik;
  return (
    <>
      <div className="dash-R">
        <div className="dash-head">
          <h3>dashboard</h3>
        </div>
        <div className="row dashboard-box-main">
          <div className="col-md-6 mb-3">
            <div className="dashboard-top-box purple-bg">
              <div className="dashboard-top-box-L">
                <b>{sal?.totalDishesServed}</b>
                <span>Total Dishes Served Till Date</span>
              </div>
              <div className="dashboard-top-box-R">
                <span>
                  <i className="ri-restaurant-fill"></i>
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="dashboard-top-box pink-bg">
              <div className="dashboard-top-box-L">
                <b>{sal?.totalSales?.toFixed(2)}</b>
                <span>Total Earnings</span>
              </div>
              <div className="dashboard-top-box-R">
                <span>
                  <i className="ri-money-dollar-circle-line"></i>
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="dashboard-top-box pink-bg">
              <div className="dashboard-top-box-L">
                <b>{sal?.totalDishesForToday}</b>
                <span>Total Dishes on Menu Today</span>
              </div>
              <div className="dashboard-top-box-R">
                <span>
                  <i className="ri-restaurant-fill"></i>
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="dashboard-top-box purple-bg">
              <div className="dashboard-top-box-L">
                <b>{sal?.totalDishesForWeek}</b>
                <span>Total Dishes on Menu this Week</span>
              </div>
              <div className="dashboard-top-box-R">
                <span>
                  <i className="ri-restaurant-fill"></i>
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="dashboard-top-box pink-bg">
              <div className="dashboard-top-box-L">
                <b>{sal?.totalOrdersForWeek}</b>
                <span>Total Orders Completed so far this Week</span>
              </div>
              <div className="dashboard-top-box-R">
                <span>
                  <i className="ri-restaurant-fill"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="dash-bottom mt-5">
          <h4 className="innner-title-dash">new orders for today</h4>

          {totalData > 0 ? (
            <h3 className="my-orders-title">
              {moment(new Date()).format("Do MMM YYYY")}
            </h3>
          ) : (
            ""
          )}
          <div className="">
            {newOrder.map((orde, id) => (
              <div
                className="accordion dash-accordion"
                id="accordionExample"
                key={id}
              >
                <div className="accordion-item" style={{ paddingLeft: "20px" }}>
                  <h2 className="accordion-header" id="headingOne">
                    {/* <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={"#collapseOne" + id} aria-expanded="true" aria-controls={"collapseOne" + id}
                                        > */}
                    <div className="dish-head">
                      <small>#{orde.orderId}</small>
                      <h5>Dishes</h5>
                    </div>
                    <ul className="Dishes-list dishes-list-edit">
                      {orde.dishData.map((dishes, idx) => (
                        <li key={idx}>
                          <figure className="Dishes-list-L">
                            <img src={ImageURL + dishes?.dishId?.dishImage} />
                          </figure>
                          <figcaption className="Dishes-list-R">
                            <h4>{dishes?.dishName}</h4>
                            <span className="pb-2">
                              <b>Qty:</b> {dishes?.qty}
                            </span>
                          </figcaption>
                        </li>
                      ))}
                    </ul>
                    {/* </button> */}
                  </h2>
                  <div
                    // id={"collapseOne" + id}
                    className="accordion-collapse"
                    // aria-labelledby={"headingOne" + id}
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <ul className="Delivery-content">
                        <li style={{ listStyleType: "none" }}>
                          <b>Delivery Preference:</b>
                          {orde.deliveryPreference}
                        </li>
                        <li style={{ listStyleType: "none" }}>
                          <b>Delivery / Pick up Window:</b>
                          {orde.deliveryOrPickupWindow}
                        </li>
                        <li style={{ listStyleType: "none" }}>
                          <b>Customer Name:</b>
                          {orde.userId.fullName}
                        </li>
                        <li style={{ listStyleType: "none" }}>
                          <b>Order Instructions:</b> {orde.orderInstructions}
                        </li>
                      </ul>
                      <div className="mt-4 btn-group-box">
                        {orde.status === 0 ? (
                          <>
                            <a
                              className="accpet-btn btn purple-btn min-height hvr-sweep-to-right  me-3"
                              onClick={() => accept(orde?._id)}
                            >
                              Accept
                            </a>
                            <a
                              className="reject-btn btn purple-btn min-height hvr-sweep-to-right trans-btn"
                              data-bs-toggle="modal"
                              data-bs-target="#reject-tab"
                              onClick={() => handleClickOpen(orde?._id)}
                            >
                              Reject
                            </a>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <Pagination
          currentPage={currentPage}
          postsPerPage={postsPerPage}
          totalPosts={totalData}
          paginate={(pageNumber) => {
            paginate(pageNumber);
          }}
          onNext={onNext}
          onPrevious={onPrevious}
        />

        <Dialog open={open}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <div className="common-modal space-2">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <button
                      type="button"
                      className="close-btn"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={handleClose}
                    >
                      <i className="ri-close-circle-line"></i>
                    </button>
                    <div className="modal-head text-center mb-4">
                      <h4 className="modal-title">reject order</h4>
                      <p>Please tell us why you rejected this order</p>
                    </div>
                    <div className="modal-body text-center">
                      <div className="mb-0 reject-text-area">
                        <Field
                          type="text"
                          component="textarea"
                          rows="4"
                          placeholder="Enter reason"
                          className={`form-control ${
                            submitCount > 0
                              ? errors?.reason && touched?.reason
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}
                          name="reason"
                          value={values.reason.replace(/ +/g, " ")}
                        ></Field>
                        <span
                          className="text-danger"
                          style={{ textAlign: "left" }}
                        >
                          <ErrorMessage name="reason" />
                        </span>
                      </div>
                      <div className="mt-5">
                        <button className="btn purple-btn min-height hvr-sweep-to-right">
                          submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </FormikProvider>
        </Dialog>
        {/* <div className="screen-div-info">
                    <ul className="info-list">
                        <li><a href="tel:9513539491"><i className="ri-phone-line"></i>951-353-9491</a></li>
                        <li><a href="mailto:info@email.com"><i className="ri-mail-line"></i>info@email.com</a></li>
                    </ul>
                </div> */}
      </div>
    </>
  );
};

export default Dashboard;

// import React, { useEffect, useState } from 'react'
// import { useNavigate } from 'react-router-dom'
// import DishImage from "../../assets/images/dish-img.png"
// import { useSelector } from 'react-redux'
// import axios from 'axios'
// import { NewOrderURL } from "../../component/config/config";
// import { toast } from 'react-toastify'
// import authHeader from "../services/auth_header";

// const Dashboard = () => {
//     const [todayOrder, setOrderToday] = useState([])
//     const [postsPerPage, setPostsPerPage] = useState(2)
//     const [totalData, setTotalData] = useState(0);
//     const [loading, setLoading] = useState(false)

//     const navigate = useNavigate();

//     const { chefDetails } = useSelector(state => state.login.data && state.login.data.chefDetails ? state.login.data : {});

//     const newOrder = async (cp) => {
//         setLoading(true)
//         try {
//             const res = (await axios.post(NewOrderURL, { pageSize: postsPerPage, pageNumber: cp, searchItem: "" }, { headers: authHeader() }))
//             if (res.status === 200) {
//                 setOrderToday(res.data.data)
//                 setTotalData(res.data.total_records)
//                 toast.success(res.data.message, { autoClose: false })
//             }
//         }
//         catch (error) {
//             const message1 = error.res.data.message
//             toast.error(error.res.data.message, { autoClose: false });
//         }
//     }
//     useEffect(() => {
//         let p = new Promise((resolve, reject) => {

//             if (chefDetails.addDishComplete === false && chefDetails.chefDetailsComplete === true) {
//                 navigate('/dashboard/adddish')

//             } else if (chefDetails.chefDetailsComplete === false) {
//                 navigate('/dashboard/chefdetails')
//             }
//             resolve()
//         })
//         p.then(() => {
//         })
//         p.catch((err) => {
//         })
//     }, [chefDetails])

//     return (
//         <>
//             <div className="dash-R">
//                 <div className="dash-head">
//                     <h3>dashboard</h3>
//                 </div>
//                 <div className="row dashboard-box-main">
//                     <div className="col-md-6 mb-3">
//                         <div className="dashboard-top-box purple-bg">
//                             <div className="dashboard-top-box-L">
//                                 <b>40</b>
//                                 <span>Total Dishes Served</span>
//                             </div>
//                             <div className="dashboard-top-box-R">
//                                 <span><i className="ri-restaurant-fill"></i></span>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="col-md-6 mb-3">
//                         <div className="dashboard-top-box pink-bg">
//                             <div className="dashboard-top-box-L">
//                                 <b>40</b>
//                                 <span>Total Dishes Served</span>
//                             </div>
//                             <div className="dashboard-top-box-R">
//                                 <span><i className="ri-money-dollar-circle-line"></i></span>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="col-md-4">
//                         <div className="dashboard-top-box pink-bg">
//                             <div className="dashboard-top-box-L">
//                                 <b>40</b>
//                                 <span>Total Dishes Served</span>
//                             </div>
//                             <div className="dashboard-top-box-R">
//                                 <span><i className="ri-restaurant-fill"></i></span>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="col-md-4">
//                         <div className="dashboard-top-box purple-bg">
//                             <div className="dashboard-top-box-L">
//                                 <b>40</b>
//                                 <span>Total Dishes Served</span>
//                             </div>
//                             <div className="dashboard-top-box-R">
//                                 <span><i className="ri-restaurant-fill"></i></span>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="col-md-4">
//                         <div className="dashboard-top-box pink-bg">
//                             <div className="dashboard-top-box-L">
//                                 <b>40</b>
//                                 <span>Total Dishes Served</span>
//                             </div>
//                             <div className="dashboard-top-box-R">
//                                 <span><i className="ri-restaurant-fill"></i></span>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="dash-bottom mt-5">
//                     <h4 className="innner-title-dash">new orders for today</h4>

//                     <h4 className="innner-title-dash">10th may 2022</h4>
//                     <div className="">
//                         <div className="accordion dash-accordion" id="accordionExample">
//                             <div className="accordion-item">
//                                 <h2 className="accordion-header" id="headingOne">
//                                     <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
//                                         <div className="dish-head">
//                                             <small>#121</small>
//                                             <h5>Dishes</h5>
//                                         </div>
//                                         <ul className="Dishes-list">
//                                             <li>
//                                                 <figure className="Dishes-list-L"><img src={DishImage} /></figure>
//                                                 <figcaption className="Dishes-list-R">
//                                                     <h4>Green Salad</h4>
//                                                     <span><b>Qty:</b> 03</span>
//                                                 </figcaption>
//                                             </li>
//                                             <li>
//                                                 <figure className="Dishes-list-L"><img src={DishImage} /></figure>
//                                                 <figcaption className="Dishes-list-R">
//                                                     <h4>Green Salad</h4>
//                                                     <span><b>Qty:</b> 03</span>
//                                                 </figcaption>
//                                             </li>
//                                         </ul>
//                                     </button>
//                                 </h2>
//                                 <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
//                                     <div className="accordion-body">
//                                         <ul className="Delivery-content">
//                                             <li><b>Delivery Preference:</b>Pickup</li>
//                                             <li><b>Delivery / Pick up Window:</b>10am - 1pm</li>
//                                             <li><b>Customer Name:</b>Michael Bruno</li>
//                                             <li><b>Order Instructions:</b> Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit, Sed Do Eiusmod Tempor Incididunt Ut Labore Et Dolore Magna Aliqua. Ut Enim Ad Minim Veniam, Quis Nostrud Exercitation</li>
//                                         </ul>
//                                         <div className="mt-4 btn-group-box"><a className="accpet-btn btn purple-btn min-height hvr-sweep-to-right  me-3">Accept</a>
//                                             <a className="reject-btn btn purple-btn min-height hvr-sweep-to-right trans-btn">Reject</a>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="screen-div-info">
//                     <ul className="info-list">
//                         <li><a href="tel:9513539491"><i className="ri-phone-line"></i>951-353-9491</a></li>
//                         <li><a href="mailto:info@email.com"><i className="ri-mail-line"></i>info@email.com</a></li>
//                     </ul>
//                 </div>
//             </div>
//         </>
//     )
// }

// export default Dashboard
