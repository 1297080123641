import { combineReducers } from 'redux';

import login from './Login';
import Signup from './Signup';
import commissionReducer from "./Commission";


export default combineReducers({
  login,
  Signup,
  commissionReducer,
});