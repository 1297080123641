import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import { AboutURL, ImageURL } from "./config/config";
import Testimonial from "./Testimonial";
import axios from "axios";
import { Typography } from "@material-ui/core";
import { Helmet } from "react-helmet";

const aboutBanner = ImageURL + "assets/images/about-banner.png";
const shape4 = ImageURL + "assets/images/shape-4.png";
const shape5 = ImageURL + "assets/images/shape-5.png";
const aboutChef = ImageURL + "assets/images/aboutUS.png";
const icon1 = ImageURL + "assets/images/icon1.png";
const icon2 = ImageURL + "assets/images/icon2.png";
const icon3 = ImageURL + "assets/images/icon3.png";
const aboutBottom = ImageURL + "assets/images/about-bottom-img.png";

const AboutUs = () => {
  const [about, setAbout] = useState();
  const aboutFetch = async () => {
    const response = await axios.post(AboutURL);
    setAbout(response.data.data);
  };

  useEffect(() => {
    aboutFetch();
  }, []);
  return (
    <>
      <div className="wraper-inner padding-head">
        <Helmet>
          <title> Wanna Eat </title>
          <meta
            name="keywords"
            content=" home cooked meal delivery, home cooked healthy meals delivery, home cooked Indian food delivery, home cooked breakfast delivery, home cooked food delivery, home cooked Indian food delivery, home cooked family meals delivery, home cooked lunch delivery, home cooked dinner delivery, home cooked evening snacks delivery"
          />
          <meta
            name="description"
            content=" Our home cooks dazzle palates with exceptional creations, signature dishes, cherished family recipes, and inventive culinary masterpieces. Download app, order tasty home cooked food and we deliver them right to your doorstep"
          />
        </Helmet>
        <div className="inner-pages">
          <div className="inner-banner inner-space">
            <figure>
              <img src={aboutBanner} />
            </figure>
            <div className="container">
              <div className="banner-inner-content ">
                <h4>About Us</h4>
              </div>
            </div>
          </div>
          <section className="about-us-description space-similer">
            <span className="shape-4">
              <img src={shape4} />
            </span>
            <span className="shape-5">
              <img src={shape5} />
            </span>
            <div className="container">
              <div className="row">
                <div className="col-md-5">
                  <figure>
                    <img src={aboutChef} />
                  </figure>
                </div>
                <div className="col-md-7 ps-4">
                  <div className="similer-title-head text-center mb-4">
                    <span className="sub-title">Our story</span>
                    <h2>About us</h2>
                  </div>
                  <div className="pt-5 about-us-content">
                    <p className="pinq-text text-center">
                      <Typography
                        style={{ textAlign: "left" }}
                        dangerouslySetInnerHTML={{ __html: about?.aboutUsH1 }}
                        variant="h6"
                      ></Typography>
                    </p>
                    <p className="text-center">
                      <Typography
                        style={{ textAlign: "left" }}
                        dangerouslySetInnerHTML={{
                          __html: about?.descriptionOne,
                        }}
                        variant="h6"
                      ></Typography>
                    </p>
                  </div>
                  <div className="row about-steps mt-5">
                    <div className="col-md-4">
                      <div className="about-steps-box">
                        <figure>
                          <img src={icon1} />
                        </figure>
                        <figcaption className="text-center">
                          <h4>
                            <Typography
                              style={{ fontWeight: "bold" }}
                              dangerouslySetInnerHTML={{
                                __html: about?.iconH1,
                              }}
                            ></Typography>
                          </h4>
                        </figcaption>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="about-steps-box">
                        <figure>
                          <img src={icon2} />
                        </figure>
                        <figcaption className="text-center">
                          <h4>
                            <Typography
                              style={{ fontWeight: "bold" }}
                              dangerouslySetInnerHTML={{
                                __html: about?.iconH2,
                              }}
                            ></Typography>
                          </h4>
                        </figcaption>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="about-steps-box">
                        <figure>
                          <img src={icon3} />
                        </figure>
                        <figcaption className="text-center">
                          <h4>
                            <Typography
                              style={{ fontWeight: "bold" }}
                              dangerouslySetInnerHTML={{
                                __html: about?.iconH3,
                              }}
                            ></Typography>
                          </h4>
                        </figcaption>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="about-bottom space-similer">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-6 about-bottom-L">
                  <figure>
                    <img src={aboutBottom} />
                  </figure>
                </div>
                <div className="col-md-6 about-bottom-R">
                  <h3>
                    <Typography
                      style={{
                        textAlign: "left",
                        fontWeight: "bold",
                        fontSize: "35px",
                        color: "#8d126e",
                      }}
                      dangerouslySetInnerHTML={{ __html: about?.aboutUsH2 }}
                      variant="h6"
                    ></Typography>
                  </h3>
                  <p>
                    <Typography
                      style={{ textAlign: "left" }}
                      dangerouslySetInnerHTML={{
                        __html: about?.descriptionTwo,
                      }}
                      variant="h6"
                    ></Typography>
                  </p>
                </div>
              </div>
            </div>
          </section>
          <Testimonial />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
