import React, { useEffect, useState } from "react";
import InnerLogo from "../../assets/images/logo 2(1).png";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { cleanAllData, commissionData } from "../../Actions/ActionCreators";
import { useSelector } from "react-redux";
import { ImageURL, LoginChefURL } from "../../component/config/config";
import dummy from "../../assets/images/dummy.jpg"
import axiosInstance from "../config/axios";

const InnerHeader = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const {id} = useParams()
	const logout = () => {
		dispatch(cleanAllData())
		localStorage.clear()
		navigate('/')
	};
	const [data, setData] = useState()
  const [refresh, setRefresh] = useState(true);

	const getData = async () => {
		const response = await axiosInstance().get(LoginChefURL, {})
		dispatch(commissionData(response.data.data));
		setData(response.data.data)
		// localStorage.setItem("pageSize", response?.data?.data?.pageSize)
	}

	
	const {pathname} = window.location;
	let arr = [
    "/dashboard/myearnings",
    "/dashboard/settings",
    "/dashboard/addish",
    `/dashboard/editdish/${id}`,
  ];
	useEffect(() => {
    if (arr.includes(pathname) || refresh) {
      getData();
      setRefresh(false);
    }
  }, [pathname]);

  
	const LoginData = useSelector((state) =>
		state.login.data && state.login.data.chefDetails
			? state.login.data
			: {}
	);
	const myName = LoginData.chefDetails && LoginData.chefDetails.fullName ? LoginData.chefDetails.fullName : ""
	const myImage = LoginData.chefDetails && LoginData.chefDetails?.profileImage ? LoginData?.chefDetails?.profileImage : LoginData.chefDetails?.chefDetails?.profileImage
	return (
		<>
			{/* {console.log(data)} */}
			<header className="inner-header" id="fixed-header">
				<div className="container-fluid">
					<div className="inner-header-box d-flex">
						<div className="inner-header-L">
							<a href="/">
								<figure>
									<img src={InnerLogo} />
								</figure>
							</a>
						</div>
						<div className="inner-header-R">
							<div className="dropdown drop-down-profile">
								<button
									className="btn dropdown-toggle"
									type="button"
									id="dropdownMenuButton1"
									data-bs-toggle="dropdown"
									aria-expanded="false"
								>
									<span className="profile-title-text">
										<figure><img src={data?.profileImage ? ImageURL + data?.profileImage : dummy} /></figure>
										<small>{data?.fullName}</small>
									</span>
								</button>
								<ul
									className="dropdown-menu"
									aria-labelledby="dropdownMenuButton1"
								>
									<li>
										<NavLink
											className="dropdown-item"
											to="settings"
										// data-bs-toggle="modal"
										// data-bs-target="#settings"
										>
											<i className="ri-settings-2-line"></i>{" "}
											Settings
										</NavLink>
									</li>
									<li onClick={() => logout()}>
										<a
											className="dropdown-item"
											data-bs-toggle="modal"
											data-bs-target="#logout"
											style={{ cursor: "pointer" }}
										>
											<i className="ri-logout-box-r-line"></i>{" "}
											Logout
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</header>
		</>
	);
};

export default InnerHeader;
