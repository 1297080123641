import React, { useEffect, useState } from 'react'
import Footer from './Footer'
import termsBanner from './../assets/images/terms-banner.png'
import { ChefTermsURL } from './config/config'
import axios from 'axios'
import axiosInstance from "./config/axios";
import { Typography } from "@material-ui/core";
const ChefTerms = () => {
    const [chefTerm, setChefTerm] = useState()
    const cheftermFetch = async () => {
        const response = await axiosInstance().get(ChefTermsURL)
        setChefTerm(response.data.data)
    }
    useEffect(() => {
        cheftermFetch()
    }, [])
    return (
        <>
            <div class="wraper-inner padding-head">
                <div class="inner-pages">
                    <div class="inner-banner inner-space">
                        <figure><img src={termsBanner} /></figure>
                        <div class="container">
                            <div class="banner-inner-content ">
                                <h4>Chef Terms & Conditions</h4>
                            </div>
                        </div>
                    </div>

                    <div class="terms-policy-content space-similer">
                        <div class="container">

                            <div class="terms-policy-content-box">
                                <p >
                                    <Typography dangerouslySetInnerHTML={{ __html: chefTerm?.chefTermsText }} variant="h6" ></Typography>
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <Footer />

        </>

    )
}

export default ChefTerms