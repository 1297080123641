import React, { useEffect, useRef, useState } from "react";
import axiosInstance from "../config/axios";
import {
  MyMenuURL,
  dishClone,
  DeleteURL,
  checkDeleteURL,
} from "../../component/config/config";
import { ImageURL } from "../../component/config/config";
import {
  MenuDatesDishesURL,
  MenuDatesURL,
  DeleteMenuURL,
} from "../../component/config/config";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Dialog } from "@material-ui/core";
import moment from "moment-timezone";
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { tostS, tostE } from "../../toast";
import { getDateForMenu, orderedDate } from "../../utility/DateFormat";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const MyMenu = () => {
  let { type } = useParams();
  const bottomScroll = useRef();
  let navigate = useNavigate();
  const location = useLocation();
  const tempCount = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
  const current_date = new Date();
  const minDate = new Date(current_date);
  const storedDate = localStorage.getItem("selectedDate");
  minDate.setDate(current_date.getDate() + 15); // Start from today + 15 days
  const maxDate = new Date(current_date);
  maxDate.setMonth(current_date.getMonth() + 6); // End at today + 6 months

  const [menu, setMenu] = useState([]);
  const [dishadd, setDishadd] = useState([]);
  const [menuDelete, setMenuDelete] = useState({ id: null, show: false });
  const [open, setOpen] = useState({ id: null, show: false });
  const [activeOpen, setActiveOpen] = useState(false);
  const [activeMessage, setActiveMessage] = useState("");
  const [isMenuDeleteMessage, setIsMenuDeleteMessage] = useState("");
  const [menuOpen, setMenuOpen] = useState({ show: false, date: null });
  const [dates, setDates] = useState([]);
  const [check, setCheck] = useState(0);
  // const [select, setSelect] = useState(false);
  const [selectIds, setSelectedIds] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    getDateForMenu(moment(new Date(storedDate)).format())
  );
  const [count, setCount] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [disable, setDisable] = useState(false);
  const [calendarDate, setCalendarDate] = useState(
    getDateForMenu(moment(new Date(storedDate)).format())
  );
  const [forceRemount, setForceRemount] = useState(false);
  const [cloneOpen, setCloneOpen] = useState(false);
  const [cloneSelect, setCloneSelect] = useState(false);

  const fetchData = async (datefilter, type) => {
    setMenu([]);
    let param;
    if (datefilter !== null && datefilter !== undefined) {
      param = { menuDate: datefilter };
      axiosInstance()
        .post(MenuDatesDishesURL, param)
        .then((res) => {
          const result = res.data.data;
          if (result.length && type !== "update" && type !== "add") {
            setTimeout(() => {
              setMenu(result);
              setCheck(1);
              setCount(0);
              document.body.classList.remove("loading-indicator");
            }, 1000);
          } else {
            axiosInstance()
              .post(MyMenuURL, {})
              .then((res1) => {
                setMenu(res1.data.data);
                setCheck(0);
                document.body.classList.remove("loading-indicator");
              })
              .catch((error) => {
                document.body.classList.remove("loading-indicator");
                tostE(error.response.data.message);
              });
              
          }
        })
        .catch((error) => {
          document.body.classList.remove("loading-indicator");
          tostE(error.response.data.message);
        });
    } else {
      axiosInstance()
        .post(MyMenuURL, {})
        .then((res) => {
          const resp = res.data.data;
          setMenu(resp);
          document.body.classList.remove("loading-indicator");
        })
        .catch((error) => {
          document.body.classList.remove("loading-indicator");
          tostE(error.response.data.message);
        });
    }
  };
  useEffect(() => {
    if (type === "all") {
      setCalendarDate(null);
      fetchData(null);
    } else {
      if (location?.state?.type) {
        fetchData(getDateForMenu(moment(new Date(type)).format()), location?.state?.type);
      } else {
        fetchData(getDateForMenu(moment(new Date(type)).format()));
      }
    }
    makeInitialDates();
    checkDish();
  }, [refresh, forceRemount]);

  useEffect(() => {
    if (type === "all") {
      setForceRemount((prev) => !prev);
    }
  }, [type]);

  useEffect(() => {
    if (location?.state != undefined || location?.state != null) {
      setSelectedIds(location?.state?.data.map((data) => data._id));
      setCount(location?.state?.data.length);
      localStorage.setItem(
        "selectedDate",
        getDateForMenu(moment(new Date(type)).format())
      );
      setSelectedDate(getDateForMenu(moment(new Date(type)).format()));
    } else {
      setCount(0);
    }
  }, [location]);

  //For edit a dish details through all menu
  const handleView = (data) => {
    navigate(`/dashboard/editdish/${data._id}`, { state: data });
  };

  //For edit a menu
  const handleMenuView = (data) => {
    navigate(`/dashboard/updatemenu/${type}`, {
      state: { data: data,
        type: getDateForMenu(moment(new Date(selectedDate)).format()),
       },
      replace: true,
    });
    let Menu_Id = data[0].menuId;
    localStorage.setItem("menuId", Menu_Id);
  };

  //For open a dish details
  const nextPage = (data) => {
    navigate(`/dashboard/dishdetails/${data._id}`, { state: data });
  };

  //For delete a dish in all menu
  const checkdeleteDish = async (data) => {
    const body = { id: data._id };
    try {
      const response = await axiosInstance().post(checkDeleteURL, body);
      if (response.data.status == 1) {
        setOpen({ ...open, id: data?._id, show: true });
      }
    } catch (err) {
      setActiveOpen(true);
      setActiveMessage(err.response.data.message);
    }
  };

  const deleteDish = async (data) => {
    const body = { id: open?.id };
    try {
      const response = await axiosInstance().post(DeleteURL, body);
      tostS(response?.data?.message);
      setRefresh(!refresh);
      setOpen(false);
    } catch (err) {
      tostE(err.response.data.message);
    }
  };

  //For delete a dish in a particular date
  const deleteMenuDish = async (data) => {
    const body = { dishId: data?.dishId, menuId: data?.menuId };
    try {
      const response = await axiosInstance().post(DeleteMenuURL, body);
      tostS(response.data.message);
      setRefresh(!refresh);
      handleDeleteClose();
      // fetchData(null);
    } catch (err) {
      tostE(err.response.data.message);
    }
  };

  const checkDish = async () => {
    axiosInstance()
      .get(MenuDatesURL)
      .then((r) => {
        setDishadd(r.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleMenuDelete = (data) => {
    setMenuDelete({
      ...menuDelete,
      dishId: data?._id,
      menuId: data?.menuId,
      show: true,
    });
  };

  const handleDeleteClose = () => {
    setMenuDelete({ ...menuDelete, id: null, show: false });
  };

  //For Dialog box
  const handleClickOpen = (data) => {
    checkdeleteDish(data);
    if(data?.menuStatus === false)
      setIsMenuDeleteMessage(`Are you sure you want to permanently delete this dish from your menu?`);
    else
      setIsMenuDeleteMessage(`Warning: You have listed this dish on a current or future
                    date menu. Are you sure you want to permanently delete this
                    dish? It will get deleted from all the future menus too.`); 
    setOpen({ ...open, id: data?._id });
  };

  //For clone a dish
  const handleClone = (data) => {
    setCloneOpen({ ...cloneOpen, data: data, show: true });
  };
  const handleCloneClose = () => {
    setCloneOpen({ ...cloneOpen, id: null, show: false });
  };

  const cloneDish = async (data) => {
    document.body.classList.add("loading-indicator");
    const body = { id: data?._id };
    try {
      const response = await axiosInstance().post(dishClone, body);
      tostS(response.data.message);
      handleCloneClose();
      // setRefresh(!refresh);
      navigate(`/dashboard/editdish/${response?.data?.data?._id}`, {
        state: data,
      });
      document.body.classList.remove("loading-indicator");
    } catch (err) {
      tostE(err.response.data.message);
      document.body.classList.remove("loading-indicator");
    }
  };
  const handleClose = () => {
    setOpen({ ...open, show: false });
  };

  //for menu details popup open
  const handleOpen = (d) => {
    setMenuOpen({ show: true, date: d });
  };

  const handleClickClose = () => {
    setMenuOpen({ show: false, date: null });
  };

  const handleConfirm = async (d) => {
    navigate(`/dashboard/mymenu/${moment(d).format("DD-MMMM-YYYY")}`);
    setSelectedDate(getDateForMenu(moment(new Date(d)).format()));
    localStorage.setItem(
      "selectedDate",
      getDateForMenu(moment(new Date(d)).format())
    );
    // setSelectedDate(moment().format("YYYY-MM-DD"));
    // fetchData(moment(new Date(d)).format("YYYY-MM-DD"));
    fetchData(moment(new Date(d)).format());
    setSelectedIds([]);
    handleClickClose();
    setCount(0);
    setDisable(false);
  };

  //For show all the dates on the top of my menu
  const makeInitialDates = () => {
    let dateArray = [moment(new Date()).format("l")];
    tempCount.map((t) => {
      let nextDate = moment(current_date).add(t, "days").format();
      dateArray.push(nextDate);
    });
    setDates(dateArray);
  };

  //For date button
  const nextDate = (daa) => {
    if (daa) {
      setCalendarDate(getDateForMenu(moment(new Date(daa)).format()));
    } else {
      setCalendarDate(getDateForMenu(moment(new Date()).format()));
      daa = getDateForMenu(moment(new Date()).format());
    }
    // setCalendarDate(new Date(daa));
    if (count >= 1) {
      handleOpen(daa);
    } else {
      navigate(`/dashboard/mymenu/${moment(daa).format("DD-MMMM-YYYY")}`);
      setSelectedDate(getDateForMenu(moment(new Date(daa)).format()));
      localStorage.setItem(
        "selectedDate",
        getDateForMenu(moment(new Date(daa)).format())
      );
      // fetchData(daa);

      // fetchData(moment(new Date(daa)).format()).toISOString()
      fetchData(getDateForMenu(moment(new Date(daa)).format()));
      // new Date(orderedDate(daa, new Date())).toISOString()

      setSelectedIds([]);
      setCount(0);
      setDisable(false);
    }
  };

  //For all dishes button
  const allDate = () => {
    navigate("/dashboard/mymenu/all");
    fetchData(null);
    setCalendarDate(null);
    setSelectedIds([]);
    setCount(0);
    setDisable(false);
  };

  //For Select all check box
  // const selectAll = () => {
  //   var items = document.getElementsByName("food");
  //   for (var i = 0; i < items.length; i++) {
  //     if (items[i].type === "checkbox") {
  //       let current = [...selectIds];
  //       current = [...selectIds, items[i].value];
  //       items[i].checked = true;
  //       selectIds.push(items[i].value);
  //       setSelectedIds(current);
  //       setCount(items.length);
  //       setDisable(true);
  //     }
  //     // console.log("selectall", selectIds)
  //   }
  // };
  const selectAll = () => {
    var items = document.getElementsByName("food");
    let current = [];
    for (var i = 0; i < items.length; i++) {
      let dishString = items[i].getAttribute("data-name").split("-");
      if (items[i].type === "checkbox") {
        if (
          dishString?.length > 0 &&
          dishString[0]?.toLowerCase()?.includes("clone")
        ) {
          items[i].checked = false;
        } else {
          items[i].checked = true;
          current.push(items[i].value);
        }
      }
    }

    setSelectedIds(current);
    setCount(current.length);
    setDisable(true);
  };

  //For unselect all checkbox
  const UnSelectAll = () => {
    var items = document.getElementsByName("food");
    for (var i = 0; i < items.length; i++) {
      if (items[i].type === "checkbox") {
        let current = [...selectIds];
        current = [...selectIds, items[i].value];
        items[i].checked = false;
        setSelectedIds([]);
        setCount(0);
        setDisable(false);
      }
    }
  };

  //For CheckBox
  const handleChange = (e, index, id, name) => {
    if (name?.length > 0 && name?.toLowerCase()?.includes("clone")) {
      handleSeClone(e);
    } else {
      let current = [...selectIds];
      if (e.target.checked) {
        current = [...selectIds, id];
        setCount(selectIds.length + 1);
      } else {
        current.splice(current.indexOf(id), 1);
        setCount(selectIds.length - 1);
      }
      setSelectedIds(current);
      // setSelect(!select);
      setDisable(false);
    }
  };

  const handleSeClone = (data) => {
    setCloneSelect({ ...cloneSelect, data: data, show: true });
  };
  const handleCloneSeClose = () => {
    setCloneSelect({ ...cloneSelect, id: null, show: false });
  };

  //Add Menu Details
  const submit = async () => {
    if (selectedDate !== null) {
      let fullDate = getDateForMenu(moment(new Date(selectedDate)).format());
      if (selectIds.length) {
        navigate("/dashboard/addmenu/date", {
          state: {
            dishIds: selectIds,
            menuDate: getDateForMenu(moment(new Date(selectedDate)).format()),
            menu: location?.state?.data,
            fullDate,
          },
          replace: true,
        });
      }
      if (location?.state?.type === "update" && selectIds.length) {
        navigate(`/dashboard/updatemenu/${type}`, {
          state: {
            dishIds: selectIds,
            menuDate: getDateForMenu(moment(new Date(selectedDate)).format()),
            data: location?.state?.data,
            fullDate,
          },
          replace: true,
        });
      }
      // else { tostE("Please select a dish") }
    } else {
      tostE("Please select a date");
    }
  };

  const Dates = () => {
    let arr = [...dishadd];
    let check = arr.map((data) =>
      moment(getDateForMenu(moment(new Date(data)).format())).format(
        "DD-MM-YYYY"
      )
    );

    return dates.map((daa, index) => {
      const dayName = moment(daa).format("ddd");
      let styleCx = "";
      if (check.includes(moment(daa).format("DD-MM-YYYY"))) {
        if (
          type === moment(daa).format("DD-MMMM-YYYY") ||
          type === location.pathname.split("/").slice(3)
        ) {
          styleCx = "filter-button active";
        } else styleCx = "filter-button";
      } else if (
        type === moment(daa).format("DD-MMMM-YYYY") ||
        type === location.pathname.split("/").slice(3)
      ) {
        styleCx = "filter-button active";
      } else {
        styleCx = "filter-button blank-data";
      }

      const handleClickDate = () => nextDate(daa);

      return (
        <a
          className={styleCx}
          onClick={handleClickDate}
          style={{ cursor: "pointer" }}
          data-filter="Today"
        >
          {index === 0
            ? "Today"
            : `${dayName}, ${moment(daa).format("Do MMM")}`}
        </a>
      );
    });
  };

  const highlightedDates = dishadd
    ?.filter((date) => {
      const dateObj = getDateForMenu(moment(new Date(date)).format());
      // return dateObj >= minDate && dateObj <= maxDate;
      return dateObj >= minDate;
    })
    .map((dateObj) => getDateForMenu(moment(new Date(dateObj)).format()));

  const CustomDatePickerInput = ({ value, onClick }) => (
    <input
      type="text"
      value={value}
      onClick={onClick}
      readOnly
      className="form-control"
    />
  );


// Sort the menu array based on the deliveryOrPickupWindow values
const sortedMenu = menu.sort((a, b) => {
  const aHasBoth = a.deliveryOrPickupWindow?.includes("10am-1pm") && a.deliveryOrPickupWindow?.includes("4pm-7pm");
  const bHasBoth = b.deliveryOrPickupWindow?.includes("10am-1pm") && b.deliveryOrPickupWindow?.includes("4pm-7pm");
  const aHas10am1pm = a.deliveryOrPickupWindow?.includes("10am-1pm");
  const bHas10am1pm = b.deliveryOrPickupWindow?.includes("10am-1pm");
  const aHas4pm7pm = a.deliveryOrPickupWindow?.includes("4pm-7pm");
  const bHas4pm7pm = b.deliveryOrPickupWindow?.includes("4pm-7pm");

  if (aHasBoth && !bHasBoth) return -1;
  if (!aHasBoth && bHasBoth) return 1;
  if (aHas10am1pm && !bHas10am1pm) return -1;
  if (!aHas10am1pm && bHas10am1pm) return 1;
  if (aHas4pm7pm && !bHas4pm7pm) return -1;
  if (!aHas4pm7pm && bHas4pm7pm) return 1;
  return 0;
});


  
  return (
    <>
      <div className="dash-R">
        <div className="dash-head">
          <h3>my menu</h3>
        </div>
        <div className="dash-bottom mt-2">
          <div className="filter-menu-box d-flex align-items-center">
            <IconButton
              onClick={() =>
                (bottomScroll.current.scrollLeft =
                  bottomScroll.current.scrollLeft - 100)
              }
              style={{ cursor: "pointer" }}
            >
              <ChevronLeftIcon />
            </IconButton>
            <div className="scrollbar-bg">
              <div className="filter-head" ref={bottomScroll}>
                <a
                  className={
                    type === "all" ? "active filter-button" : "filter-button"
                  }
                  data-filter="all"
                  style={{ cursor: "pointer" }}
                  onClick={() => allDate()}
                >
                  All Dishes
                </a>
                <Dates />

                <DatePicker
                  selected={calendarDate}
                  onChange={(e) => nextDate(e)}
                  highlightDates={highlightedDates}
                  minDate={minDate}
                  maxDate={maxDate}
                  placeholderText="Select Date"
                />
              </div>
            </div>
            <IconButton
              onClick={() =>
                (bottomScroll.current.scrollLeft =
                  bottomScroll.current.scrollLeft + 100)
              }
              style={{ cursor: "pointer" }}
            >
              <ChevronRightIcon />
            </IconButton>
          </div>
          <div className="filter-text-button justify-content-between d-flex align-items-center mt-3 mb-3">
            {type != "all" && check != 1 ? (
              <div className="filter-text-button-L">
                <div className="filter-menu-box">
                  <span className="number-of-select">
                    <i className="ri-check-line"></i>
                    {count}
                  </span>
                  <button
                    className="select-all-box"
                    style={{ cursor: "pointer", border: "#fff" }}
                    onClick={() => selectAll()}
                    disabled={disable}
                  >
                    Select All
                  </button>
                  <button
                    className="Unselect-box"
                    style={{ cursor: "pointer", border: "#fff" }}
                    onClick={() => UnSelectAll()}
                  >
                    Unselect
                  </button>
                </div>
                <span>
                  <b>Step 1:</b> Please select the menu for a given day by
                  selecting the dishes you want to add.
                  <br />
                  <b>Step 2:</b> Click the “Add Menu Details” button to finalize
                  the number of servings you are going to cook for each selected
                  dish.
                </span>
              </div>
            ) : null}

            <div className="filter-text-button-R">
              {type === "all" ? (
                <NavLink
                  to="/dashboard/addish"
                  className="btn purple-btn min-height hvr-sweep-to-right with-icon"
                >
                  {" "}
                  <i className="ri-add-circle-line"></i> Add New Dish
                </NavLink>
              ) : null}
              {check != 1 && type != "all" && selectIds.length > 0 ? (
                <a
                  className="btn purple-btn min-height hvr-sweep-to-right with-icon"
                  onClick={() => submit()}
                >
                  Add Menu Details
                </a>
              ) : null}
              <div className="filter-text-button-R">
                {type != "all" && check != 0 ? (
                  // && filterPassedTime(new Date(type).setHours(15, 30, 1, 1))
                  <a
                    className="btn purple-btn min-height hvr-sweep-to-right trans-btn "
                    style={{ marginLeft: "20px" }}
                    onClick={() => handleMenuView(menu)}
                  >
                    Edit Menu Details
                  </a>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="row">
            {sortedMenu?.map((data, index) => (
              <div className=" col-md-3 filter Today">
                <div className="gallery_product">
                  <div className="action-box-head">
                    {type === "all" ? (
                      // <>
                      <div className="action-box-head-L">
                        <a
                          className="edit-btn hvr-sweep-to-right "
                          onClick={() => handleView(data)}
                        >
                          <i className="ri-pencil-fill"></i>
                        </a>
                        <a
                          className="edit-btn hvr-sweep-to-right "
                          onClick={() => handleClone(data)}
                        >
                          <i
                            style={{ color: "#fd207a" }}
                            class="ri-file-copy-line"
                          ></i>
                        </a>
                        <a
                          type="button"
                          className="delete-btn hvr-sweep-to-right"
                          data-bs-toggle="modal"
                          data-bs-target="#delete-menu"
                          onClick={() => handleClickOpen(data)}
                        >
                          <i className="ri-delete-bin-fill"></i>
                        </a>
                      </div>
                    ) : null}
                    {data.totalServings >= 0 ? (
                      <div className="action-box-head-L">
                        <a
                          type="button"
                          className="delete-btn hvr-sweep-to-right"
                          data-bs-toggle="modal"
                          data-bs-target="#delete-menu"
                          onClick={() => handleMenuDelete(data)}
                        >
                          <i className="ri-delete-bin-fill"></i>
                        </a>
                      </div>
                    ) : null}
                    {type === "all" ? (
                      <div className="action-box-head-R">
                        {data.menuStatus ? (
                          <span className="text-info purple-bg">On Menu</span>
                        ) : (
                          <span className="text-info pink-bg">Not on Menu</span>
                        )}
                      </div>
                    ) : null}
                    {check != 1 && type != "all" ? (
                      <label
                        className="coustom-checkbox white-checkbox"
                        id={index}
                      >
                        <input
                          type="checkbox"
                          id={`custom-checkbox-${index}`}
                          name="food"
                          data-name={data.name}
                          checked={selectIds.includes(data._id)}
                          value={data._id}
                          onChange={(e) =>
                            handleChange(e, index, data._id, data.name)
                          }
                        />
                        <span className="checkmark"></span>
                      </label>
                    ) : null}
                  </div>
                  <figure className="gallery_product-top">
                    <img
                      style={{ objectFit: "contain" }}
                      src={ImageURL + data.dishImage}
                    />
                  </figure>
                  <figcaption className="gallery_product-content">
                    <div
                      className="gallery_product-content-head"
                      style={{ cursor: type === "all" ? "pointer" : "default" }}
                      onClick={() => {
                        if (type === "all") {
                          nextPage(data);
                        }
                      }}
                    >
                      <h4 style={{ wordBreak: "break-all"}}>
                      {data.name}
                      </h4>
                      <span>${Number(data.price).toFixed(2)}</span>
                    </div>
                    <div className="food-info">
                      <span>
                        <b>Portion Size:</b>
                        {data.portionSize}
                      </span>
                      <span>
                        <b>Food Type:</b>
                        {data.categoryName}
                      </span>
                      <span>
                        <b>Ingredients:</b>
                        {data.ingredientResult.map((ingr, idx) => {
                          return (
                            <>
                              {idx === data.ingredientResult.length - 1
                                ? `${ingr.name}`
                                : `${ingr.name}, `}
                            </>
                          );
                        })}
                      </span>
                    </div>
                    <div className="food-info">
                      {type !== "all" && check === 1 ? (
                        <>
                          {data.totalServings === 1 ? (
                            <span>
                              <b>No. of serving:</b>
                              {data.totalServings}
                            </span>
                          ) : (
                            <span>
                              <b>No. of servings:</b>
                              {data.totalServings}
                            </span>
                          )}

                          <span>
                            <b>Delivery window:</b>
                            {`${data.deliveryOrPickupWindow}`
                              .split(",")
                              .join(", ")}
                          </span>
                          <span>
                            <b>Order by Date/Time:</b>
                            {moment(data?.orderByDate).format("MM-DD-YYYY")},
                            {moment(data?.orderByDate).format("LT")}
                          </span>
                        </>
                      ) : null}
                    </div>
                  </figcaption>
                </div>
              </div>
            ))}
          </div>
        </div>

        <Dialog open={activeOpen}>
          <div className="common-modal space-2">
            <div className="modal-dialog">
              <div className="modal-content">
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setActiveOpen(false)}
                >
                  <i className="ri-close-circle-line"></i>
                </button>
                <div className="modal-head text-center mb-4">
                  <h4 className="modal-title">you can't delete dish</h4>

                  <p>{activeMessage}</p>
                </div>
                <div className="modal-body text-center">
                  <div className="mt-3 yes-no-btn">
                    <button
                      className="btn purple-btn min-height hvr-sweep-to-right ms-1"
                      onClick={() => setActiveOpen(false)}
                    >
                      Ok
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dialog>

        <Dialog open={open.show}>
          <div className="common-modal space-2">
            <div className="modal-dialog">
              <div className="modal-content">
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => handleClose()}
                >
                  <i className="ri-close-circle-line"></i>
                </button>
                <div className="modal-head text-center mb-4">
                  <h4 className="modal-title">delete dish</h4>
                  <p style={{ fontWeight: "500" }}>
                    {isMenuDeleteMessage}
                  </p>
                </div>
                <div className="modal-body text-center">
                  <div className="mt-3 yes-no-btn">
                    <button
                      className="btn purple-btn min-height trans-btn hvr-sweep-to-right me-1"
                      onClick={() => handleClose()}
                    >
                      No
                    </button>
                    <button
                      className="btn purple-btn min-height hvr-sweep-to-right ms-1"
                      onClick={() => deleteDish(open)}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
        <Dialog open={menuOpen.show}>
          <div
            className="common-modal space-2"
            id="Not-saved"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => handleClickClose()}
                >
                  <i className="ri-close-circle-line"></i>
                </button>
                <div className="modal-head text-center mb-4">
                  <h4 className="modal-title">
                    MENU FOR SELECTED DATE NOT SAVED
                  </h4>
                  <p>
                    Do you want to leave this page? If you select 'Yes', your
                    selections will not be saved. Select 'No' to continue on
                    this page.
                  </p>
                </div>
                <div className="modal-body text-center">
                  <div className="mt-3 yes-no-btn">
                    <button
                      onClick={() => handleClickClose()}
                      className="btn purple-btn min-height trans-btn hvr-sweep-to-right me-1"
                    >
                      No
                    </button>
                    <button
                      className="btn purple-btn min-height hvr-sweep-to-right ms-1"
                      onClick={() => handleConfirm(menuOpen.date)}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
        <Dialog open={menuDelete.show}>
          <div className="common-modal space-2">
            <div className="modal-dialog">
              <div className="modal-content">
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => handleDeleteClose()}
                >
                  <i className="ri-close-circle-line"></i>
                </button>
                <div className="modal-head text-center mb-4">
                  <h4 className="modal-title">delete dish</h4>
                  <p>
                    {" "}
                    Are you sure you want to delete this dish from your menu for
                    this scheduled date? If you select yes, this dish will only
                    be removed from this date. It will still remain in your 'All
                    Dishes' library.{" "}
                  </p>
                </div>
                <div className="modal-body text-center">
                  <div className="mt-3 yes-no-btn">
                    <button
                      className="btn purple-btn min-height trans-btn hvr-sweep-to-right me-1"
                      onClick={() => handleDeleteClose()}
                    >
                      No
                    </button>
                    <button
                      className="btn purple-btn min-height hvr-sweep-to-right ms-1"
                      onClick={() => deleteMenuDish(menuDelete)}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
        <Dialog open={cloneOpen.show}>
          <div className="common-modal space-2">
            <div className="modal-dialog">
              <div className="modal-content">
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => handleCloneClose()}
                >
                  <i className="ri-close-circle-line"></i>
                </button>
                <div className="modal-head text-center mb-4">
                  <h4 className="modal-title">clone dish</h4>
                  <p>Are you sure you want to clone this dish?</p>
                </div>
                <div className="modal-body text-center">
                  <div className="mt-3 yes-no-btn">
                    <button
                      className="btn purple-btn min-height trans-btn hvr-sweep-to-right me-1"
                      onClick={() => handleCloneClose()}
                    >
                      No
                    </button>
                    <button
                      className="btn purple-btn min-height hvr-sweep-to-right ms-1"
                      onClick={() => cloneDish(cloneOpen?.data)}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
        <Dialog open={cloneSelect.show}>
          <div className="common-modal space-2">
            <div className="modal-dialog">
              <div className="modal-content">
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => handleCloneSeClose()}
                >
                  <i className="ri-close-circle-line"></i>
                </button>
                <div className="modal-head text-center mb-4">
                  <h4 className="modal-title">Rename Dish</h4>
                  <p>
                    Your dish is named as ‘Clone’. You cannot add it to the menu
                    with this name. Please go to ‘All Dishes’ to edit & rename.
                  </p>
                </div>
                <div className="modal-body text-center">
                  <div className="mt-3 yes-no-btn">
                    <button
                      className="btn purple-btn min-height trans-btn hvr-sweep-to-right me-1"
                      onClick={() => handleCloneSeClose()}
                    >
                      Ok
                    </button>
                    {/* <button
                      className="btn purple-btn min-height hvr-sweep-to-right ms-1"
                      // onClick={() => dishSubmit(cloneEdit?.data)}
                    >
                      Yes
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default MyMenu;
