import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { ViewDishURL } from '../../component/config/config'
import authHeader from "../services/auth_header";
import { useNavigate, useParams } from 'react-router-dom'
import { ImageURL } from "../../component/config/config";
import { DeleteURL } from "../../component/config/config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Dialog } from "@material-ui/core";
import { tostS, tostE } from "../../toast"
import axiosInstance from "../config/axios"
const DishDetails = () => {
    const { id } = useParams();
    const [dishDetails, setDishDetails] = useState()


    const fetchData = async () => {

        try {
            const response = (await axiosInstance().post(ViewDishURL, { id: id }))

            setDishDetails(response.data.data)

        }
        catch (error) {
            const message1 = error.response.data.message
        }

    }
    useEffect(() => {
        fetchData();
    }, []);
    let navigate = useNavigate();

    const handleView = (data) => {
        navigate(`/dashboard/editdish/${id}`, { state: data });
    };
    const delDish = async (data) => {
        const body = {
            id: id
        }
        try {
            const response = await axiosInstance().post(DeleteURL, body)
            tostS(response.data.message);
            fetchData()
            navigate('/dashboard/mymenu/all')
        }
        catch (err) {
        }
    }
    // For dialog box
    const [open, setOpen] = useState({
        id: null,
        show: false
    });
    const handleClickOpen = (data) => {
        setOpen({
            ...open,
            id: id,
            show: true
        })
    }
    const handleClose = () => {
        setOpen({
            ...open,
            id: null,
            show: false
        })
    }
    const [isHover, setIsHover] = useState(false);



    return (
      <>
        <div className="dash-R">
          <div className="dash-head">
            <h3>my menu</h3>
            <nav className="breadcrumb-box">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a
                    href="/dashboard/mymenu/all"
                    style={{ color: isHover ? "#8d126e" : "#707070" }}
                  >
                    My Menu
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <a href="#">All </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {" "}
                  Dish Details
                </li>
              </ol>
            </nav>
          </div>
          <div className="dash-bottom mt-2 order-detail">
            <div className="col-md-6">
              <figure>
                <img src={ImageURL + dishDetails?.dishImage} />
              </figure>
              <span>
                {dishDetails?.menuStatus ? (
                  <span className="text-info purple-bg">On Menu</span>
                ) : (
                  <span className="text-info pink-bg">Not on Menu</span>
                )}
              </span>
              <h4 className="title-inner">{dishDetails?.name}</h4>
              <small className="price-text">
                ${Number(dishDetails?.price).toFixed(2)}
              </small>
              <span className="text-food">{dishDetails?.cuisineName}</span>
              <div className="food-info">
                <span>
                  <b>Food Type:</b>
                  {dishDetails?.categoryName}
                </span>
                <span>
                  <b>Portion Size: </b>
                  {dishDetails?.portionSize}
                </span>
                <br />
                <span>{dishDetails?.description}</span>
              </div>
            </div>
            <hr className="grey-line" />
            <div className="food-info food-info-break col-md-6">
              <span>
                <b>Dietary Tags </b>
                {`${dishDetails?.restrictedDiets}`}
              </span>
              <span>
                <b>Preparation Time:</b>
                {`${dishDetails?.preprationTime}` +
                  `${dishDetails?.preprationTime <= 1 ? "min" : "mins"}`}{" "}
              </span>
              <span>
                <b>Ingredients:</b>
                {dishDetails?.ingredientResult.map((dt, index) => {
                  return (
                    <>
                      {index === dishDetails.ingredientResult.length - 1
                        ? `${dt.name}`
                        : `${dt.name}, `}
                    </>
                  );
                })}
              </span>
            </div>
            <div className="mt-4 btn-group">
              <a
                className="btn purple-btn min-height hvr-sweep-to-right  me-3"
                onClick={() => {
                  handleView();
                }}
              >
                Edit
              </a>
              <a
                className="btn purple-btn min-height hvr-sweep-to-right trans-btn"
                onClick={() => handleClickOpen()}
              >
                Delete
              </a>
            </div>
          </div>
          <Dialog open={open.show}>
            <div className="common-modal space-2">
              <div className="modal-dialog">
                <div className="modal-content">
                  <button
                    type="button"
                    className="close-btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => handleClose()}
                  >
                    <i className="ri-close-circle-line"></i>
                  </button>
                  <div className="modal-head text-center mb-4">
                    <h4 className="modal-title">delete dish</h4>
                    <p>
                      Are you sure you want to delete the dish ?<br />
                      Note: This will also delete this dish If it is present in
                      the menu of any day
                    </p>
                  </div>
                  <div className="modal-body text-center">
                    <div className="mt-3 yes-no-btn">
                      <button
                        className="btn purple-btn min-height trans-btn hvr-sweep-to-right me-1"
                        onClick={() => handleClose()}
                      >
                        No
                      </button>
                      <button
                        className="btn purple-btn min-height hvr-sweep-to-right ms-1"
                        onClick={() => delDish(open)}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
          {/* <div className="screen-div-info">
                    <ul className="info-list">
                        <li><a href="tel:9513539491"><i className="ri-phone-line"></i>951-353-9491</a></li>
                        <li><a href="mailto:info@email.com"><i className="ri-mail-line"></i>info@email.com</a></li>
                    </ul>
                </div> */}
        </div>
      </>
    );
}

export default DishDetails