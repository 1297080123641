import React, { useEffect, useState } from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Dialog } from "@mui/material";
import { ImageURL, LoginURL } from "../component/config/config";
import { ForgotURL } from "../component/config/config";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { loginSuccess } from "../Actions/ActionCreators";
import { useDispatch } from "react-redux";
import validator from "validator";
import { tostS, tostE } from "../toast";
const imagePattern = ImageURL + "assets/images/patterns-img.png";

const forgot = ImageURL + "assets/images/forget-password.png";

const Login = (props) => {
  const dispatch = useDispatch();
  const [log, setLog] = useState({
    email: "",
    password: "",
  });
  //For change a input field
  const handleSubmit = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    // console.log(name, value);
    setLog({ ...log, [name]: value });
    setError({ ...error, [name]: false });
  };
  const [error, setError] = useState({
    email: false,
    password: false,
  });
  let navigate = useNavigate();
  // For submit a form
  const submit = (e) => {
    e.preventDefault();
    let isValid = true;
    let er = {
      email: false,
      password: false,
    };
    if (log.email == "") {
      isValid = false;
      er.email = true;
    }
    if (log.password == "") {
      isValid = false;
      er.password = true;
    }
    setError({ ...er });
    if (isValid == true) {
      axios
        .post(LoginURL, { ...log })
        .then((response) => {
          // console.log(response);
          const data = response.data.data;
          if (response.status === 200) {
            setLog({ email: "", password: "" });
            localStorage.setItem("token", response.data.data.token);
            dispatch(loginSuccess(data));
            tostS(response.data.message);
            setError({
              email: false,
              password: false,
            });

            //
          }
          // navigate("/dashboard");
        })
        .catch((error) => {
          // console.log(error);
          tostE(error.response.data.message);
        });
    }
  };
  //For Dialog box
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  //forgot password
  const [mail, setMail] = useState("");
  const [emailError, setEmailError] = useState({
    email: false,
  });
  // For change a input field
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    // console.log(name, value);
    setMail({ ...mail, [name]: value });
    setEmailError({ ...emailError, [name]: false });
    if (name === "email") {
      if (mail.email != "") {
        validateEmail(mail.email);
      }
    }
  };
  const [errorEmail, setErrorEmail] = useState("");
  const validateEmail = (value) => {
    if (
      validator.isEmail(value, {
        required: /^([+]\d{2})?\d{10}$/,
      })
    ) {
      setErrorEmail("");
    } else {
      setErrorEmail(<p>Email Address is not valid</p>);
    }
  };

  // For send a mail to user for a password
  const send = (e) => {
    e.preventDefault();
    let isValid = true;
    let er = {
      email: false,
    };
    if (mail.email == "") {
      isValid = false;
      er.email = true;
      setErrorEmail("");
    } else {
      if (mail.email != "") {
        if (
          validator.isEmail(mail.email, {
            required: /^([+]\d{2})?\d{10}$/,
          })
        ) {
          isValid = true;
          setErrorEmail("");
        } else {
          isValid = false;
          setErrorEmail(<p>Email Address is not valid</p>);
        }
      }
    }
    setEmailError({ ...er });
    if (isValid === true) {
      axios
        .post(ForgotURL, { ...mail })
        .then((response) => {
          // console.log(response);
          if (response.status === 200) {
            setMail({ email: "" });
            tostS(response.data.message);
            setOpen(false);
            setEmailError({ email: false });
            localStorage.setItem("id", response.data.data.chefDetails._id);
            localStorage.setItem(
              "chefID",
              response.data.data.chefDetails.chefID
            );
          }
        })
        .catch((error) => {
          // console.log(error);
          tostE(error.response.data.message);
        });
    }
  };
  return (
    <>
      {/* <Header /> */}
      <div className="wraper-inner padding-head">
        <div className="inner-pages">
          <div className="patterns-banner inner-space">
            <figure>
              <img src={imagePattern} />
            </figure>
          </div>
          <section className="main-wrapper-login mar-t mb-5">
            <div className="container">
              <div className="form-box row">
                <div className="form-head text-center">
                  <h2>login as chef</h2>
                  <p>Log in to your existing account</p>
                </div>
                <form
                  className="col-md-5 m-auto"
                  onSubmit={submit}
                  autoComplete="off"
                >
                  <div className="mb-3">
                    <label className="form-label">Email</label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={log.email}
                      onChange={handleSubmit}
                    />
                    <span
                      className="errorMessage"
                      style={{
                        display: error.email ? "block" : "none",
                      }}
                    >
                      Email is required
                    </span>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Password</label>
                    <input
                      type="password"
                      name="password"
                      placeholder="Password"
                      className="form-control"
                      value={log.password}
                      onChange={handleSubmit}
                    />
                    <span
                      className="errorMessage"
                      style={{
                        display: error.password ? "block" : "none",
                      }}
                    >
                      Password is required
                    </span>

                    <div className="forgotPasswordBtn">
                      <button
                        type="button"
                        className="forget-password"
                        data-bs-toggle="modal"
                        data-bs-target="#forget-password"
                        onClick={handleClickOpen}
                      >
                        Forgot Password
                      </button>
                    </div>
                  </div>
                  <div className="mb-3 text-center mt-5">
                    <button className="btn purple-btn min-height hvr-sweep-to-right">
                      Log In
                    </button>
                  </div>

                  <span className="form-bottom-text">
                    Don't have an account ?{" "}
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate("/signup")}
                    >
                      {" "}
                      Sign up{" "}
                    </a>
                  </span>
                </form>
              </div>
            </div>
          </section>
          {/* <ToastContainer /> */}
        </div>
      </div>
      <Footer />
      <Dialog open={open}>
        {/* <div className="modal-dialog modal-dialog-centered"> */}
        <div className="modal-content">
          <button
            type="button"
            className="close-btn"
            data-bs-dismiss="modal"
            onClick={handleClose}
            aria-label="Close"
          >
            <i className="ri-close-circle-line"></i>
          </button>
          <div className="modal-head text-center mb-4">
            <figure>
              <img src={forgot} />
            </figure>
            <h4 className="modal-title">forgot password</h4>
            <p>
              Enter the email address you used to register, and we’ll send you a
              link to reset your password
            </p>
          </div>
          <div className="modal-body text-center">
            <form>
              <input
                type="email"
                name="email"
                placeholder="Email Address"
                className="form-control"
                onChange={handleChange}
                value={mail.email}
                // onBlur={(e) => validateEmail(e.target.value)}
              />
              <span
                className="errorMessage"
                style={{
                  display: emailError.email ? "block" : "none",
                }}
              >
                Email is required
              </span>
              {errorEmail === "" ? null : (
                <span className="errorMessage">{errorEmail}</span>
              )}
              <div className="mt-4 pt-3">
                <button
                  onClick={send}
                  className="btn purple-btn min-height hvr-sweep-to-right"
                >
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
        {/* </div> */}
      </Dialog>
    </>
  );
};

export default Login;
